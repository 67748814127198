import { FC } from 'react';

import AmountControl from '../AmountControl/AmountControl';

import { IAmountCounter } from '../../../interfaces/AtomsRelatedInterfaces';

import './AmountCounter.css';

const AmountCounter: FC<IAmountCounter> = ({ amount, personType, price }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <AmountControl personType={personType} type='minus' price={price} />
        </div>
        <span className={'amount'} style={{ opacity: amount % 2 ? 1 : 0.99 }}>
          {amount < 10 ? `${0}${amount}` : amount}
        </span>
        <div>
          <AmountControl personType={personType} type='plus' price={price} />
        </div>
      </div>
    </div>
  );
};

export default AmountCounter;
