import { gql } from '@apollo/client';

export const SHOP_INITIALIZE_PURCHASE = gql`
  mutation SIP(
    $product: KeycardTicketPurchaseConfigInput!
    $purchase: ShopInitializeWebViewPurchaseInput!
  ) {
    shopInitializeWebviewPurchase(
      productInput: $product
      purchaseInput: $purchase
    ) {
      failureUrl
      orderId
      orderNumber
      redirectUrl
      successUrl
      token
    }
  }
`;
