import { useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';

const SubmitWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { values, handleSubmit } = useFormikContext<any>();

  useEffect(
    () => {
      handleSubmit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  );

  return <>{children}</>;
};

export default SubmitWrapper;
