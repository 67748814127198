import { gql } from '@apollo/client';

export const SHOP_LOAD_PRODUCT = gql`
  query ShopLoadProduct($productId: String!) {
    shopLoadKeycardTicketProduct(productId: $productId) {
      id
      defaultPersonTypeId
      personTypes {
        id
        title
        ageLimit {
          max
          min
        }
      }
      availability {
        maxConsecutiveDays
      }
    }
  }
`;
