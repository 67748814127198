import { ISelectListOption } from '../../../interfaces/AtomsRelatedInterfaces';

const getDayOption = (month: number, year: number): ISelectListOption[] =>
  new Array(month && year ? new Date(year, Number(month) + 1, 0).getDate() : 31)
    .fill(0)
    .map((value, index) => ({
      label: index + 1,
      value: String(index + 1),
    }));

const monthOptions = new Array(12).fill(0).map((value, index) => ({
  label: index + 1,
  value: String(index),
}));

const yearOptions = new Array(new Date().getFullYear())
  .fill(0)
  .map((value, index) => ({
    label: index + 1,
    value: String(index + 1),
  }))
  .reverse();

export { getDayOption, monthOptions, yearOptions };
