import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Modal from '../../atoms/Modal';
import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import { Form, Formik } from 'formik';
import InputField from '../InputField';
import specialDiscount from '../../../icons/special-discount.svg';
import Subheader from '../../atoms/Subheader/Subheader';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import './styled.css';
import { useMutation } from '@apollo/client';
import ACTIVATE_TOKEN from '../../../graphql/product/activeToken';
import { DiscountToken } from '../../../interfaces/TotalComponentRelatedInterfaces';
import { ActiveTokenModalProps } from './types';
import { sendAnalytics } from '../../../utils/sendAnalytics';

const ActivateTokenModal: FC<ActiveTokenModalProps> = ({
  isOpen,
  setIsOpen,
  setActivatedTokenId,
  offerDiscountsReFetch,
}) => {
  const { t } = useTranslation();
  const [activateToken] = useMutation(ACTIVATE_TOKEN);

  const onSubmit = async (values: { code: string }) => {
    await activateToken({
      variables: {
        input: {
          code: values.code,
        },
      },
    })
      .then((activateTokenRes) => {
        const newToken = activateTokenRes?.data?.redeemPromoCode?.awardedToken;
        if (newToken) {
          sendAnalytics('Coupon Entered', {
            cart_id: '',
            coupon_id: newToken.id,
          });
          setActivatedTokenId(newToken.id);
          toast(t('checkoutView.tokenAdded'), { type: 'success' });
        }
        return newToken?.id;
      })
      .then((newTokenId) => {
        offerDiscountsReFetch().then((res: any) => {
          if (
            !newTokenId ||
            !res?.data?.shopLoadOfferDiscountTokens.find(
              (d: DiscountToken) => d.id === newTokenId
            )
          )
            toast(t('checkoutView.cantUseToken'), { type: 'warning' });
        });
        setIsOpen(false);
      })
      .catch((err) => {
        sendAnalytics('Coupon Denied', {
          cart_id: '',
          reason: err.message,
        });
        toast(err.message, { type: 'error' });
      });
  };

  return (
    <Modal isOpen={isOpen} height={'600px'}>
      <div className={'activeTokenContainer'}>
        <div className='exitButton'>
          <MainOptionButton onClick={(): void => setIsOpen(false)}>
            x
          </MainOptionButton>
        </div>
        <Formik
          enableReinitialize
          initialValues={{ code: '' }}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <div className={'center'}>
                <Subheader
                  icon={specialDiscount}
                  text={t('checkoutView.addToken')}
                  isBlack
                />
              </div>
              <div className={'center'}>
                <div style={{ width: '240px', padding: '40px 0' }}>
                  <InputField label={''} type='text' name='code' />
                </div>
              </div>
              <div className={'center'}>
                <Button
                  className='submit-button'
                  type='submit'
                  disabled={!values.code}
                >
                  {t('commons.submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ActivateTokenModal;
