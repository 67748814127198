import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import { ShopLandingProduct } from '../../../interfaces/ShopRelatedInterfaces';
import getImageLink from '../../../utils/getImageLink';
import SmallMainViewTile from '../../molecules/SmallMainViewTile/SmallMainViewTile';
import arrowRight from '../../../icons/arrow-right-black.svg';
import arrowLeft from '../../../icons/arrow-left-black.svg';
import './styles.css';
import { useHistory } from 'react-router-dom';
import useClearSelectedData from '../../../hooks/useClearSelectedData';
import { useTranslation } from 'react-i18next';
import { sendAnalytics } from '../../../utils/sendAnalytics';

const MostLovedProducts: FC<{ mobileView?: boolean }> = ({ mobileView }) => {
  const {
    lovedProductsState,
    buyTicketViewState,
    menuHiddenState,
    chosenMainCategoryState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [lovedProducts] = lovedProductsState;
  const history = useHistory();
  const [visibleSlide, setVisibleSlide] = useState(0);
  const totalLovedProducts = lovedProducts?.length || 0;
  const [, setChosenMainCategory] = chosenMainCategoryState;
  const [, setChosenSubcategory] = chosenSubcategoryState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [, setIsMenuHidden] = menuHiddenState;
  const clearSelectedData = useClearSelectedData();

  const totalSlides = mobileView
    ? (totalLovedProducts * 280 - 10) / 315
    : totalLovedProducts > 3
    ? totalLovedProducts * 0.85 - 0.05
    : totalLovedProducts;

  const visibleSlides = mobileView
    ? 1
    : totalLovedProducts < 3
    ? totalLovedProducts
    : 3;

  const handleProductClick = (
    product: ShopLandingProduct,
    position: number
  ) => {
    sendAnalytics('Product Clicked', {
      product_id: product.id,
      sku: '',
      category: product.category,
      name: product.title,
      position: position,
      url: window.location.href + 'buy-tickets?productId=' + product.id,
      image_url: getImageLink(product?.image.asset._ref) || '',
    });
    setIsMenuHidden(true);
    clearSelectedData();
    setIsBuyTicketView(true);
    sessionStorage.setItem('isBuyTicketView', 'true');
    history.push('/buy-tickets');
    setChosenMainCategory(product?.categoryId);
    setChosenSubcategory(product?.id);
    sessionStorage.setItem('chosenMainCategory', product?.categoryId ?? '');
    sessionStorage.setItem('chosenSubcategory', product?.id ?? '');
  };

  return (
    <div style={{ paddingLeft: mobileView ? 0 : 15 }}>
      <div className={'sectionLabel'}>{t('startView.mostLoved')}</div>
      <CarouselProvider
        naturalSlideWidth={260}
        naturalSlideHeight={210}
        totalSlides={totalSlides}
        orientation='horizontal'
        interval={1000}
        dragEnabled={mobileView ? true : false}
        visibleSlides={visibleSlides}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {!mobileView && visibleSlide > 0 && (
            <div className={'carousel-arrow-back'}>
              <ButtonBack
                className={'clean-navigation-button'}
                onClick={() => setVisibleSlide(visibleSlide - 1)}
              >
                <img src={arrowLeft} style={{ width: '20px' }} />
              </ButtonBack>
            </div>
          )}
          <Slider>
            {lovedProducts?.map(
              (product: ShopLandingProduct, index: number) => (
                <Slide
                  index={index}
                  key={product.id + '__' + index}
                  style={{
                    width: '268px',
                    marginLeft: index !== 0 ? '10px' : '0',
                  }}
                >
                  <SmallMainViewTile
                    imgSrc={getImageLink(product?.image.asset._ref) || ''}
                    name={product.id}
                    title={product?.title ?? ''}
                    onClick={() => handleProductClick(product, index + 1)}
                    mobileView={mobileView}
                  />
                </Slide>
              )
            )}
          </Slider>
          {!mobileView &&
            totalLovedProducts > 3 &&
            visibleSlide + 3 < totalLovedProducts && (
              <div className={'carousel-arrow-next'}>
                <ButtonNext
                  className={'clean-navigation-button'}
                  onClick={() => setVisibleSlide(visibleSlide + 1)}
                >
                  <img src={arrowRight} style={{ width: '20px' }} />
                </ButtonNext>
              </div>
            )}
        </div>
      </CarouselProvider>
    </div>
  );
};

export default MostLovedProducts;
