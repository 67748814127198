import { FC } from 'react';

import { IButton } from '../../../../interfaces/AtomsRelatedInterfaces';

import './SubmitButton.css';

const Button: FC<IButton> = ({
  children,
  className,
  disabled,
  style,
  ...rest
}) => {
  return (
    <button
      {...rest}
      style={
        disabled
          ? { color: '#fff', backgroundColor: '#C3C3C3', ...style }
          : style
      }
      disabled={disabled}
      className={`${className} ${disabled ? 'submit-button-disabled' : ''}`}
    >
      {children}
    </button>
  );
};

export default Button;
