export const PersonTypeTitle = (personType: string) => {
  return personType === 'adult' || personType === 'adult-v2'
    ? 'buyTicketView.adult'
    : personType === 'senior' || personType === 'senior-v2'
    ? 'buyTicketView.senior'
    : personType === 'teen-child'
    ? 'buyTicketView.teenChild'
    : personType === 'teen'
    ? 'buyTicketView.teen'
    : personType === 'child'
    ? 'buyTicketView.child'
    : personType === 'entry'
    ? 'buyTicketView.entry'
    : '';
};
