import { FC } from 'react';

import '../../../index.css';
import './Subheader.css';

import { ISubheader } from '../../../interfaces/AtomsRelatedInterfaces';

const Subheader: FC<ISubheader> = ({
  alt = '',
  customPaddingBottom,
  customMarginBottom,
  isBlack,
  icon,
  isCaptalize,
  isCentered,
  isPointer,
  text,
}) => (
  <div
    className={`subheader ${isPointer ? 'element-on-hover' : ''}`}
    style={
      isCentered
        ? {
            display: 'flex',
            justifyContent: 'center',
          }
        : undefined
    }
  >
    {icon ? (
      <div style={{ paddingTop: '10px' }}>
        <img alt={alt} className='subheader-icon' src={icon} />
      </div>
    ) : null}
    <h3
      className='font-grey'
      style={{
        paddingBottom:
          customPaddingBottom !== undefined ? `${customPaddingBottom}em` : '0',
        marginBottom:
          customMarginBottom !== undefined
            ? `${customMarginBottom}em`
            : undefined,
        color: isBlack ? '#000' : undefined,
        textTransform: isCaptalize ? 'capitalize' : undefined,
      }}
    >
      {text}
    </h3>
  </div>
);

export default Subheader;
