import {
  ProductGroupType,
  ShopLandingProduct,
} from '../interfaces/ShopRelatedInterfaces';

export type SubCategoryType = ProductGroupType | ShopLandingProduct;

const getCategoryOptions = (
  availableProducts: ShopLandingProduct[],
  mainCategoryId: string
): SubCategoryType[] => {
  let uniqueProductVariantGroupConfigs: ProductGroupType[] = [];

  const subCategories: ShopLandingProduct[] =
    availableProducts?.filter(
      (product: ShopLandingProduct) => product?.categoryId === mainCategoryId
    ) || [];

  const productVariantGroupConfigs = ([] as ProductGroupType[]).concat(
    ...availableProducts.map(
      (product: ShopLandingProduct): ProductGroupType[] =>
        product.productVariantGroupConfig
    )
  );

  productVariantGroupConfigs.map((config: ProductGroupType): void => {
    const id = config?.['ticket-group']?.id;
    const navigationId = config?.['navigation-group']?.id;
    if (
      navigationId === mainCategoryId &&
      !uniqueProductVariantGroupConfigs.find(
        (c) => c?.['ticket-group']?.id === id
      )
    )
      uniqueProductVariantGroupConfigs.push(config);
  });

  return [
    ...uniqueProductVariantGroupConfigs,
    ...subCategories.filter((s) => s.productVariantGroupConfig.length === 0),
  ].sort((c1: any, c2: any) => {
    const n1 = c1.frontendPriority || c1?.['ticket-group']?.sorting || 0;
    const n2 = c2.frontendPriority || c2?.['ticket-group']?.sorting || 0;
    return n2 - n1;
  });
};

export default getCategoryOptions;
