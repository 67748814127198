import { gql } from '@apollo/client';

export const SHOP_LOAD_CALENDAR_FOR_PERSON_TYPES = gql`
  query GetCalendarForPersonTypes($input: PersonTypeLoadCalendarInput!) {
    shopLoadCalendarForPersonTypes(input: $input) {
      dates {
        date
        price
        availability
      }
      messages {
        name
        text
        icon
        style
        type
        behavior
      }
      birthDates
    }
  }
`;
