import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import listRed from '../../../icons/list-red.svg';
import { FC } from 'react';
import { Order } from './type';
import SmallText from '../SmallText/SmallText';
import TicketDetails from '../TicketDetails';
import './styles.css';

type BookedTicketsProps = {
  orders: Order[];
};
const BookedTicketsComponent: FC<BookedTicketsProps> = ({ orders }) => {
  const { t } = useTranslation();

  return (
    <>
      <Subheader icon={listRed} text={t('user.bookings')} isBlack />
      <div className='groupMember'>
        {orders?.length > 0 ? (
          <>
            {orders.map((order, index) => (
              <div key={'order' + index} style={{ padding: '0 14px 14px' }}>
                {index > 0 && <div className='TicketLine' />}
                <div style={{ paddingTop: 14 }}>
                  <TicketDetails order={order} />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div style={{ textAlign: 'center', paddingTop: '100px' }}>
            <SmallText text={t('user.noOrders')} isNoUppercase />
          </div>
        )}
      </div>
    </>
  );
};

export default BookedTicketsComponent;
