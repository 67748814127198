import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { SHOP_ENABLED } from '../../../graphql/shop/shopEnabled';
import offlinePicture from '../../../assets/offline.jpg';
import './style.css';

const ShopEnabledWrapper: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data } = useQuery(SHOP_ENABLED);
  const legacyAxessShopEnabled = data?.shopStatus?.legacyAxessShopEnabled;
  const { t } = useTranslation();

  return (
    <>
      {legacyAxessShopEnabled !== false ? (
        <>{children}</>
      ) : (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
          <img
            src={offlinePicture}
            alt={'offline'}
            className={'offlineImage'}
          />
          <div className={'offlineInfo'}>
            <h2>
              <b>Ticket-Shop Offline</b>
            </h2>
            <h4>{t('commons.offlineInfo')}</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default ShopEnabledWrapper;
