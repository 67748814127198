import { FC, useContext } from 'react';

import { IFlimsLaaxLogo } from '../../../interfaces/AtomsRelatedInterfaces';
import './FlimsLaaxLogo.css';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';

const FlimsLaaxLogo: FC<IFlimsLaaxLogo> = ({ alt, src }) => {
  const history = useHistory();
  const { buyTicketViewState } = useContext(AppContext);
  const [isBuyTicketView, setIsBuyTicketView] = buyTicketViewState;

  const onClick = () => {
    setIsBuyTicketView(false);
    sessionStorage.setItem('isBuyTicketView', 'false');
    history.push('/');
  };

  return (
    <div className='logoContainer' onClick={onClick}>
      <img alt={alt} className='flims-laax-logo' src={src}></img>
    </div>
  );
};

export default FlimsLaaxLogo;
