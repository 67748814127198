import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../AppContext';

import SubmitButton from '../../../components/atoms/buttons/SubmitButton/SubmitButton';

import './CookiesPanel.css';

const CookiesPanel = () => {
  const { cookiesPanelState } = useContext(AppContext);
  const { t } = useTranslation();

  const [isCookiesPanelVisible, setIsCookiesPanelVisible] = cookiesPanelState;
  const cookiesLink = (): string => {
    const en = Boolean(localStorage.getItem('lang') === 'en');
    if (en) {
      return 'https://www.flimslaax.com/en/legal-information/data-protection';
    } else {
      return 'https://www.flimslaax.com/rechtliches/datenschutz';
    }
  };

  const handleGotItClick = () => {
    setIsCookiesPanelVisible(false);
    localStorage.setItem('isCookiesPanelVisible', 'false');
  };

  return (
    <div
      className='cookies-panel'
      style={{ display: isCookiesPanelVisible ? 'default' : 'none' }}
    >
      <div style={{ paddingLeft: '3em' }}>
        <span>{t('commons.cookiesAgreement')}</span>{' '}
        <span className='more-info'>
          <a target='blank' href={cookiesLink()}>
            {t('commons.moreInfo')}
          </a>
        </span>
      </div>
      <div style={{ paddingRight: '5em' }}>
        <SubmitButton
          className='submit-button-white-round'
          onClick={handleGotItClick}
        >
          {t('commons.gotIt')}
        </SubmitButton>
      </div>
    </div>
  );
};

export default CookiesPanel;
