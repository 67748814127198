type GetBorderProps = {
  dateIsDayBefore: boolean;
  dateIsDayAfter: boolean;
  dateIsEndDate: boolean;
  dateIsStartDate: boolean;
  dateIsBetween: boolean;
};
const getBorderProps = ({
  dateIsDayBefore,
  dateIsDayAfter,
  dateIsEndDate,
  dateIsStartDate,
  dateIsBetween,
}: GetBorderProps) => {
  const oneDaySelect = dateIsStartDate && dateIsEndDate;
  return {
    border: dateIsDayBefore || dateIsDayAfter ? '1px dashed #333' : undefined,
    borderTopLeftRadius:
      (dateIsEndDate && !oneDaySelect) || dateIsBetween || dateIsDayAfter
        ? '0'
        : '6px',
    borderTopRightRadius:
      (dateIsStartDate && !oneDaySelect) || dateIsBetween || dateIsDayBefore
        ? '0'
        : '6px',
    borderBottomRightRadius:
      (dateIsStartDate && !oneDaySelect) || dateIsBetween || dateIsDayBefore
        ? '0'
        : '6px',
    borderBottomLeftRadius:
      (dateIsEndDate && !oneDaySelect) || dateIsBetween || dateIsDayAfter
        ? '0'
        : '6px',
  };
};

export default getBorderProps;
