import { FC, useState } from 'react';
import LanguageButton from './LanguageButton/LanguageButton';
import { useMutation } from '@apollo/client';
import SAVE_PROFILE_LANGUAGE from '../../../../graphql/user/saveProfileLanguage';

const LanguageButtons: FC = () => {
  const token = Boolean(localStorage.getItem('token'));
  const [isEnActive, setIsEnActive] = useState(
    Boolean(localStorage.getItem('lang') === 'en')
  );
  const [saveProfileLanguage] = useMutation(SAVE_PROFILE_LANGUAGE);

  const handleLanguageSwitch = (lang: 'en' | 'de') => {
    if (lang === 'en') {
      if (!isEnActive) {
        setIsEnActive(true);
        localStorage.setItem('lang', 'en');
        if (token)
          saveProfileLanguage({
            variables: { input: { language: 'en' } },
          }).catch((err) => console.log(err));
      }
    } else {
      if (isEnActive) {
        setIsEnActive(false);
        localStorage.setItem('lang', 'de');
        if (token)
          saveProfileLanguage({
            variables: { input: { language: 'de' } },
          }).catch((err) => console.log(err));
      }
    }
    window.location.reload();
  };

  return (
    <>
      <LanguageButton
        color={!isEnActive ? '#fff' : '#7c7c7c'}
        backgroundColor={!isEnActive ? '#b30019' : '#f0f0f0'}
        children='de'
        onClick={() => handleLanguageSwitch('de')}
      />
      <LanguageButton
        children='en'
        color={isEnActive ? '#fff' : '#7c7c7c'}
        backgroundColor={isEnActive ? '#b30019' : '#f0f0f0'}
        onClick={() => handleLanguageSwitch('en')}
      />
    </>
  );
};

export default LanguageButtons;
