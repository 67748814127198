import { FC, useContext } from 'react';
import SummaryTicket from '../../molecules/SummaryTicket/SummaryTicket';
import { IShopOfferDraftPersonTypePrice } from '../../../interfaces/TicketsSummaryRelatedInterfaces';
import { Form, Formik } from 'formik';
import SubmitWrapper from './SubmitWrapper';
import checkIfPersonTypeMatchesAge from '../../../utils/checkIfPersonTypeMatchesAge';
import {
  ReducedBasePriceValues,
  ReducedBasePriceMembers,
  ReducedEmptyArrayPerson,
  SummaryTicketFormProps,
} from './types';
import { OptionPartyMember, PricePerPersonType } from '../TicketsSummary/type';
import { AppContext } from '../../../AppContext';

const SummaryTicketForm: FC<SummaryTicketFormProps> = ({
  basePricePerPersonType,
  onSubmit,
  optionsPartyMembers,
  onAddPersonClick,
  partyMembersTypeAdded,
  partyMembersAdded,
  setPartyMembersAdded,
  productIncludeSeniorType,
  getPersonTypesTitle,
  chosenSubcategory,
  getUserKeyCardPrice,
}) => {
  const {
    chosenPersonFieldsState,
    chosenPartyMembersOnTicketState,
    availableProductsState,
  } = useContext(AppContext);
  const [availableProducts] = availableProductsState;
  const [chosenPersonFields] = chosenPersonFieldsState;
  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;

  const selectedProductTypes = availableProducts?.filter(
    (p: any) => p.id == chosenSubcategory
  )?.[0]?.quantity?.[0]?.values;

  const sortedAndFilteredMembers =
    basePricePerPersonType &&
    optionsPartyMembers &&
    basePricePerPersonType.reduce(
      (o: ReducedBasePriceMembers, item: PricePerPersonType) => {
        const selectedProductType = selectedProductTypes?.filter(
          (productType: any) => productType?.backendCode === item.type
        )?.[0];

        return {
          ...o,
          [item.type]: optionsPartyMembers.filter(
            (pM: OptionPartyMember) =>
              pM?.dateOfBirth &&
              checkIfPersonTypeMatchesAge(
                item.type,
                new Date(pM.dateOfBirth),
                productIncludeSeniorType,
                selectedProductType?.minimumAge,
                selectedProductType?.maximumAge
              )
          ),
        };
      },
      {}
    );

  const initialValues = sortedAndFilteredMembers
    ? basePricePerPersonType.reduce(
        (o: ReducedBasePriceValues, item: PricePerPersonType) => {
          const emptyArrayPerson =
            item.total / item.price > 0
              ? (Array.apply(
                  [],
                  Array(item.total / item.price).map(() => {
                    return '';
                  })
                ) as string[])
              : [];
          const indexes = chosenPersonFields
            .filter((pF: string) => pF.split('.')[0] === item.type)
            .map((pF: string) => pF.split('.')[1]);
          const arrayPerson = emptyArrayPerson.reduce(
            (o2: ReducedEmptyArrayPerson, item2: string, index: number) => {
              const lastSelectedMember = chosenPartyMembersOnTicket
                .filter(
                  (memberId: string) => !Object.values(o2).includes(memberId)
                )
                .filter(
                  (memberId: string) =>
                    sortedAndFilteredMembers[item.type].filter(
                      (p: OptionPartyMember) => p.value === memberId
                    )?.[0]
                )?.[0];

              const availableMember = sortedAndFilteredMembers[
                item.type
              ].filter((c: OptionPartyMember) => {
                return c?.value && o2
                  ? !Object.values(o2).includes(c?.value)
                  : false;
              })?.[0]?.value;

              return {
                ...o2,
                [indexes[index]]: lastSelectedMember
                  ? lastSelectedMember
                  : availableMember
                  ? availableMember
                  : '',
              };
            },
            {}
          );
          return { ...o, [item.type]: arrayPerson };
        },
        {}
      )
    : {};
  return (
    <>
      {sortedAndFilteredMembers && optionsPartyMembers && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form>
            <SubmitWrapper>
              {basePricePerPersonType?.map(
                (offer: IShopOfferDraftPersonTypePrice) => {
                  const selectedProductType = selectedProductTypes?.filter(
                    (productType: any) =>
                      productType?.backendCode === offer.type
                  )?.[0];

                  return (
                    offer?.total > 0 && (
                      <SummaryTicket
                        key={`${offer.type}${offer.price}${offer.total}`}
                        onAddPersonClick={onAddPersonClick}
                        partyMembersTypeAdded={partyMembersTypeAdded}
                        partyMembersAdded={partyMembersAdded}
                        setPartyMembersAdded={setPartyMembersAdded}
                        optionsPartyMembers={optionsPartyMembers}
                        offer={offer}
                        productIncludeSeniorType={productIncludeSeniorType}
                        getPersonTypesTitle={getPersonTypesTitle}
                        minimumAge={selectedProductType?.minimumAge}
                        maximumAge={selectedProductType?.maximumAge}
                        getUserKeyCardPrice={getUserKeyCardPrice}
                      />
                    )
                  );
                }
              )}
            </SubmitWrapper>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default SummaryTicketForm;
