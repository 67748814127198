import {
  ICalendarDayForPersonType,
  IRenderTileContent,
} from '../../../../interfaces/BookingRelatesInterfaces';
import moment from 'moment';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import availabilityDateColors from '../../../../constans/availabilityDateColors';
import { checkDatePosition, getBorderProps } from '.';
import { dateFormat } from '../../../../constans/formats';

export const renderTileContent = ({
  date,
  maxConsecutiveDays = 1,
  ticketsStartDate,
  ticketsEndDate,
  upSaleDates,
  calendarForPersonTypes,
}: IRenderTileContent): JSX.Element | null => {
  const today = moment(new Date()).format(dateFormat);
  const convertedDate = moment(date).format(dateFormat);
  const currentDate = moment(date).isSameOrAfter(today);
  const dateIsStartDate = Boolean(
    ticketsStartDate && moment(ticketsStartDate).isSame(convertedDate)
  );
  const dateIsEndDate = Boolean(
    ticketsEndDate && moment(ticketsEndDate).isSame(convertedDate)
  );

  const dateIsBetween = Boolean(
    ticketsStartDate &&
      ticketsEndDate &&
      moment(convertedDate).isBetween(ticketsStartDate, ticketsEndDate)
  );

  const priceForPersonType = calendarForPersonTypes?.find(
    (day: ICalendarDayForPersonType) => day.date === convertedDate
  )?.price;
  const upSalesPrice = upSaleDates.find(
    (sale: any) => sale.date === convertedDate
  )?.dayPrice;

  const { dateIsDayAfter, dateIsDayBefore } = checkDatePosition({
    maxConsecutiveDays,
    upSaleDates,
    ticketsStartDate,
    ticketsEndDate,
    convertedDate,
  });

  const price = Number(upSalesPrice || priceForPersonType);

  const availabilityDate = calendarForPersonTypes?.find(
    (day: ICalendarDayForPersonType) => day.date === convertedDate
  )?.availability;

  const dateColorActive =
    availabilityDate && availabilityDateColors.includes(availabilityDate);

  const colorFromAvailabilityDate =
    availabilityDate === 'GREEN'
      ? '#d3eadd'
      : availabilityDate === 'YELLOW'
      ? '#fcf4db'
      : availabilityDate === 'ORANGE'
      ? '#FFA07A'
      : availabilityDate === 'RED'
      ? '#fbdddd'
      : '#ffffff';

  const containerBackground =
    dateIsStartDate || dateIsEndDate
      ? '#333'
      : dateIsBetween
      ? '#4f4f4f'
      : dateIsDayBefore || dateIsDayAfter
      ? '#f1f7f3'
      : colorFromAvailabilityDate;

  const containerFontColor =
    dateIsStartDate || dateIsEndDate || dateIsBetween
      ? '#FFF'
      : !dateColorActive
      ? '#828282'
      : '#000';

  const priceFontColor =
    upSalesPrice && priceForPersonType && priceForPersonType > upSalesPrice
      ? '#3CB371'
      : dateIsStartDate || dateIsEndDate || dateIsBetween
      ? '#FFF'
      : '#808080';

  const todayTileContent = currentDate ? (
    <div className={'dayContainer'}>
      <div
        className={'dayContent'}
        style={{
          background: containerBackground,
          color: containerFontColor,
          ...getBorderProps({
            dateIsDayBefore,
            dateIsDayAfter,
            dateIsEndDate,
            dateIsStartDate,
            dateIsBetween,
          }),
        }}
      >
        <div>{date.getDate()}</div>

        <div className={'priceContainer'} style={{ color: priceFontColor }}>
          {formatPriceForDisplay(price)}
        </div>
      </div>
    </div>
  ) : null;

  return todayTileContent;
};
