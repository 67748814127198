const checkIfPersonTypeMatchesAge = (
  personType: string,
  personDate: Date,
  productIncludeSeniorType?: boolean,
  minimumAge?: number,
  maximumAge?: number
): boolean => {
  const today = new Date();
  const month = today.getMonth() - personDate.getMonth();
  const yearToRemove =
    month < 0 || (month === 0 && today.getDate() < personDate.getDate())
      ? 1
      : 0;
  const personAge =
    today.getFullYear() - personDate.getFullYear() - yearToRemove;
  const adultWithMaxAge =
    productIncludeSeniorType !== undefined ? productIncludeSeniorType : true;
  if (minimumAge && maximumAge) {
    return personAge >= minimumAge && personAge <= maximumAge;
  } else
    switch (personType) {
      case 'adult':
        return adultWithMaxAge
          ? personAge > 17 && personAge < 64
          : personAge > 17;
      case 'adult-v2':
        return adultWithMaxAge
          ? personAge > 17 && personAge < 64
          : personAge > 17;
      case 'child':
        return personAge > 5 && personAge < 13;
      case 'entry':
        return personAge > 5;
      case 'teen-child':
        return personAge > 5 && personAge < 18;
      case 'teen':
        return personAge > 12 && personAge < 18;
      case 'senior':
        return personAge > 63;
      case 'senior-v2':
        return personAge > 63;
      default:
        return personAge === 1000;
    }
};

export default checkIfPersonTypeMatchesAge;
