import Subheader from '../../atoms/Subheader/Subheader';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import CloseRed from '../../../icons/closeRed.svg';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';

const SummaryTicketWithoutPartyMembers = ({
  basePricePerPersonType,
  getPersonTypesTitle,
}: any) => {
  const { chosenPersonTypesState } = useContext(AppContext);
  const [chosenPersonTypes, setChosenPersonTypes] = chosenPersonTypesState;

  const removePersonType = (type: String) => {
    const newChosenPersonTypes = chosenPersonTypes.filter(
      (cPT: string) => cPT !== type
    );
    setChosenPersonTypes(newChosenPersonTypes);
    sessionStorage.setItem('chosenPersonTypes', newChosenPersonTypes);
  };

  const personTypesNotEmpty = chosenPersonTypes?.length > 0;
  return (
    <div>
      {personTypesNotEmpty &&
        basePricePerPersonType?.map((offer: any, index: number) => {
          const typeAmount = offer.total / offer.price;
          if (typeAmount)
            return (
              <div
                key={offer.type + index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div className={'ticketSubHeader'}>
                  <Subheader
                    isBlack
                    isCaptalize
                    text={`${typeAmount} x ${getPersonTypesTitle(offer.type)}`}
                  />
                </div>
                <div className='checkoutTicketPrice'>
                  CHF {formatPriceForDisplay(offer.total)}
                </div>
                <img
                  alt='close-btn'
                  src={CloseRed}
                  className={'closeButton pointer'}
                  onClick={() => removePersonType(offer.type)}
                />
              </div>
            );
        })}
    </div>
  );
};
export default SummaryTicketWithoutPartyMembers;
