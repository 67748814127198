import React, { ReactElement } from 'react';
import FieldWrapper from '../../molecules/FieldWrapper';
import SimpleSelect, { SimpleSelectVariant } from '../../atoms/SimpleSelect';
import { ISelectListOption } from '../../../interfaces/AtomsRelatedInterfaces';

interface SimpleSelectFieldProps {
  label?: string;
  name: string;
  options: ISelectListOption[];
  required?: boolean;
  disabled?: boolean;
  isMarkRed?: boolean;
  placeholder?: string;
  onChange?: (value: any) => void;
  variant?: SimpleSelectVariant;
}

const SimpleSelectField = ({
  label,
  name,
  options,
  required = false,
  disabled = false,
  isMarkRed = false,
  onChange,
  placeholder,
  variant,
}: SimpleSelectFieldProps): ReactElement => (
  <FieldWrapper {...{ label, name, required, onChange }}>
    {(field: any) => (
      <>
        <SimpleSelect
          {...{
            ...field,
            options,
            disabled,
            isMarkRed,
            placeholder,
            variant,
          }}
        />
      </>
    )}
  </FieldWrapper>
);

export default SimpleSelectField;
