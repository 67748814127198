import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useDatesAndDaysText = (
  startDate: string,
  endDate: string
): string => {
  const { t } = useTranslation();

  const convertedStartDate = moment(startDate).format('DD.MM');
  const convertedEndDate = moment(endDate).format('DD.MM');
  const diff =
    moment.duration(moment(endDate).diff(moment(startDate))).asDays() || 0;

  return `${convertedStartDate} - ${convertedEndDate} (${diff + 1} ${
    diff > 0 ? t('commons.days') : t('commons.day')
  })`;
};
