/* eslint-disable array-callback-return */
import { FC, useContext } from 'react';
import './styles.css';
import Subheader from '../../atoms/Subheader/Subheader';
import { AppContext } from '../../../AppContext';
import getImageLink from '../../../utils/getImageLink';
import ReactMarkdown from 'react-markdown';
import {
  ProductGroupType,
  ShopLandingProduct,
} from '../../../interfaces/ShopRelatedInterfaces';

const TicketInformation: FC<any> = ({
  title,
  description,
  chosenSubcategory,
}) => {
  const {
    availableProductsState,
    chosenGroupCategoryState,
    chosenMainCategoryState,
  } = useContext(AppContext);
  const [availableProducts] = availableProductsState;
  const [chosenGroupCategory] = chosenGroupCategoryState;
  const [chosenMainCategory] = chosenMainCategoryState;
  const enLangIsActive = localStorage.getItem('lang') === 'en';
  const groupCategories: ShopLandingProduct[] = availableProducts.filter(
    (product: ShopLandingProduct) =>
      chosenGroupCategory &&
      product.productVariantGroupConfig.find(
        (c) =>
          c['ticket-group']?.id === chosenGroupCategory &&
          c['navigation-group']?.id === chosenMainCategory
      )
  );
  const selectedGroupCategory = chosenGroupCategory
    ? groupCategories?.[0]?.productVariantGroupConfig.find(
        (c: ProductGroupType) => c?.['ticket-group']?.id === chosenGroupCategory
      )
    : undefined;

  const imageRef: string = availableProducts.filter(
    (p: any) => p.id === chosenSubcategory
  )?.[0]?.image.asset._ref;

  const imageLink = imageRef
    ? getImageLink(imageRef)
    : selectedGroupCategory?.['ticket-group']?.['category-image'];

  return (
    <div className='information-container'>
      {imageLink && (
        <img className={'information-img'} src={imageLink} alt={'image'} />
      )}
      <div className='information-text'>
        <Subheader
          text={
            chosenSubcategory
              ? title
              : selectedGroupCategory?.['ticket-group']?.[
                  `name-${enLangIsActive ? 'en' : 'de'}`
                ] || ''
          }
          isBlack
        />
        <ReactMarkdown>
          {chosenSubcategory
            ? description
            : selectedGroupCategory?.['ticket-group']?.[
                `description-${enLangIsActive ? 'en' : 'de'}`
              ] || ''}
        </ReactMarkdown>
      </div>
    </div>
  );
};
export default TicketInformation;
