import { useLazyQuery } from '@apollo/client';
import DOMPurify from 'dompurify';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AppContext } from '../../../../AppContext';
import LanguageButtons from '../../../atoms/buttons/LanguageButtons/LanguageButtons';

import '../../../../index.css';
import '../../../atoms/Subheader/Subheader.css';
import './ToggleMenuDefaultView.css';

import { ISuccesPage } from '../../../../interfaces/MainPageRelatedInterfaces';
import FlimsLaaxLogo from '../../../atoms/FlimsLaaxLogo/FlimsLaaxLogo';
import flimsLaaxLogo from '../../../../assets/flimsLaaxLogo3.png';

import { FETCH_BASIC_DATA_OF_GUEST } from '../../../../graphql/user/guest';

import { lock } from '../../../../utils/loginRelated';

import { useClearRestOfMobileViews } from '../../../../hooks/useClearRestOfMobileViews';
import useWindowWidth from '../../../../hooks/useWindowWidth';

const ToggleMenuDefaultView: FC<ISuccesPage> = ({ isSuccessPage }) => {
  const { chosenMainCategoryState, chosenSubcategoryState, menuHiddenState } =
    useContext(AppContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [, setChosenMainCategory] = chosenMainCategoryState;
  const [, setChosenSubcategory] = chosenSubcategoryState;
  const [isMenuHidden, setIsMenuHidden] = menuHiddenState;
  const [redTitle, setRedTitle] = useState('');
  const token = Boolean(localStorage.getItem('token'));
  const { width } = useWindowWidth();
  const isMobileView = width < 600;
  const [getGuest, { data: guestData }] = useLazyQuery(
    FETCH_BASIC_DATA_OF_GUEST
  );

  useEffect(() => {
    token && getGuest();
  }, [getGuest, token]);

  const [nameToUppercase, setNameToUppercase] = useState('');

  useEffect(() => {
    const name = guestData?.guest?.profile?.firstName ?? '';
    setNameToUppercase(name.toUpperCase());
  }, [guestData]);

  const updateMenuTitle = () => {
    if (location.pathname === '/user-panel') {
      setRedTitle(
        t('commons.hello') +
          ' ' +
          (nameToUppercase.length < 9
            ? nameToUppercase
            : `${nameToUppercase.slice(0, 9)}...`)
      );
    } else if (isSuccessPage) {
      setRedTitle(
        t('commons.thankYou') +
          ' ' +
          (nameToUppercase.length < 9
            ? nameToUppercase
            : `${nameToUppercase.slice(0, 9)}...`)
      );
    } else {
      setRedTitle('TICKETSHOP');
    }
    return () => {
      setRedTitle('');
    };
  };

  useEffect(() => {
    updateMenuTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameToUppercase]);

  const handleMenuClick = () => {
    //TODO : as reusable hook
    const opositeSessionStorageMenuState = !isMenuHidden;
    setIsMenuHidden(opositeSessionStorageMenuState);
    localStorage.setItem(
      'isMenuHidden',
      String(opositeSessionStorageMenuState)
    );
  };

  const zenDeskShowHref = DOMPurify.sanitize(
    // eslint-disable-next-line no-script-url
    'javascript:$zopim.livechat.window.show();'
  );
  const handleZenClick = () => {
    return (
      <HelmetProvider>
        <Helmet>
          <script
            id='ze-snippet'
            src='https://static.zdassets.com/ekr/snippet.js?key=cb97d551-2e9c-4589-ae09-2fa47b59c8ee'
          ></script>
        </Helmet>
      </HelmetProvider>
    );
  };

  const clearViews = useClearRestOfMobileViews(['buyView', 'successView']);

  const onLogOutClick = useCallback(() => {
    localStorage.removeItem('token');
    clearViews();
    setChosenMainCategory('');
    setChosenSubcategory('');
    sessionStorage.removeItem('chosenMainCategory');
    sessionStorage.removeItem('chosenSubcategory');
    lock.logout({
      redirectUrl: window.location.href,
    });
    window.location.href = window.location.origin;
  }, [clearViews, setChosenMainCategory, setChosenSubcategory]);

  return (
    <div className={'container-menu'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div className='menu' onClick={handleMenuClick}>
          <span className='shortMenuLine' style={{ marginTop: '8px' }}></span>
          <span className='longMenuLine'></span>
          <span className='menuTitle'>{t('commons.menu')}</span>
        </div>
        {!isMobileView ? null : (
          <a href={zenDeskShowHref}>
            <div
              className='menu'
              style={{
                alignItems: 'center',
                background: '#b30019',
                color: '#fff',
                justifyContent: 'center',
                marginLeft: '15px',
              }}
              onClick={handleZenClick}
            >
              <span style={{ fontWeight: 'bold' }}>?</span>
              <span
                style={{
                  fontSize: '10px',
                  fontWeight: 'lighter',
                  textTransform: 'uppercase',
                }}
              >
                {t('commons.help')}
              </span>
            </div>
          </a>
        )}
        <div style={{ marginLeft: '15px' }}>
          <LanguageButtons />
        </div>
        {token && (
          <div className='logOut' onClick={onLogOutClick}>
            {t('commons.logOut')}
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {isSuccessPage ? (
          <h1 style={{ fontSize: '40px' }}>{redTitle}</h1>
        ) : (
          <h1 style={{ margin: 0 }}>{redTitle}</h1>
        )}
      </div>
      <div className='menuLogoContainer'>
        <FlimsLaaxLogo alt='Flims Laax Logo' src={flimsLaaxLogo} />
      </div>
    </div>
  );
};

export default ToggleMenuDefaultView;
