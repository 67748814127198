import { useContext } from 'react';
import { AppContext } from '../AppContext';

const useClearUpgrades = () => {
  const { chosenUpgradesState } = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [chosenUpgrades, setChosenUpgrades] = chosenUpgradesState;

  const clear = () => {
    sessionStorage.setItem('chosenUpgrades', String([]));
    setChosenUpgrades([]);
  };
  return clear;
};
export default useClearUpgrades;
