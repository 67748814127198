/* eslint-disable array-callback-return */
import React, { FC, useEffect } from 'react';
import './CustomerTypeTickets.css';
import useSetPersonTypesAmounts from '../../../hooks/useSetPeronTypesAmount';

const CustomerTypeTicketsWrapper: FC<{
  productPersonTypes: [string];
  loadedPersonTypeFromQuery: boolean;
  setLoadedPersonTypeFromQuery: (value: boolean) => void;
  children: React.ReactNode;
}> = ({
  productPersonTypes,
  loadedPersonTypeFromQuery,
  setLoadedPersonTypeFromQuery,
  children,
}) => {
  const setPersonTypesAmounts = useSetPersonTypesAmounts(productPersonTypes);

  useEffect(() => {
    if (!loadedPersonTypeFromQuery && productPersonTypes.length > 0) {
      setPersonTypesAmounts();
      setLoadedPersonTypeFromQuery(true);
    }
  }, [loadedPersonTypeFromQuery, productPersonTypes]);

  return <>{children}</>;
};
export default CustomerTypeTicketsWrapper;
