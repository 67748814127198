import { useMutation } from '@apollo/client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Subheader from '../../atoms/Subheader/Subheader';
import './styled.css';
import userIcon from '../../../icons/user-red.svg';
import MemberForm from '../MemberForm';
import '../../atoms/TileContainer/TileContainer.css';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import { UPDATE_PARTY_MEMBER } from '../../../graphql/user/user';
import moment from 'moment';
import { EditMemberModalProps, EditPartyMemberType } from './type';
import { NoKeyCardType } from '../../../constans/keycardType';
import Modal from '../../atoms/Modal';
import { dateFormat } from '../../../constans/formats';

const EditMemberModal: FC<EditMemberModalProps> = ({
  isOpen,
  setToggleEditMemberModal,
  setEditMember,
  refresh,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [updatePartyMember] = useMutation(UPDATE_PARTY_MEMBER);

  const submitSuccess = (): void => {
    toast(t('toasts.editMemberSuccess'), { type: 'success' });
    refresh();
    setToggleEditMemberModal(false);
    setEditMember(null);
  };
  const submitError = (message?: string): void => {
    toast(message || t('toasts.serverError'), { type: 'error' });
  };

  const onSubmit = async (values: EditPartyMemberType): Promise<void> => {
    await updatePartyMember({
      variables: {
        partyMember: {
          id: values?.id,
          firstName: values.firstName,
          lastName: values?.lastName || null,
          dateOfBirth: moment(
            new Date(
              Number(values.yearBirth),
              Number(values.monthBirth),
              Number(values.dayBirth)
            )
          ).format(dateFormat),
          keycard:
            values.keyCardType === NoKeyCardType
              ? null
              : {
                  displayNumber: values.keyCardDisplayNumber,
                  type: values.keyCardType,
                },
        },
      },
    })
      .then((res) =>
        res?.data?.updatePartyMember ? submitSuccess() : submitError()
      )
      .catch((err) =>
        values.keyCardType !== NoKeyCardType
          ? submitError(t('toasts.badKeycard'))
          : submitError(err.message)
      );
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={'editMemberContainer'}>
        <div className='exitButtonEdit'>
          <MainOptionButton
            onClick={(): void => setToggleEditMemberModal(false)}
          >
            x
          </MainOptionButton>
        </div>
        <Subheader
          icon={userIcon}
          text={t('checkoutView.editPerson')}
          isBlack
        />
        {initialValues && (
          <MemberForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            isAddPerson
            isEditMember
          />
        )}
      </div>
    </Modal>
  );
};

export default EditMemberModal;
