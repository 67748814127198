import Modal from '../../atoms/Modal';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

interface AboutModalProps {
  isOpen: boolean;
  close: () => void;
}
const AboutModal: FC<AboutModalProps> = ({ isOpen, close }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen}>
      <div style={{ padding: 40, position: 'relative' }}>
        <div className='exitButton'>
          <MainOptionButton onClick={close}>x</MainOptionButton>
        </div>
        <div>
          <div className={'about'}>{t('startView.about')?.toUpperCase()}</div>
          <div className={'aboutTitle'}>Copyright 2024 by</div>
          <div className={'aboutText'}>Mountain Vision AG</div>
          <div className={'aboutText'}>Via Murschetg 17</div>
          <div className={'aboutText'}>CH-7032 Laax</div>
          <div className={'aboutText'}>
            Ein Unternehmen der Weissen Arena Gruppe
          </div>
          <div className={'aboutTitle'}>Verantwortlich für den Inhalt</div>
          <div className={'aboutText'}>Mountain Vision AG</div>
          <div className={'aboutText'}>Via Murschetg 17</div>
          <div className={'aboutText'}>CH-7032 Laax</div>
          <div className={'aboutText'}>
            Ein Unternehmen der Weissen Arena Gruppe
          </div>
          <div className={'aboutText'} style={{ paddingTop: '14px' }}>
            Tel +41 81 927 70 07
          </div>
          <div className={'aboutText'}>Fax +41 81 927 70 08</div>
          <div className={'aboutText'} style={{ paddingBottom: '14px' }}>
            E-Mail: info@laax.com
          </div>
          <div className={'aboutText'}> Alle Rechte vorbehalten.</div>
        </div>
      </div>
    </Modal>
  );
};
export default AboutModal;
