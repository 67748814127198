import { useMutation } from '@apollo/client';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AppContext } from '../../../AppContext';

import Subheader from '../../atoms/Subheader/Subheader';
import './AddMemberModal.css';
import userIcon from '../../../icons/user-red.svg';
import MemberForm from '../MemberForm';
import '../../atoms/TileContainer/TileContainer.css';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';

import { IAddMemberModal } from '../../../interfaces/AddMemberReleatedInterfaces';

import { CREATE_PARTY_MEMBER } from '../../../graphql/user/user';
import moment from 'moment';
import { ICreatePartyMemberInput } from './type';

import checkIfPersonTypeMatchesAge from '../../../utils/checkIfPersonTypeMatchesAge';
import { NoKeyCardType } from '../../../constans/keycardType';
import Modal from '../../atoms/Modal';
import { dateFormat } from '../../../constans/formats';

const AddMemberModal: FC<IAddMemberModal> = ({
  isOpen,
  specifiedPersonType,
  setToggleAddMemberModal,
  isCheckoutView,
  refresh,
  idOldUser,
  productIncludeSeniorType,
  chosenSubcategory,
}) => {
  const { chosenPartyMembersOnTicketState, config, availableProductsState } =
    useContext(AppContext);
  const [availableProducts] = availableProductsState;
  const { t } = useTranslation();
  const [isBirthNotInRange, setIsBirthNotInRange] = useState(true);
  const [createPartyMember, { data }] = useMutation(CREATE_PARTY_MEMBER);
  const [chosenPartyMembersOnTicket, setChosenPartyMembersOnTicket] =
    chosenPartyMembersOnTicketState;
  const partyMembers = data?.createPartyMember?.partyMembers;

  const selectedProductType = availableProducts
    ?.find((p: any) => p.id == chosenSubcategory)
    ?.quantity?.[0]?.values?.find(
      (v: any) => v.backendCode === specifiedPersonType
    );
  const submitSuccess = (): void => {
    toast(t('toasts.addMemberSuccess'), { type: 'success' });
    refresh();
    setToggleAddMemberModal && setToggleAddMemberModal(false);
  };
  const submitError = (): void => {
    toast(t('toasts.serverError'), { type: 'error' });
  };

  const setBirthDate = (date: Date) => {
    if (specifiedPersonType)
      setIsBirthNotInRange(
        !checkIfPersonTypeMatchesAge(
          specifiedPersonType,
          date,
          productIncludeSeniorType,
          selectedProductType?.minimumAge,
          selectedProductType?.maximumAge
        )
      );
  };
  const onSubmit = async (values: ICreatePartyMemberInput): Promise<void> => {
    if (specifiedPersonType && Boolean(isBirthNotInRange)) {
      toast(t('checkoutView.incorrectBirthDatePersonType'), {
        type: 'error',
      });
    } else {
      await createPartyMember({
        variables: {
          partyMember: {
            firstName: values.firstName,
            lastName: values?.lastName || null,
            dateOfBirth: moment(
              new Date(
                Number(values.yearBirth),
                Number(values.monthBirth),
                Number(values.dayBirth)
              )
            ).format(dateFormat),
            keycard:
              values.keyCardType === NoKeyCardType
                ? null
                : {
                    displayNumber: values.keyCardDisplayNumber,
                    type: values.keyCardType,
                  },
          },
        },
      })
        .then((res) =>
          res?.data?.createPartyMember ? submitSuccess() : submitError()
        )
        .catch((err) =>
          err.message === 'ValidationError in req.body'
            ? toast(t('checkoutView.incorrectKeycardNumber'), { type: 'error' })
            : toast(err?.message ?? 'Error', { type: 'error' })
        );
    }
  };

  useEffect(() => {
    const currentMemberID =
      partyMembers?.[Number(partyMembers?.length - 1)]?.id;
    isCheckoutView &&
      currentMemberID &&
      setChosenPartyMembersOnTicket([
        // eslint-disable-next-line
        ...chosenPartyMembersOnTicket.filter((m: string) => m != idOldUser),
        currentMemberID,
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isCheckoutView]);

  return (
    <Modal isOpen={isOpen}>
      <div className={'addMemberContainer'}>
        <div className='exitButton'>
          <MainOptionButton
            onClick={(): void => setToggleAddMemberModal(false)}
          >
            x
          </MainOptionButton>
        </div>
        <Subheader icon={userIcon} text={t('checkoutView.addPerson')} isBlack />
        <MemberForm
          isAddPerson={true}
          setBirthDate={setBirthDate}
          onSubmit={onSubmit}
          isDateNotInRange={Boolean(specifiedPersonType) && isBirthNotInRange}
          newKeycardCost={config?.newKeycardCost}
        />
      </div>
    </Modal>
  );
};

export default AddMemberModal;
