import { useMutation, useQuery } from '@apollo/client';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import { AppContext } from './AppContext';

import BuyTicketView from './components/pages/BuyTicketView/BuyTicketView';
import CheckoutView from './components/pages/CheckoutView/CheckoutView';
import CookiesPanel from './components/molecules/CookiesPanel/CookiesPanel';
import PaymentAbort from './components/pages/Payment/Abort';
import PaymentFail from './components/pages/Payment/Fail';
import PaymentSuccess from './components/pages/Payment/Success';
import StartViewPage from './components/pages/StartView/StartView';
import UserPanel from './components/pages/UserPanel/UserPanel';
import ProductTemplate from './components/templates/ProductTemplate';

import { getShopCarousel, getShopLandingLayouts } from './client';

import { IMainContainerWrapper } from './interfaces/AtomsRelatedInterfaces';
import {
  ProductGroupType,
  ShopCategory,
  ShopLandingProduct,
} from './interfaces/ShopRelatedInterfaces';

import { FETCH_BASIC_DATA_OF_GUEST } from './graphql/user/guest';
import SAVE_PROFILE_LANGUAGE from './graphql/user/saveProfileLanguage';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ProductWidget from './components/pages/ProductWidget/ProductWidget';
import filterProducts from './utils/filterProducts';
import Payment from './components/pages/Payment';
import Redirect404 from './components/pages/Redirect404';
import AuthenticatedWrapper from './components/templates/AuthenticatedWrapper';
import { sendAnalytics, SegmentAnalyticsTypes } from './utils/sendAnalytics';
import getCategoryOptions from './utils/getCategoryOptions';

const MainContainerWrapper: FC<IMainContainerWrapper> = ({
  children,
  authenticated,
}) => (
  <div className='wrapper-main-container'>
    {authenticated ? (
      <AuthenticatedWrapper>
        <div className='main-container'>{children}</div>
      </AuthenticatedWrapper>
    ) : (
      <div className='main-container'>{children}</div>
    )}
  </div>
);

const AppRouter = () => {
  const {
    availableProductsState,
    buyTicketViewState,
    cookiesPanelState,
    mainCategoriesState,
    successPageState,
    lovedProductsState,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [langStorage, setLangStorage] = useState<string>(
    localStorage.getItem('lang') === 'de' ? 'de' : 'en'
  );
  const token = Boolean(localStorage.getItem('token'));
  const [isCookiesPanelVisible] = cookiesPanelState;
  const [isBuyTicketView] = buyTicketViewState;
  const [, setIsSuccessPageView] = successPageState;
  const [, setMainCategories] = mainCategoriesState;
  const [, setAvailableProducts] = availableProductsState;
  const [, setLovedProducts] = lovedProductsState;

  const [saveProfileLanguage, { data: saveProfileLanguageData }] = useMutation(
    SAVE_PROFILE_LANGUAGE
  );
  const { data: userDetails, loading: userDetailsLoading } = useQuery(
    FETCH_BASIC_DATA_OF_GUEST,
    {
      skip: !token,
    }
  );

  useEffect(() => {
    if (userDetailsLoading === false) {
      const userLanguage = userDetails?.guest?.profile?.language;
      if (sessionStorage.getItem('languageFromBrowser') !== 'true') {
        sessionStorage.setItem('languageFromBrowser', 'true');
        const langFromBrowser =
          window.navigator?.language.split('-')[0] === 'de' ? 'de' : 'en';
        if (token && userLanguage && userLanguage !== langFromBrowser) {
          if (langFromBrowser !== langStorage) {
            setLangStorage(langFromBrowser);
            localStorage.setItem('lang', langFromBrowser);
          }
          saveProfileLanguage({
            variables: { input: { language: langFromBrowser } },
          }).catch(() => console.log('lang err'));
        } else if (langFromBrowser !== langStorage) {
          setLangStorage(langFromBrowser);
          localStorage.setItem('lang', langFromBrowser);
          window.location.reload();
        }
      } else if (token && userLanguage && userLanguage !== langStorage)
        saveProfileLanguage({
          variables: { input: { language: langStorage } },
        }).catch(() => console.log('lang err'));
    }
  }, [userDetailsLoading]);

  useEffect(() => {
    if (saveProfileLanguageData) window.location.reload();
  }, [saveProfileLanguageData]);

  useEffect(() => {
    const lang = localStorage.getItem('lang') === 'de' ? 'de' : 'en';
    new Promise<any>(() => {
      getShopCarousel(lang).then((result) => {
        const newLovedProducts =
          result?.landing?.layouts?.products &&
          result.landing.layouts.products.filter(
            (product) => !product?.hidden && product?.webshopProduct
          );
        newLovedProducts && setLovedProducts(newLovedProducts);
      });
    });
    new Promise<any>(() => {
      getShopLandingLayouts(lang).then((layout) => {
        const newAvailableProducts = filterProducts(layout?.products || [])
          .sort(
            (p1: ShopLandingProduct, p2: ShopLandingProduct) =>
              (p2.frontendPriority || 0) - (p1.frontendPriority || 0)
          )
          .map((p) => {
            let productVariantGroupConfig: ProductGroupType[] = [];
            if (p?.productVariantGroupConfig)
              try {
                productVariantGroupConfig = JSON.parse(
                  p.productVariantGroupConfig
                ).grouping;
              } catch (e) {
                console.log(e);
              }
            return { ...p, productVariantGroupConfig };
          });

        const newCategories =
          layout.categories &&
          layout.categories.sort(
            (c1: ShopCategory, c2: ShopCategory) =>
              (c2.frontendPriority || 0) - (c1.frontendPriority || 0)
          );

        setAvailableProducts(newAvailableProducts);
        setMainCategories(
          newCategories?.filter(
            (category) =>
              getCategoryOptions(newAvailableProducts, category?.id)?.length
          )
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zenDeskBtn = useMemo(
    () => (
      <div className='zendeskButton'>
        <HelmetProvider>
          <Helmet>
            <script
              id='ze-snippet'
              src='https://static.zdassets.com/ekr/snippet.js?key=cb97d551-2e9c-4589-ae09-2fa47b59c8ee'
            >
              {`${((window as any).zESettings = {
                webWidget: {
                  offset: {
                    horizontal: '10px',
                    vertical: isCookiesPanelVisible ? '80px' : '20px',
                    mobile: {
                      horizontal: '-100px',
                      vertical: '-100px',
                    },
                  },
                },
              })}`}
            </script>
          </Helmet>
        </HelmetProvider>
      </div>
    ),
    [isCookiesPanelVisible]
  );

  useEffect(() => {
    setIsSuccessPageView(Boolean(sessionStorage.getItem('isSuccessPageView')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userOffline = () => {
    toast(t('toasts.connectionLost'), { type: 'error' });
  };

  useEffect(() => {
    window.addEventListener('offline', userOffline);
    return () => window.removeEventListener('offline', userOffline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) sessionStorage.setItem('userIdentify', String(false));
  }, [token]);

  useEffect(() => {
    const user = userDetails?.guest?.profile;
    if (user && token && sessionStorage.getItem('userIdentify') !== 'true') {
      sessionStorage.setItem('userIdentify', String(true));
      sendAnalytics(
        user.id,
        {
          name: user.firstName + ' ' + user.lastName,
          language: user.language,
          email: user.email,
        },
        SegmentAnalyticsTypes.IDENTIFY
      );
    }
  }, [userDetails, token]);

  return (
    <Switch>
      <Route path='/productWidget'>
        <ProductWidget />
      </Route>
      {/* @ts-ignore */}
      <Route exact path='/'>
        {isBuyTicketView ? (
          <>
            <MainContainerWrapper>
              <BuyTicketView />
            </MainContainerWrapper>
            <CookiesPanel />
            {zenDeskBtn}
          </>
        ) : (
          <>
            <MainContainerWrapper>
              <StartViewPage isMobile={false} tiles={true} />
            </MainContainerWrapper>
            <CookiesPanel />
            {zenDeskBtn}
          </>
        )}
      </Route>
      <Route path='/buy-tickets'>
        <ProductTemplate>
          <MainContainerWrapper>
            <BuyTicketView />
          </MainContainerWrapper>
          <CookiesPanel />
          {zenDeskBtn}
        </ProductTemplate>
      </Route>
      <Route path='/checkout/view-cart'>
        <MainContainerWrapper authenticated>
          <CheckoutView />
        </MainContainerWrapper>
        <CookiesPanel />
        {zenDeskBtn}
      </Route>
      <Route path='/checkout/payment-info'>
        <AuthenticatedWrapper>
          <Payment />
        </AuthenticatedWrapper>
      </Route>
      <Route path='/checkout/complete'>
        <MainContainerWrapper authenticated>
          <StartViewPage isMobile={false} tiles={true} />
        </MainContainerWrapper>
      </Route>

      <Route path='/payment/abort'>
        <MainContainerWrapper authenticated>
          <PaymentAbort />
        </MainContainerWrapper>
      </Route>
      <Route path='/payment/fail'>
        <MainContainerWrapper authenticated>
          <PaymentFail />
        </MainContainerWrapper>
      </Route>
      <Route path='/payment/success'>
        <MainContainerWrapper authenticated>
          <PaymentSuccess />
        </MainContainerWrapper>
      </Route>
      <Route path='/user-panel'>
        <MainContainerWrapper authenticated>
          <UserPanel />
        </MainContainerWrapper>
        <CookiesPanel />
        {zenDeskBtn}
      </Route>
      <Route path='*'>
        <Redirect404 />
      </Route>
    </Switch>
  );
};

export default AppRouter;
