import { Auth0LockPasswordless } from 'auth0-lock';

const { REACT_APP_AUTH_CLIENT_ID, REACT_APP_AUTH_DOMAIN } = process.env;
const domain = `${REACT_APP_AUTH_DOMAIN ?? ''}` ?? '';
const clientId = `${REACT_APP_AUTH_CLIENT_ID ?? ''}` ?? '';

const language = Boolean(localStorage.getItem('lang') === 'en') ? 'en' : 'de';
const options = {
  configurationBaseUrl: 'https://cdn.eu.auth0.com',
  allowedConnections: ['email', 'apple', 'google-oauth2'],
  passwordlessMethod: 'code',
  auth: {
    autoParseHash: true,
    redirect: false,
    responseType: 'token',
    params: {
      scope: 'openid email',
    },
  },
  theme: {
    logo: 'https://s3.eu-central-1.amazonaws.com/laax-image-repo/logos/laax-200.png',
    primaryColor: '#333333',
  },
  language,
  languageDictionary: {
    title: 'LAAX Ticket Shop',
  },
  avatar: null,
  autofocus: true,
  autoclose: true,
  rememberLastLogin: false,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
};

export const lock = new Auth0LockPasswordless(clientId, domain, options);
