import { FC } from 'react';
import FlimsLaaxLogo from '../../atoms/FlimsLaaxLogo/FlimsLaaxLogo';
import flimsLaaxLogo from '../../../assets/flimsLaaxLogo3.png';
import './BookTicketStatusBar.css';
import { IBookTicketStatusBar } from './type';

const BookTicketStatusBar: FC<IBookTicketStatusBar> = () => {
  return (
    <div className='bookTicketStatusBarContainer'>
      <div className='logoBookTicketStatusBar'>
        <FlimsLaaxLogo alt='Flims Laax Logo' src={flimsLaaxLogo} />
      </div>
    </div>
  );
};

export default BookTicketStatusBar;
