import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../SubmitButton/SubmitButton';

import { ILoginButton } from '../../../../interfaces/AtomsRelatedInterfaces';

import { lock } from '../../../../utils/loginRelated';
import ErrorToast from '../../ErrorToast/ErrorToast';
import SmallText from '../../SmallText/SmallText';
import { sendAnalytics } from '../../../../utils/sendAnalytics';

const token = localStorage.getItem('token');
const LoginButton: FC<ILoginButton> = ({
  disabled,
  navigationRoute,
  text,
  buttonText,
  allowedConnections,
  iconComponent,
  onClickFunction,
}) => {
  const { t } = useTranslation();
  const [error, setIsError] = useState(0);
  const [errorDescription, setErrorDescription] = useState('');

  const checkError = (error: any) => {
    const errDesc = error?.error_description;
    if (error) {
      if (errDesc !== 'Login required' && error?.statusCode === 429) {
        setIsError(429);
      } else if (errDesc && errDesc !== 'Login required') {
        setIsError(1);
        setErrorDescription(errDesc);
      }
      console.log(error);
    }
  };

  const handleAuthorizeClick = () => {
    onClickFunction && onClickFunction();
    if (!token) {
      lock.checkSession({}, function (error, authResult) {
        setTimeout(() => {
          if (!authResult || !token) {
            lock.show({ allowedConnections });
            lock.on('hide', function () {
              lock.hide();
            });
            lock.on('authenticated', function (authResult) {
              if (authResult?.accessToken) {
                sendAnalytics('User Login', { accountType: 'tickets.laax' });
                localStorage.setItem('token', authResult.accessToken);
                window.location.href = navigationRoute;
              }
            });
          } else if (error) {
            error && checkError(error);
          } else {
            window.location.href = navigationRoute;
          }
        }, 1000);
      });
    } else window.location.href = navigationRoute;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  return (
    <>
      {error ? <ErrorToast statusCode={error} text={errorDescription} /> : null}
      {text ? (
        <div onClick={handleAuthorizeClick} style={{ cursor: 'pointer' }}>
          <SmallText isLink text={text} />
        </div>
      ) : (
        <Button
          className='submit-button'
          disabled={disabled}
          onClick={handleAuthorizeClick}
          style={{ width: '100%' }}
        >
          {iconComponent ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <div style={{ padding: '0 14px' }}>{iconComponent}</div>
              {buttonText || t('commons.next')}
            </div>
          ) : (
            buttonText || t('commons.next')
          )}
        </Button>
      )}
    </>
  );
};

export default LoginButton;
