import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { clearSessionStoragePersonTypesAmounts } from '../utils/productRelatedUtils';
import useClearAddons from './useClearAddons';
import useClearUpgrades from './useClearUpgrades';

const useClearSelectedData = () => {
  const {
    chosenPersonTypesState,
    chosenPartyMembersOnTicketState,
    ticketsStartDateState,
    ticketsEndDateState,
    discountTokenIdState,
  } = useContext(AppContext);

  const clearAddons = useClearAddons();
  const clearUpgrades = useClearUpgrades();
  const [, setChosenPersonTypes] = chosenPersonTypesState;
  const [, setChosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [, setTicketsStartDate] = ticketsStartDateState;
  const [, setTicketsEndDate] = ticketsEndDateState;
  const [, setDiscountTokenId] = discountTokenIdState;

  const clear = () => {
    clearAddons();
    clearUpgrades();
    clearSessionStoragePersonTypesAmounts();
    setChosenPersonTypes([]);
    setChosenPartyMembersOnTicket([]);
    setTicketsStartDate('');
    setTicketsEndDate('');
    setDiscountTokenId('');
  };
  return clear;
};
export default useClearSelectedData;
