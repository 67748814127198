import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../../BuyTicketView/BuyTicketView.css';
import '../CheckoutView.css';
import { NavigationContainerProps } from './type';
import Button from '../../../atoms/buttons/SubmitButton/SubmitButton';
import PayButton from '../../../atoms/buttons/PayButton/PayButton';

const NavigationContainer: FC<NavigationContainerProps> = ({
  isTicketsActive,
  isTotalCostActiveMobile,
  canGoToTotalCost,
  isSubmitBtnDisabled,
  handlePayment,
  setMobileView,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleNextToPayInCheckout = () => {
    setMobileView('totalCost');
  };
  const handleBackToFirstStep = () => {
    setMobileView('ticket');
    history.goBack();
  };
  const handleBackToTickets = () => {
    setMobileView('ticket');
  };

  return (
    <>
      {isTicketsActive ? (
        <div className='nextBackButtonsCheckout'>
          <Button
            className='submit-button'
            onClick={(): void => handleBackToFirstStep()}
          >
            {t('commons.back')}
          </Button>
          <Button
            className='submit-button'
            disabled={!canGoToTotalCost}
            onClick={(): void => handleNextToPayInCheckout()}
          >
            {t('commons.next')}
          </Button>
        </div>
      ) : null}
      {isTotalCostActiveMobile ? (
        <div className='nextBackButtonsCheckout'>
          <Button
            className='submit-button'
            onClick={(): void => handleBackToTickets()}
          >
            {t('commons.back')}
          </Button>
          <PayButton
            className='pay-button'
            disabled={isSubmitBtnDisabled}
            onClick={handlePayment}
          >
            {t('commons.next')}
          </PayButton>
        </div>
      ) : null}
    </>
  );
};

export default NavigationContainer;
