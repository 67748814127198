import { gql } from '@apollo/client';

export const SHOP_LOAD_OFFER = gql`
  query ShopLoadOffer($input: KeycardTicketPurchaseConfigInput!) {
    shopLoadOffer(input: $input) {
      totalPrice
      lineItems {
        title
        price
        subLineItems {
          title
          price
        }
      }
    }
  }
`;

export const SHOP_LOAD_OFFER_DISCOUNTS = gql`
  query SDT($input: KeycardTicketPurchaseConfigInput!) {
    shopLoadOfferDiscountTokens(input: $input) {
      id
      discount
      title
      description
      autoApply
    }
  }
`;
