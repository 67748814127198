import sanityClient from '@sanity/client';

import {
  ShopCarouselResponse,
  ShopLandingResponse,
} from './interfaces/ShopRelatedInterfaces';

export const sanityProjectId = 'prci4ijt';
export const sanityDataset = 'production';

export const sanityApiClient = sanityClient({
  projectId: sanityProjectId,
  dataset: sanityDataset,
  useCdn: true,
});

const PRODUCT_TYPES = ['shopProductAxessKeycardTicket'];

export const productTypesConditional = `_type in [${PRODUCT_TYPES.map(
  (type) => `"${type}"`
).join(',')}]`;

export const getShopLandingLayouts = async (
  lang: string,
  type: string = 'shopLandingLayout'
) => {
  const result = await sanityApiClient.fetch<ShopLandingResponse>(`
*[][0]{
"landing": *[_type == "${type}" && hidden != true][0]{
"layouts": layouts[]{
_type,
"title": title.${lang},
"description": description.${lang},
"products": products[]->{
"id": id.current,
"type": _type,
"title": title.${lang},
"description": shortDescription.${lang},
"image": images[0],
"category": category->title.${lang},
hidden
},
deepLinkUrl,
image
}
},
"categories": *[_type == "shopCategory"]{"id": id.current, "title": title.${lang}, "frontendPriority":frontendPriority, image},
"products": *[
(${productTypesConditional} || (_type in ["nextShopProduct"] && webshopProduct == true)) &&
!(showOnlyToGroup in ["guestcard-all", "guestcard-owner", "guestcard-guest"]) && hidden != true
]{
"id": id.current,
"type": _type,
"title": title.${lang},
"description": shortDescription.${lang},
"image": images[0],
"category": category->title.${lang},
"categoryId": category->id.current,
"searchKeywords": searchKeywords.${lang},
quantity[]{values[]->},
hidden,
productVariantGroupConfig,
"frontendPriority":frontendPriority
},
"termsAndConditionsUrl": *[_type == "settingsGlobal"][0].termsAndConditionsUrl.${lang}
}
`);

  return result;
};

export const getShopCarousel = async (lang: string) => {
  const result = await sanityApiClient.fetch<ShopCarouselResponse>(`
*[][0]{
"landing": *[_type == "winterShopLandingLayout"][0]{
"layouts": layouts[0]{
_type,
"title": title.${lang},
"description": description.${lang},
"products": products[]->{
"id": id.current,
"type": _type,
"title": title.${lang},
"description": shortDescription.${lang},
"image": images[0],
"category": category->title.${lang},
hidden,
webshopProduct,
},
deepLinkUrl,
image
}
}
}
`);

  return result;
};
