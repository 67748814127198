import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { AddonType } from '../components/molecules/Addons/type';
import { UpgradeType } from '../components/molecules/Upgrades/type';
import priceDivided from '../utils/priceDivided';

export default (
  shopDraftResult: any,
  addonsFromSettings: any = [],
  upgradesFromSettings: any = []
) => {
  const {
    chosenAddonsState,
    chosenUpgradesState,
    chosenPersonTypesState,
    availableProductsState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const [chosenAddons] = chosenAddonsState;
  const [chosenUpgrades] = chosenUpgradesState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [availableProducts] = availableProductsState;
  const [chosenSubcategory] = chosenSubcategoryState;

  const addons =
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes?.addons;
  const upgrades =
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes?.upgrades;

  const basePricePerPersonType =
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes
      ?.basePricePerPersonType || [];

  const addonsPrice = chosenAddons
    .map(
      (addon: string) =>
        addons?.find((a: AddonType) => a.id === addon).totalPrice || 0
    )
    .reduce((a: number, b: number) => a + b, 0);

  const upgradesPrice = chosenUpgrades
    .map(
      (upgrade: string) =>
        upgrades?.find((u: UpgradeType) => u.id === upgrade).totalPrice || 0
    )
    .reduce((a: number, b: number) => a + b, 0);

  const shopProductTypePrice = (type: string): number | undefined =>
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes?.basePricePerPersonType?.find(
      (person: any) => person?.type === type
    )?.price;

  const uniqueChosenPersonTypes = chosenPersonTypes?.filter(
    (cPT: string, pos: number) => chosenPersonTypes.indexOf(cPT) == pos
  );
  const uniqueChosenAddons = chosenAddons?.filter(
    (cPT: string, pos: number) => chosenAddons.indexOf(cPT) == pos
  );
  const uniqueChosenUpgrades = chosenUpgrades?.filter(
    (cPT: string, pos: number) => chosenUpgrades.indexOf(cPT) == pos
  );
  const product = availableProducts.find(
    (aP: any) => aP.id === chosenSubcategory
  );

  const productsGoogleAnalytics = [
    ...basePricePerPersonType.map(
      (personType: { type: string; price: number; total: number }) => ({
        item_id: product?.id,
        item_name: product?.title,
        affiliation: 'tickets.laax.com',
        currency: 'CHF',
        item_category: product?.category,
        variant: personType.type,
        price: priceDivided(personType.price),
        quantity: chosenPersonTypes.filter(
          (cPT: string) => cPT === personType.type
        ).length,
      })
    ),
    ...addonsFromSettings.map((addon: { id: string; title: string }) => ({
      item_id: addon?.id,
      item_name: addon?.title,
      affiliation: 'tickets.laax.com',
      item_category: product?.category,
      variant: addon?.id,
      currency: 'CHF',
      price: priceDivided(
        addons?.find(({ id }: { id: string }) => id === addon.id)?.totalPrice
      ),
      quantity: chosenAddons?.filter((cPT: string) => cPT === addon.id).length,
    })),
    ...upgradesFromSettings?.map((upgrade: { id: string; title: string }) => ({
      item_id: upgrade?.id,
      item_name: upgrade?.title,
      affiliation: 'tickets.laax.com',
      item_category: product?.category,
      variant: upgrade?.id,
      currency: 'CHF',
      price: priceDivided(
        upgrades?.find(({ id }: { id: string }) => id === upgrade.id)
          ?.totalPrice
      ),
      quantity: chosenUpgrades.filter((cPT: string) => cPT === upgrade.id)
        .length,
    })),
  ];

  const productsAnalytics = {
    products: [
      ...uniqueChosenPersonTypes.map((u: string) => ({
        product_id: product?.id,
        sku: product?.id + '--' + u,
        category: product?.category,
        name: product?.title,
        variant: u,
        currency: 'CHF',
        price: priceDivided(shopProductTypePrice(u)),
        quantity: chosenPersonTypes.filter((cPT: string) => cPT === u).length,
      })),
      ...uniqueChosenAddons.map((u: string) => {
        const addon = addonsFromSettings?.find(
          ({ id }: { id: string }) => id === u
        );
        return {
          product_id: addon?.id,
          sku: product?.id + '--' + addon?.id,
          category: product?.category,
          name: addon?.title,
          variant: addon?.id,
          currency: 'CHF',
          price: priceDivided(
            addons?.find(({ id }: { id: string }) => id === u)?.totalPrice
          ),
          quantity: chosenAddons.filter((cPT: string) => cPT === u).length,
        };
      }),
      ...uniqueChosenUpgrades.map((u: string) => {
        const upgrade = upgradesFromSettings?.find(
          ({ id }: { id: string }) => id === u
        );
        return {
          product_id: upgrade?.id,
          sku: product?.id + '--' + upgrade?.id,
          category: product?.category,
          name: upgrade?.title,
          variant: upgrade?.id,
          currency: 'CHF',
          price: priceDivided(
            upgrades?.find(({ id }: { id: string }) => id === u)?.totalPrice
          ),
          quantity: chosenUpgrades.filter((cPT: string) => cPT === u).length,
        };
      }),
    ],
  };
  return {
    addons,
    upgrades,
    addonsPrice,
    upgradesPrice,
    productsAnalytics,
    productsGoogleAnalytics,
  };
};
