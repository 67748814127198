import { FC } from 'react';

import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';

import '../../../index.css';

import { ISmallMainViewTile } from '../../../interfaces/SmallMainViewTileRelatedInterfaces';
import './SmallMainViewTile.css';
import dotsIcon from '../../../icons/3dots-icon.svg';
import Icon from '../../atoms/Icon/Icon';

const SmallMainViewTile: FC<ISmallMainViewTile> = ({
  imgSrc,
  onClick,
  title,
}) => {
  return (
    <div className='small-main-view-tile-wrapper' onClick={onClick}>
      <div className='small-main-view-tile-container'>
        <div
          style={{
            backgroundImage: `url(${imgSrc})`,
          }}
          className='small-main-view-tile-img'
        >
          <div className='dots-menu-container'>
            <MainOptionButton>
              <Icon alt='default icon' src={dotsIcon} />
            </MainOptionButton>
          </div>
        </div>
        <div className='small-main-view-title'>
          <h4>{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default SmallMainViewTile;
