import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import useClearSelectedData from '../../../hooks/useClearSelectedData';
import Spinner from '../../atoms/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import './styled.css';
import useURLQuery from '../../../hooks/useURLQuery';
import { ShopLandingProduct } from '../../../interfaces/ShopRelatedInterfaces';

const ProductTemplate: FC<{ children: React.ReactNode }> = ({ children }) => {
  const query = useURLQuery();
  const categoryId = query.get('productId');
  const groupId = query.get('groupId');
  const { t } = useTranslation();
  const clearSelectedData = useClearSelectedData();

  const {
    chosenSubcategoryState,
    chosenMainCategoryState,
    availableProductsState,
    buyTicketViewState,
    chosenGroupCategoryState,
  } = useContext(AppContext);

  const [availableProducts] = availableProductsState;
  const [chosenSubcategory, setChosenSubcategory] = chosenSubcategoryState;
  const [, setChosenMainCategory] = chosenMainCategoryState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [chosenGroupCategory, setChosenGroupCategory] =
    chosenGroupCategoryState;
  const [loading, setLoading] = useState(true);
  const newSubcategory: ShopLandingProduct = availableProducts?.find(
    (aP: any) => aP.id === categoryId
  );

  const newProductByGroupId = (availableProducts as ShopLandingProduct[])?.find(
    (aP) =>
      aP.productVariantGroupConfig.find(
        (p) => p?.['ticket-group']?.id === groupId
      )
  );
  const newGroupByProductId =
    newSubcategory?.productVariantGroupConfig?.[0]?.['ticket-group']?.id;

  useEffect(() => {
    setIsBuyTicketView('true');
    sessionStorage.setItem('isBuyTicketView', String(true));
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [setIsBuyTicketView]);

  // SET PRODUCT BY PRODUCT ID FROM QUERY
  useEffect(() => {
    if (newSubcategory && newSubcategory.id !== chosenSubcategory) {
      clearSelectedData();
      setChosenMainCategory(newSubcategory.categoryId);
      setChosenSubcategory(newSubcategory.id);
      sessionStorage.setItem(
        'chosenMainCategory',
        String(newSubcategory.categoryId)
      );
      sessionStorage.setItem('chosenSubcategory', String(newSubcategory.id));
    }
  }, [
    newSubcategory,
    chosenSubcategory,
    clearSelectedData,
    setChosenMainCategory,
    setChosenSubcategory,
  ]);

  // SET GROUP BY PRODUCT ID FROM QUERY
  useEffect(() => {
    if (
      setChosenGroupCategory &&
      newGroupByProductId &&
      newGroupByProductId !== chosenGroupCategory
    ) {
      setChosenGroupCategory(newGroupByProductId);
      sessionStorage.setItem(
        'chosenGroupCategory',
        String(newGroupByProductId)
      );
    }
  }, [newGroupByProductId, chosenGroupCategory]);

  // SET GROUP AND PRODUCT BY GROUP ID FROM QUERY
  useEffect(() => {
    if (
      !categoryId &&
      groupId &&
      groupId !== chosenGroupCategory &&
      newProductByGroupId
    ) {
      const newMainCategory =
        newProductByGroupId.productVariantGroupConfig.find(
          (p) => p['ticket-group'].id === groupId
        )?.['navigation-group']?.id;
      setChosenSubcategory('');
      setChosenGroupCategory(groupId);
      setChosenMainCategory(newMainCategory);
      sessionStorage.setItem('chosenMainCategory', String(newMainCategory));
      sessionStorage.setItem('chosenGroupCategory', String(groupId));
      sessionStorage.setItem('chosenSubcategory', String(''));
    }
  }, [groupId, categoryId, newProductByGroupId, chosenGroupCategory]);

  return (
    <>
      {!categoryId || newSubcategory || !groupId || newProductByGroupId ? (
        children
      ) : loading ? (
        <Spinner customDIVHeight='100vh' />
      ) : (
        <div className={'noAccessContainer'}>
          {t('commons.noAccessToProduct')}
        </div>
      )}
    </>
  );
};

export default ProductTemplate;
