import { gql } from '@apollo/client';

const SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES = gql`
  query GetOfferDraftForPersonTypes($input: PersonTypeLoadOfferSummaryInput!) {
    shopLoadOfferDraftForPersonTypes(input: $input) {
      basePrice
      basePricePerPersonType {
        total
        price
        type
      }
      upsaleDates {
        date
        dayPrice
      }
      upgrades {
        id
        summaryPrice
        totalPrice
        summaryPriceType
        quantityAvailable
      }
      addons {
        id
        summaryPrice
        totalPrice
        summaryPriceType
        quantityAvailable
      }
    }
  }
`;

export const GET_BASE_PRICE_FROM_SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES = gql`
  query GetBasePriceFromOfferDraftForPersonTypes(
    $input: PersonTypeLoadOfferSummaryInput!
  ) {
    shopLoadOfferDraftForPersonTypes(input: $input) {
      basePrice
      addons {
        id
        summaryPrice
        totalPrice
        summaryPriceType
        quantityAvailable
      }
      upgrades {
        id
        summaryPrice
        totalPrice
        summaryPriceType
        quantityAvailable
      }
    }
  }
`;

export default SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES;
