import { useQuery } from '@apollo/client';
import { FC, useEffect, useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import AddMemberModal from '../../molecules/AddMemberModal/AddMemberModal';
import ErrorToast from '../../atoms/ErrorToast/ErrorToast';
import Spinner from '../../atoms/Spinner/Spinner';
import Subheader from '../../atoms/Subheader/Subheader';
import ITicketsSummary from '../../../interfaces/TicketsSummaryRelatedInterfaces';
import ticketIcon from '../../../icons/ticket-icon-red.svg';
import SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES from '../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import './TicketsSummary.css';
import { FETCH_PARTY_MEMBERS } from '../../../graphql/user/user';
import SummaryTicketForm from '../SummaryTicketForm';
import { useDatesAndDaysText } from './utils';
import { SummaryTicketValue } from '../SummaryTicketForm/types';
import { OptionPartyMember } from './type';
import ProductTitle from './ProductTitle';
import { SHOP_LOAD_PRODUCT } from '../../../graphql/product/product';
import SummaryTicketWithoutPartyMembers from './SummaryTicketWithoutPartyMembers';
import Addons from './Addons';
import Upgrades from './Upgrades';

const TicketsSummary: FC<ITicketsSummary> = ({
  endDate,
  startDate,
  chosenSubcategory,
  filteredPersonTypes,
  ticketWithPartMembers,
  addons,
  upgrades,
  upgradesFromSettings,
  addonsFromSettings,
  getUserKeyCardPrice,
}) => {
  const { chosenPartyMembersOnTicketState } = useContext(AppContext);

  const fetchedPartyMembers = useQuery(FETCH_PARTY_MEMBERS, {
    fetchPolicy: 'network-only',
  });
  const statusCode =
    fetchedPartyMembers.error?.message.indexOf('400') !== -1 ? 400 : undefined;
  const datesAndDaysText = useDatesAndDaysText(startDate, endDate);
  const [, setChosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [toggleAddMemberModal, setToggleAddMemberModal] = useState(false);
  const [clickedPersonType, setClickedPersonType] = useState('');
  const [partyMembersAdded, setPartyMembersAdded] = useState(false);
  const [idOldUser, setIdOldUser] = useState<string | null>(null);
  const [partyMembersTypeAdded, setPartyMembersTypeAdded] = useState<
    string | null
  >(null);
  const [optionsPartyMembers, setOptionsPartyMembers] = useState<
    OptionPartyMember[] | undefined
  >(undefined);
  const shopProduct = useQuery(SHOP_LOAD_PRODUCT, {
    fetchPolicy: 'network-only',
    variables: {
      productId: chosenSubcategory,
    },
    skip: !chosenSubcategory,
  });

  const productTypes =
    shopProduct?.data?.shopLoadKeycardTicketProduct?.personTypes || [];
  const productPersonTypes = productTypes.map((pT: any) => pT.id);

  const getPersonTypesTitle = (personType: string) =>
    productTypes.filter((t: any) => t.id === personType)?.[0]?.title;

  const productIncludeSeniorType = Boolean(
    productPersonTypes.find(
      (pT: string) => pT === 'senior' || pT === 'senior-v2'
    )
  );

  const fetchedShopPersonTypeData = useQuery(
    SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          startDate,
          endDate,
          productId: chosenSubcategory,
          personTypes: filteredPersonTypes,
        },
      },
      skip:
        !startDate ||
        !endDate ||
        !chosenSubcategory ||
        filteredPersonTypes[0] === '' ||
        filteredPersonTypes === [],
    }
  );

  const statusCodePersonTypes =
    fetchedShopPersonTypeData?.error?.message?.indexOf('400') !== -1
      ? 400
      : undefined;
  const isShopPersonTypeDataLoading = fetchedShopPersonTypeData?.data?.loading;
  const fetchErrorPersonTypes = fetchedShopPersonTypeData?.error?.message;

  const onSubmit = (values: SummaryTicketValue): void => {
    const arraysOfPartyMembersIDs = Object.values(values).map((value) =>
      Object.values(value)
    );

    const arrayPartyMembersIDs = arraysOfPartyMembersIDs
      .flat()
      ?.filter((v) => v !== undefined);

    setChosenPartyMembersOnTicket(arrayPartyMembersIDs);
    sessionStorage.setItem(
      'chosenPartyMembersOnTicket',
      String(arrayPartyMembersIDs)
    );

    optionsPartyMembers &&
      setOptionsPartyMembers(
        optionsPartyMembers.map((member) => ({
          ...member,
          hidden: arrayPartyMembersIDs.includes(member.value),
        }))
      );
  };

  const onAddPersonClick = (type: string, idOldUser: string) => {
    if (toggleAddMemberModal) {
      setToggleAddMemberModal(false);
      setPartyMembersTypeAdded(null);
      setIdOldUser(null);
    } else {
      setToggleAddMemberModal(true);
      setPartyMembersTypeAdded(type);
      setClickedPersonType(type.split('.')[0]);
      setIdOldUser(idOldUser);
    }
  };

  const addMemberModalRefresh = async () => {
    await fetchedPartyMembers.refetch();
    setPartyMembersAdded(true);
  };

  const basePricePerPersonType =
    fetchedShopPersonTypeData?.data?.shopLoadOfferDraftForPersonTypes
      ?.basePricePerPersonType;
  useEffect(() => {
    if (fetchedPartyMembers.data) {
      let newPartyMembers = fetchedPartyMembers.data.guest.partyMembers.map(
        (pM: any) => ({
          label: `${pM.firstName} ${pM?.lastName || ''}`,
          value: pM.id,
          hidden: false,
          dateOfBirth: pM.dateOfBirth,
        })
      );
      newPartyMembers && setOptionsPartyMembers(newPartyMembers);
    }
  }, [fetchedPartyMembers.data]);

  useEffect(() => {
    fetchedShopPersonTypeData.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPersonTypes]);

  return (
    <div className='ticketSummaryContainer'>
      {fetchedPartyMembers.error && <ErrorToast statusCode={statusCode} />}
      {fetchErrorPersonTypes && (
        <ErrorToast statusCode={statusCodePersonTypes} />
      )}
      <Subheader isBlack icon={ticketIcon} text='Tickets' />
      {isShopPersonTypeDataLoading || fetchedPartyMembers.loading ? (
        <Spinner />
      ) : (
        <>
          <Addons addons={addons} addonsFromSettings={addonsFromSettings} />
          <Upgrades
            upgrades={upgrades}
            upgradesFromSettings={upgradesFromSettings}
          />
          {basePricePerPersonType && (
            <ProductTitle
              {...{
                chosenSubcategory,
                basePricePerPersonType,
                filteredPersonTypes,
              }}
            />
          )}
          <span style={{ color: '#969696', fontSize: '16px' }}>
            {datesAndDaysText}
          </span>
          {ticketWithPartMembers ? (
            <>
              {optionsPartyMembers && basePricePerPersonType && (
                <SummaryTicketForm
                  {...{
                    basePricePerPersonType,
                    onSubmit,
                    onAddPersonClick,
                    partyMembersTypeAdded,
                    optionsPartyMembers,
                    partyMembersAdded,
                    setPartyMembersAdded,
                    productIncludeSeniorType,
                    getPersonTypesTitle,
                    chosenSubcategory,
                    getUserKeyCardPrice,
                  }}
                />
              )}
            </>
          ) : (
            <SummaryTicketWithoutPartyMembers
              basePricePerPersonType={basePricePerPersonType}
              getPersonTypesTitle={getPersonTypesTitle}
            />
          )}

          {ticketWithPartMembers && (
            <AddMemberModal
              isOpen={toggleAddMemberModal}
              isCheckoutView
              setToggleAddMemberModal={setToggleAddMemberModal}
              specifiedPersonType={clickedPersonType}
              idOldUser={idOldUser}
              refresh={addMemberModalRefresh}
              productIncludeSeniorType={productIncludeSeniorType}
              chosenSubcategory={chosenSubcategory}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TicketsSummary;
