import { FC, useContext } from 'react';

import { AppContext } from '../../../AppContext';
import { ISuccesPage } from '../../../interfaces/MainPageRelatedInterfaces';

import ToggleMenuDefaultView from './ToggleMenuDefaultView/ToggleMenuDefaultView';
import ToggleMenuWithListedOptions from './ToggleMenuWithListedOptions/ToggleMenuWithListedOptions';
import './ToggleSideMenu.css';
const ToggleSideMenu: FC<ISuccesPage> = ({ isSuccessPage }) => {
  const { menuHiddenState } = useContext(AppContext);
  const [isMenuHidden] = menuHiddenState;
  return (
    <div className='toggleSideMenu'>
      {isMenuHidden ? (
        <div className='toggleMenuDefaultViewWrapper'>
          <ToggleMenuDefaultView isSuccessPage={isSuccessPage} />
        </div>
      ) : (
        <div className='toggleMenuWithListedOptionsWrapper'>
          <ToggleMenuWithListedOptions />
        </div>
      )}
    </div>
  );
};

export default ToggleSideMenu;
