import React, { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import UrlReplaceStateWrapper from '../../molecules/UrlReplaceStateWrapper';

const BuyTicketWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { chosenPersonTypesState, chosenPersonFieldsState, menuHiddenState } =
    useContext(AppContext);
  const [, setIsMenuHidden] = menuHiddenState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [, setChosenPersonFields] = chosenPersonFieldsState;

  useEffect(() => {
    if (chosenPersonTypes) {
      const personFields = chosenPersonTypes.map(
        (pT: string, index: number) => `${pT}.${index}`
      );
      setChosenPersonFields(personFields);
      sessionStorage.setItem('chosenPersonFields', String(personFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPersonTypes]);

  useEffect(() => {
    setIsMenuHidden(true);
  }, []);

  return (
    <UrlReplaceStateWrapper path={'buy-tickets'}>
      <div style={{ minHeight: '101vh' }}>{children}</div>
    </UrlReplaceStateWrapper>
  );
};

export default BuyTicketWrapper;
