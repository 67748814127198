import { FC } from 'react';
import { ICustomerTypeTicket } from '../../../../interfaces/CustomerTypeTicketRelatedInterfaces';
import AmountCounter from '../../../atoms/AmountCounter/AmountCounter';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import useTypeDescription from '../../../../hooks/useTypeDescription';

const CustomerTypeTicket: FC<ICustomerTypeTicket> = ({
  amount,
  personType,
  ticketBasedOnPersonType,
  title,
  maximumAge,
  minimumAge,
}) => {
  const ageText =
    maximumAge && minimumAge ? `${minimumAge}-${maximumAge}` : undefined;

  const description = useTypeDescription(personType, ageText);

  return (
    <div
      style={{
        borderBottom: '1px solid #f0f0f0',
        padding: '10px 0',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>{title}</h3>
        <AmountCounter
          amount={amount}
          personType={personType}
          price={
            ticketBasedOnPersonType
              ? Number(formatPriceForDisplay(ticketBasedOnPersonType))
              : undefined
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <p className='small-grey-text'>{description}</p>
          <p className='small-grey-text'>
            CHF{' '}
            {ticketBasedOnPersonType
              ? formatPriceForDisplay(ticketBasedOnPersonType)
              : '-'}
          </p>
        </div>
        <span className='amount-counter-total-price'>
          CHF{' '}
          {ticketBasedOnPersonType
            ? formatPriceForDisplay(ticketBasedOnPersonType * amount)
            : '-'}
        </span>
      </div>
    </div>
  );
};

export default CustomerTypeTicket;
