/* eslint-disable array-callback-return */
import { useQuery } from '@apollo/client';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import CustomerTypeTicket from './CustomerTypeTicket/CustomerTypeTicket';
import Subheader from '../../atoms/Subheader/Subheader';
import userIcon from '../../../icons/user-red.svg';
import './CustomerTypeTickets.css';
import { ICalendarDayForPersonType } from '../../../interfaces/BookingRelatesInterfaces';
import { ICustomerTypeTickets } from '../../../interfaces/CustomerTypeTicketRelatedInterfaces';
import Spinner from '../../atoms/Spinner/Spinner';
import { SHOP_LOAD_PRODUCT } from '../../../graphql/product/product';
import { PersonType } from '../../../interfaces/AtomsRelatedInterfaces';
import CustomerTypeTicketsWrapper from './CustomerTypeTicketsWrapper';
import availabilityDateColors from '../../../constans/availabilityDateColors';

const CustomerTypeTickets: FC<ICustomerTypeTickets> = ({
  defaultPersonTypes,
  shopDraftResult,
  startDate,
  calendarData,
  loadingCalendarData,
}) => {
  const [loadedPersonTypeFromQuery, setLoadedPersonTypeFromQuery] =
    useState(false);

  const { chosenPersonTypesState, chosenSubcategoryState } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [chosenSubcategory] = chosenSubcategoryState;
  const [chosenPersonTypes] = chosenPersonTypesState;

  const shopProduct = useQuery(SHOP_LOAD_PRODUCT, {
    fetchPolicy: 'network-only',
    variables: {
      productId: chosenSubcategory,
    },
    skip: !chosenSubcategory,
  });

  const productTypes =
    shopProduct?.data?.shopLoadKeycardTicketProduct?.personTypes ||
    defaultPersonTypes ||
    [];
  const productPersonTypes = productTypes.map((pT: any) => pT.id) || [];

  const shopProductTypePrice = (type: string): number | undefined =>
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes?.basePricePerPersonType?.find(
      (person: any) => person?.type === type
    )?.price;

  const availabilityDayColor = calendarData?.find(
    (day: ICalendarDayForPersonType) => day?.date === startDate
  )?.availability;

  const isTodayDateWithQuantityAvailable = availabilityDayColor
    ? availabilityDateColors.includes(availabilityDayColor)
    : false;

  const loading = loadingCalendarData || shopDraftResult?.loading;

  return (
    <div className='customer-type-tickets-container'>
      <Subheader
        isCentered
        icon={userIcon}
        text={t('commons.people')}
        isBlack
      />
      {!loading && !shopProduct.loading ? (
        <>
          {!isTodayDateWithQuantityAvailable && startDate ? (
            `${t('buyTicketView.noTicketsAvailableForDate')}.`
          ) : (
            <CustomerTypeTicketsWrapper
              productPersonTypes={productPersonTypes}
              {...{ loadedPersonTypeFromQuery, setLoadedPersonTypeFromQuery }}
            >
              {productPersonTypes.map((type: PersonType, index: number) => {
                const productType = productTypes?.filter(
                  (t: any) => t.id === type
                )?.[0];

                return (
                  <CustomerTypeTicket
                    key={type + '_' + index}
                    amount={
                      chosenPersonTypes?.filter((cPT: string) => cPT === type)
                        .length
                    }
                    title={productType?.title}
                    personType={type}
                    maximumAge={productType?.ageLimit?.max}
                    minimumAge={productType?.ageLimit?.min}
                    productId={chosenSubcategory}
                    ticketBasedOnPersonType={shopProductTypePrice(type)}
                  />
                );
              })}
            </CustomerTypeTicketsWrapper>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default CustomerTypeTickets;
