import { FC, useContext, useState } from 'react';
import { AddonProps } from './type';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import AmountButton from './AmountButton';
import { AppContext } from '../../../../AppContext';
import Details from './Details';
import { useTranslation } from 'react-i18next';
import SmallText from '../../../atoms/SmallText/SmallText';

const Addon: FC<AddonProps> = ({
  id,
  totalPrice,
  title,
  maxQuantity,
  infoContent,
}) => {
  const { chosenAddonsState } = useContext(AppContext);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [chosenAddons] = chosenAddonsState;
  const description = '';
  const amount = chosenAddons?.filter((cA: string) => cA === id).length;
  const { t } = useTranslation();

  return (
    <div
      style={{
        borderBottom: '1px solid #f0f0f0',
        padding: '10px 0',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: 0 }}>{title || id}</h3>
        <AmountButton
          id={id}
          amount={amount}
          price={totalPrice}
          maxQuantity={maxQuantity}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <p className='small-grey-text'>{description}</p>
          <p className='small-grey-text'>
            CHF {totalPrice ? formatPriceForDisplay(totalPrice) : '-'}
          </p>
        </div>
        <span className='amount-counter-total-price'>
          CHF {totalPrice ? formatPriceForDisplay(totalPrice * amount) : '-'}
        </span>
      </div>
      <div onClick={() => setDetailsOpen(true)}>
        <SmallText isLink isNoUppercase text={t('commons.learnMore')} />
      </div>
      <Details
        isOpen={detailsOpen}
        close={() => setDetailsOpen(false)}
        title={title}
        infoContent={infoContent}
      />
    </div>
  );
};

export default Addon;
