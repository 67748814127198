import React, { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import UrlReplaceStateWrapper from '../../molecules/UrlReplaceStateWrapper';
import { useHistory } from 'react-router-dom';
import PaymentOverlay from '../../organisms/PaymentOverlay/PaymentOverlay';
import OrderCompletedAnalytics from '../../molecules/OrderCompltedAnalytics';

type PaymentStatus = 'Cancel' | 'Complete' | 'Pending';

const Payment: FC = () => {
  const history = useHistory();
  const { purchaseState } = useContext(AppContext);
  const [purchase, setPurchase] = purchaseState;
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('Pending');
  const [successAnalyticsSend, setSuccessAnalyticsSend] = useState(false);
  const [tokenId, setTokenId] = useState<string | undefined>(undefined);

  const handleCloseWhenSuccess = () => {
    setTimeout(() => {
      setPurchase(null);
      sessionStorage.removeItem('purchase');
      setPaymentStatus('Complete');
    }, 1000);
  };
  const handleClose = () => {
    setPurchase(null);
    sessionStorage.removeItem('purchase');
    setPaymentStatus('Cancel');
  };

  useEffect(() => {
    if (!purchase) {
      if (paymentStatus === 'Complete' && successAnalyticsSend)
        history.push(`/checkout/complete${window.location.search}`);
      else if (paymentStatus === 'Cancel')
        history.push(`/checkout/view-cart${window.location.search}`);
    }
  }, [purchase, paymentStatus, successAnalyticsSend]);

  useEffect(() => {
    if (purchase?.tokenId) setTokenId(purchase.tokenId);
  }, [purchase]);

  return (
    <UrlReplaceStateWrapper path={history.location.pathname}>
      <div
        style={{
          height: 'calc(100vh - 40px)',
          width: '100vw',
        }}
      >
        <PaymentOverlay
          purchase={purchase}
          onClose={handleClose}
          handleCloseWhenSuccess={handleCloseWhenSuccess}
        />
        {paymentStatus === 'Complete' && (
          <OrderCompletedAnalytics
            setSuccessAnalyticsSend={setSuccessAnalyticsSend}
            tokenId={tokenId}
          />
        )}
      </div>
    </UrlReplaceStateWrapper>
  );
};

export default Payment;
