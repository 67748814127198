import React, { FC, useContext, useEffect, useState } from 'react';
import './ProductWidget.css';
import { AppContext } from '../../../AppContext';
import { ShopLandingProduct } from '../../../interfaces/ShopRelatedInterfaces';
import SelectList from '../../atoms/SelectList/SelectList';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import arrow from '../../../icons/arrow-simple-down-white.png';
import { useTranslation } from 'react-i18next';
import SelectDateModal from './SelectDateModal';
import {
  useCalendarQuery,
  useGetURLQueryData,
  useLoadOfferForPersonType,
  useProductQuery,
} from './hooks';

const ProductWidgetComponent: FC = () => {
  const {
    availableProductsState,
    chosenSubcategoryState,
    ticketsStartDateState,
    ticketsEndDateState,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [availableProducts] = availableProductsState;
  const [chosenSubcategory] = chosenSubcategoryState;
  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;
  const [amountPerson, setAmountPerson] = useState<any>(undefined);
  const [showInputs, setShowInputs] = useState<boolean>(false);
  const { buttonText, deepLink, startPrice, isMobile, backgroundColor } =
    useGetURLQueryData();

  const { selectedPersonType, maxConsecutiveDays, productLoading } =
    useProductQuery();

  const productName =
    chosenSubcategory &&
    availableProducts?.find(
      (product: ShopLandingProduct) => product?.id === chosenSubcategory
    )?.title;

  const cutProductName =
    !isMobile && productName && productName?.length > 34
      ? productName.substring(0, 34) + '...'
      : productName;

  const personTypes =
    Array(Number(amountPerson) || 0).fill(selectedPersonType) || [];

  const { calendarDates, calendarLoading } = useCalendarQuery(personTypes);
  const { price, offerForPersonType, offerForPersonTypeLoading } =
    useLoadOfferForPersonType(personTypes);

  const disabledButton =
    !ticketsStartDate ||
    !ticketsEndDate ||
    !amountPerson ||
    !selectedPersonType ||
    !price;

  const link =
    `${deepLink ? deepLink : 'https://tickets.laax.com/buy-tickets'}` +
    `?productId=${chosenSubcategory}&startDate=${ticketsStartDate}&endDate=${ticketsEndDate}&${selectedPersonType}=${amountPerson}`;

  const PriceComponent = (
    <div>
      {localStorage.getItem('lang') === 'en' ? 'from' : 'ab'}
      {' CHF '}
      {!offerForPersonTypeLoading &&
        formatPriceForDisplay(Number(price || startPrice || 0))}
    </div>
  );
  const widgetContainerHeight = showInputs
    ? isMobile
      ? '160px'
      : '250px'
    : undefined;

  const postMessage = () => {
    window.parent.postMessage('button-clicked', '*');
  };

  useEffect((): (() => void) => {
    const button = document.getElementById('widget-button');
    if (button) button.addEventListener('click', postMessage);
    return () => button && button.removeEventListener('click', postMessage);
  }, []);

  if (productLoading) return null;
  else
    return (
      <div
        className={'wrapper-widget-container'}
        style={{ height: isMobile ? '160px' : '250px' }}
      >
        <div
          className={isMobile ? 'widget-container-mobile' : 'widget-container'}
          style={{ height: widgetContainerHeight, backgroundColor }}
          onMouseEnter={() => {
            setShowInputs(true);
          }}
          onMouseLeave={() => {
            if (isMobile || (!ticketsStartDate && !amountPerson)) {
              setShowInputs(false);
            }
          }}
        >
          <div className={isMobile ? 'product-name-mobile' : 'product-name'}>
            {cutProductName || ''}
          </div>
          {isMobile && (
            <>
              {PriceComponent}
              <img
                src={arrow}
                style={{
                  cursor: 'pointer',
                  transform: showInputs ? 'rotate(180deg)' : undefined,
                }}
                alt={'image'}
              />
            </>
          )}
          <div
            className={isMobile ? 'inputs-mobile' : 'inputs'}
            style={{
              opacity: showInputs ? 1 : 0,
            }}
          >
            <SelectDateModal
              calendarDates={calendarDates}
              maxConsecutiveDays={maxConsecutiveDays}
              offerForPersonType={offerForPersonType}
              isMobile={isMobile}
              disabled={calendarLoading}
            />
            <SelectList
              additionalOnChange={(e) => setAmountPerson(e.target.value)}
              propValue={amountPerson}
              options={Array(9)
                .fill(null)
                .map((v, index) => ({
                  label: index + 1,
                  value: index + 1,
                }))}
              placeholder={t('commons.numberOfPeople')}
              iconClassName={'select-people'}
              width={isMobile ? '49%' : '100%'}
              isGrey
            />
          </div>

          <div
            className={isMobile ? 'product-details-mobile' : 'product-details'}
            style={{
              opacity: !isMobile || showInputs ? 1 : 0,
              height: !isMobile || showInputs ? '40px' : 0,
              backgroundColor,
            }}
          >
            {!isMobile && PriceComponent}
            <a
              className={
                isMobile ? 'submit-button-white-mobile' : 'submit-button-white'
              }
              id={'widget-button'}
              href={!disabledButton ? link : undefined}
              target='_blank'
              style={{ color: backgroundColor }}
            >
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    );
};

export default ProductWidgetComponent;
