import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AppContext } from '../../../AppContext';

import { useClearRestOfMobileViews } from '../../../hooks/useClearRestOfMobileViews';
import useClearProduct from '../../../hooks/useClearProduct';

interface IErrorMessage {
  statusCode?: number;
  text?: string;
}

const ToastWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div style={{ visibility: 'hidden', fontSize: 0 }}>{children}</div>;
};

const ErrorToast: FC<IErrorMessage> = ({ statusCode, text }): any => {
  const { buyTicketViewState } = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const clearProduct = useClearProduct();
  const toastToShow = (type: 'info' | 'warning' | 'error', text?: string) =>
    toast(text ?? t('toasts.error'), { type });
  const [, setIsBuyTicketView] = buyTicketViewState;
  const clearViews = useClearRestOfMobileViews(['successView']);

  useEffect(() => {
    clearProduct();
    setIsBuyTicketView(false);
    clearViews();
    sessionStorage.removeItem('isBuyTicketView');
    history.push('/');
  }, []);

  if (text) {
    return <ToastWrapper>{toastToShow('error', text)}</ToastWrapper>;
  } else if (statusCode && statusCode === 429) {
    return (
      <ToastWrapper>
        {toastToShow('warning', t('toasts.error429'))}
      </ToastWrapper>
    );
  } else {
    return (
      <ToastWrapper>
        {toastToShow(
          'error',
          `${t('toasts.error')} ${statusCode ? ` ${statusCode}` : ''} ${t(
            'toasts.occurred'
          )}`
        )}
      </ToastWrapper>
    );
  }
};

export default ErrorToast;
