import { FC } from 'react';

import { IMainStartPicture } from '../../../interfaces/MainPageRelatedInterfaces';
import FlimsLaaxLogo from '../../atoms/FlimsLaaxLogo/FlimsLaaxLogo';
import flimsLaaxLogo from '../../../assets/flimsLaaxLogo3.png';
import './MainStartPicture.css';

const MainStartPicture: FC<IMainStartPicture> = ({ src, alt }) => {
  return (
    <div className='mainPictureContainer'>
      <div className='logoContainerStartPicture'>
        <FlimsLaaxLogo alt='Flims Laax Logo' src={flimsLaaxLogo} />
      </div>

      <img src={src} alt={alt} className='mainPicture'></img>
    </div>
  );
};

export default MainStartPicture;
