import { FC } from 'react';
import Loader from 'react-loader-spinner';

import { ISpinner } from '../../../interfaces/AtomsRelatedInterfaces';

const Spinner: FC<ISpinner> = ({ customDIVHeight }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: customDIVHeight ?? undefined,
    }}
  >
    <Loader
      type='MutatingDots'
      color='rgb(196, 4, 4)'
      height={100}
      secondaryColor='#787878'
      width={100}
    />
  </div>
);
export default Spinner;
