import { FC, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Subheader from '../../atoms/Subheader/Subheader';
import './TicketsSummary.css';
import CloseRed from '../../../icons/closeRed.svg';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import { AddonType } from '../../molecules/Addons/type';

interface AddonsProps {
  addons: AddonType[];
  addonsFromSettings: {
    id: string;
    title: string;
  }[];
}
const Addons: FC<AddonsProps> = ({ addons, addonsFromSettings }) => {
  const { chosenAddonsState } = useContext(AppContext);
  const [chosenAddons, setChosenAddons] = chosenAddonsState;

  const uniqueChosenAddons = chosenAddons?.filter(
    (cA: string, pos: number) => chosenAddons.indexOf(cA) == pos
  );

  const deleteAddon = (addon: string) => {
    const newChosenAddons = chosenAddons?.filter((a: string) => a !== addon);
    setChosenAddons(newChosenAddons);
    sessionStorage.setItem('chosenAddons', String(newChosenAddons));
  };

  return (
    <div className={'customerTickets'}>
      {uniqueChosenAddons.map((cA: string, index: number) => (
        <div className={'itemContainer'} key={'addon' + index}>
          <div className={'itemSubHeader'}>
            <Subheader
              customPaddingBottom={0}
              customMarginBottom={1}
              isBlack
              text={
                chosenAddons?.filter((c: string) => c === cA).length +
                ' x ' +
                addonsFromSettings.find((u) => u.id === cA)?.title
              }
            />
          </div>
          <div className='itemPrice'>
            CHF{' '}
            {formatPriceForDisplay(
              chosenAddons?.filter((c: string) => c === cA).length *
                addons?.filter((a: any) => a.id === cA)[0].totalPrice ?? 0
            )}
          </div>
          <img
            alt='close-btn'
            src={CloseRed}
            className={'closeButton pointer'}
            onClick={() => deleteAddon(cA)}
          />
        </div>
      ))}
    </div>
  );
};

export default Addons;
