import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from 'react-modal';
import TicketMoreDetails from '../../atoms/TicketMoreDetails';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import moment from 'moment';
import { PersonTypeTitle } from '../../../mockups/personTypeTitle';
import ticketGrey from '../../../icons/ticket-icon-grey.svg';
import SmallText from '../SmallText/SmallText';
import { Order } from '../BookedTickets/type';

const TicketDetails: FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  const [showTicketsCarousel, setShowTicketsCarousel] = useState(false);
  const id = order?.orderNumber ?? '';
  const currency = 'CHF';
  const totalPrice = order?.price?.total ?? '';
  const totalWithCurrency = `${currency} ${formatPriceForDisplay(totalPrice)}`;
  const date = moment(
    order?.orderItems?.[0].dateRange?.start || order?.createdAt
  ).format('DD.MM.YYYY');

  const tickets = [...order?.orderItems].reduce((o: any, item: any) => {
    return {
      ...o,
      [item?.personTypeTitle]: o[item?.personTypeTitle]
        ? o[item?.personTypeTitle] + item?.quantity
        : item?.quantity,
    };
  }, {});

  const productName = order?.orderItems
    ? order?.orderItems[0]?.productName
    : '';

  return (
    <div>
      <span className='dateInBookedTicket'>
        {productName + ' (' + id + ')'}
      </span>
      <div
        style={{
          paddingTop: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <SmallText text={totalWithCurrency} />
        <SmallText isNoUppercase text={date} />
        {Object.keys(tickets).map((keyTickets, index) => (
          <div key={'PersonTypeTitle' + index}>
            <SmallText
              isNoUppercase
              text={
                tickets[keyTickets] + ' x ' + t(PersonTypeTitle(keyTickets))
              }
            />
          </div>
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingTop: 6,
          cursor: 'pointer',
        }}
        onClick={() => setShowTicketsCarousel(true)}
      >
        <img
          src={ticketGrey}
          alt='grey ticket icon'
          style={{ paddingRight: 6 }}
        />
        <SmallText
          isLink
          isNoUppercase
          text={
            order?.orderItems?.length > 1
              ? t('user.viewTickets')
              : t('user.viewTicket')
          }
        />
      </div>
      <Modal
        isOpen={showTicketsCarousel}
        className='carouselModal'
        style={{
          overlay: {
            zIndex: 2,
          },
        }}
      >
        <div
          style={{
            width: 325,
            margin: '50px auto',
          }}
        >
          <CarouselProvider
            naturalSlideWidth={325}
            naturalSlideHeight={900}
            totalSlides={order?.orderItems.length}
            orientation='horizontal'
            interval={1000}
            dragEnabled={true}
            visibleSlides={1}
          >
            <Slider>
              {order?.orderItems?.map((orderItem: any, index: number) => (
                <Slide
                  index={index}
                  key={'orderItem' + index}
                  style={{
                    width: '315px',
                    marginLeft: 10,
                  }}
                >
                  <TicketMoreDetails
                    {...orderItem}
                    ticketCode={id}
                    isLastTicket={index === order?.orderItems.length - 1}
                    isFirstTicket={index === 0}
                    qrCode={orderItem?.qrCode || order.qrCodes?.[0]?.data}
                    closeModal={() => setShowTicketsCarousel(false)}
                  />
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      </Modal>
    </div>
  );
};

export default TicketDetails;
