import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../AppContext';
import LanguageButtons from '../../../atoms/buttons/LanguageButtons/LanguageButtons';
import SmallMenuFooter from '../../SmallMenuFooter/SmallMenuFooter';
import ToggleMenuOption from '../ToggleMenuOption/ToggleMenuOption';
import './ToggleMenuWithListedOptions.css';
import LoginButton from '../../../atoms/buttons/LoginButton/LoginButton';

const ToggleMenuWithListedOptions: FC = () => {
  const { mainCategoriesState, menuHiddenState } = useContext(AppContext);
  const { t } = useTranslation();
  const [isMenuHidden, setIsMenuHidden] = menuHiddenState;
  const [mainCategories] = mainCategoriesState;

  return (
    <>
      <div className='toggleManuWithListedOptionsBox'>
        <div className='menu' onClick={() => setIsMenuHidden(!isMenuHidden)}>
          <span className='shortMenuLine' style={{ marginTop: '8px' }}></span>
          <span className='longMenuLine'></span>
          <span className='menuTitle'>{t('commons.menu')}</span>
        </div>
        <div>
          <LanguageButtons />
        </div>
        <LoginButton
          navigationRoute={`${window.location.origin}/user-panel`}
          text={t('user.yourAccount')}
        />
      </div>
      {mainCategories?.map((mainCategory: any, index: number) => (
        <ToggleMenuOption
          mainCategory={mainCategory}
          key={mainCategory.title + index + '-empty-div'}
        />
      ))}
      <div style={{ paddingTop: '16px' }}>
        <SmallMenuFooter />
      </div>
    </>
  );
};

export default ToggleMenuWithListedOptions;
