import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import './styled.css';
import { AppContext } from '../../../../AppContext';
import useIsAnyPersonIsChosenOnTicket from '../../../../hooks/useIsAnyPersonIsChosenOnTicket';
import { GET_BASE_PRICE_FROM_SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES } from '../../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import LoginButton from '../../../atoms/buttons/LoginButton/LoginButton';
import { TotalProps } from './type';
import { PersonTypeTitle } from '../../../../mockups/personTypeTitle';
import Subheader from '../../../atoms/Subheader/Subheader';
import circleRed from '../../../../icons/check-circle-red.svg';

const Total: FC<TotalProps> = ({
  productTitle,
  isTotalCostActive,
  productId,
  basePrice,
  setBasePrice,
  loginButtonDisabled,
  endDate,
  startDate,
  addonsFromSettings,
  upgradesFromSettings,
  nextClick,
}) => {
  const { t, i18n } = useTranslation();
  const {
    chosenPartyMembersOnTicketState,
    chosenPersonTypesState,
    chosenAddonsState,
    chosenUpgradesState,
    availableProductsState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const [chosenSubcategory] = chosenSubcategoryState;
  const [availableProducts] = availableProductsState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [chosenAddons] = chosenAddonsState;
  const [chosenUpgrades] = chosenUpgradesState;
  const isChosenPerson = useIsAnyPersonIsChosenOnTicket();
  const [sumToDisplay, setSumToDisplay] = useState(0);
  const uniqueChosenAddons = chosenAddons?.filter(
    (cA: string, pos: number) => chosenAddons.indexOf(cA) == pos
  );
  const uniqueChosenUpgrades = chosenUpgrades?.filter(
    (cA: string, pos: number) => chosenUpgrades.indexOf(cA) == pos
  );
  const data = useQuery(
    GET_BASE_PRICE_FROM_SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          startDate,
          endDate,
          productId: chosenSubcategory,
          personTypes: chosenPersonTypes,
        },
      },
      skip:
        !startDate ||
        !endDate ||
        !chosenSubcategory ||
        chosenPersonTypes[0] === '',
    }
  );
  const basePriceData = data?.data;
  const loading = data?.loading;

  useEffect(() => {
    productId &&
      setBasePrice(
        basePriceData?.shopLoadOfferDraftForPersonTypes?.basePrice ?? 0
      );
  }, [
    basePrice,
    basePriceData?.shopLoadOfferDraftForPersonTypes?.basePrice,
    productId,
    setBasePrice,
    chosenPersonTypes,
  ]);

  useEffect(() => {
    setSumToDisplay(isChosenPerson ? basePrice : 0);
  }, [
    basePrice,
    chosenPartyMembersOnTicket,
    chosenPersonTypes,
    isChosenPerson,
  ]);
  const countTickets: any = chosenPersonTypes.reduce(
    (accumulator: any, value: any) => {
      return { ...accumulator, [value]: (accumulator[value] || 0) + 1 };
    },
    {}
  );

  const selectedProductValuesSanity = availableProducts.filter(
    (p: any) => p.id == chosenSubcategory
  )?.[0]?.quantity?.[0].values;

  const getPersonTypeTitle = (personType: string) => {
    const titleObject = selectedProductValuesSanity?.filter(
      (v: any) => v?.backendCode === personType
    )?.[0]?.title;
    return i18n.language === 'en' ? titleObject?.en : titleObject?.de;
  };

  return (
    <div className='subtotalContainer'>
      <Subheader
        isCentered
        icon={circleRed}
        text={t('commons.subtotal')}
        isBlack
      />
      <div className={'customerTickets'}>
        {Boolean(chosenPersonTypes.length) && (
          <p className={'sumTickets'}>
            {chosenPersonTypes.length + ' x ' + productTitle}
          </p>
        )}
        {Object.keys(countTickets).map((key, index) => (
          <p className='small-grey-text' key={key + index}>
            {countTickets[key] + ' x '}
            {getPersonTypeTitle(key) || t(PersonTypeTitle(key))}
          </p>
        ))}
      </div>
      <div className={'customerTickets'}>
        {uniqueChosenAddons.map((cA: string, index: number) => (
          <p className={'sumTickets'} key={cA + index}>
            {chosenAddons?.filter((c: string) => c === cA).length +
              ' x ' +
              addonsFromSettings?.find((a) => a.id === cA)?.title}
          </p>
        ))}
        {uniqueChosenUpgrades.map((cU: string, index: number) => (
          <p className={'sumTickets'} key={cU + index}>
            {chosenUpgrades?.filter((c: string) => c === cU).length +
              ' x ' +
              upgradesFromSettings?.find((a) => a.id === cU)?.title}
          </p>
        ))}
      </div>
      <div className='totalCostsText'>
        <div>
          <h3 className='totalCostsH3'>{t('commons.subtotal')}</h3>
        </div>
        {!loading && (
          <div>
            <h3 className='totalCostsH3'>
              {'CHF ' + formatPriceForDisplay(sumToDisplay)}
            </h3>
          </div>
        )}
      </div>
      <div className={isTotalCostActive ? 'subTotalBox' : 'grayedSubTotalBox'}>
        <div className='loginButtonContainer'>
          <LoginButton
            onClickFunction={nextClick}
            disabled={loginButtonDisabled}
            navigationRoute={`${window.location.origin}/checkout/view-cart`}
          />
        </div>
      </div>
    </div>
  );
};

export default Total;
