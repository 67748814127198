import { useContext } from 'react';
import { AppContext } from '../AppContext';
import useURLQuery from './useURLQuery';

const useSetPersonTypesAmounts = (productPersonTypes: [string]) => {
  const query = useURLQuery();
  const { chosenPersonTypesState } = useContext(AppContext);

  const [chosenPersonTypes, setChosenPersonTypes] = chosenPersonTypesState;

  const setPersonTypes = () => {
    const newChosenPersonTypes: string[] = [];
    productPersonTypes.map((personType: string) => {
      const amount = Number(query.get(personType)) || 0;
      const stateAmount = chosenPersonTypes.filter(
        (cPT: string) => cPT === personType
      ).length;

      newChosenPersonTypes.push(
        ...Array(amount > 0 ? amount : stateAmount).fill(personType)
      );
    });
    setChosenPersonTypes(newChosenPersonTypes);
    sessionStorage.setItem('chosenPersonTypes', String(newChosenPersonTypes));
  };
  return setPersonTypes;
};
export default useSetPersonTypesAmounts;
