import { gql } from '@apollo/client';

export const FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER = gql`
  query {
    shopLoadPurchases {
      ... on TicketShopPurchase {
        orderNumber
        createdAt
        price {
          total
          discount
          refunded
          tokens {
            name
          }
        }
        qrCodes {
          data
          label
        }
        orderItems {
          fees
          productId
          productVariantId
          productName
          quantity
          personTypeTitle
          personTypeId
          price
          mediaId
          dateRange {
            start
          }
          qrCode
        }
      }
    }
  }
`;
