/* eslint-disable array-callback-return */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../../atoms/Subheader/Subheader';
import circleRed from '../../../../icons/check-circle-red.svg';
import './styles.css';
import LoginButton from '../../../atoms/buttons/LoginButton/LoginButton';
import { FaGoogle, FaRegEnvelope, FaApple } from 'react-icons/fa';

const SignIn: FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className='signIn-container'>
      <Subheader
        isCentered
        icon={circleRed}
        text={t('buyTicketView.signIn')}
        isBlack
      />
      <div className={'signIn-button-container'}>
        <LoginButton
          navigationRoute={'/'}
          buttonText={t('buyTicketView.logInWithEmail')}
          allowedConnections={['email']}
          iconComponent={<FaRegEnvelope size={20} />}
        />
      </div>
      <div className={'signIn-button-container'}>
        <LoginButton
          navigationRoute={'/'}
          buttonText={t('buyTicketView.logInWithGoogle')}
          allowedConnections={['google-oauth2']}
          iconComponent={<FaGoogle size={20} />}
        />
      </div>
      <div className={'signIn-button-container'}>
        <LoginButton
          navigationRoute={'/'}
          buttonText={t('buyTicketView.logInWithApple')}
          allowedConnections={['apple']}
          iconComponent={<FaApple size={20} />}
        />
      </div>
    </div>
  );
};
export default SignIn;
