import { FC, useEffect, useState } from 'react';

import '../../../index.css';
import './SelectList.css';

import {
  ISelectList,
  ISelectListOption,
} from '../../../interfaces/AtomsRelatedInterfaces';

const SelectList: FC<ISelectList> = ({
  additionalOnChange,
  propValue,
  disabled,
  isGrey,
  options,
  placeholder,
  iconClassName = 'select-arrow',
  width = '300px',
}) => {
  const [value, setValue] = useState(propValue || '');
  const handleChange = (e: { target: HTMLSelectElement }) => {
    additionalOnChange && additionalOnChange(e);
  };
  useEffect(() => {
    propValue && setValue(propValue);
  }, [propValue]);

  return (
    <div
      className={
        isGrey
          ? `select-div-2 select-div-grey-2 ${iconClassName}`
          : `select-div-2 ${iconClassName}`
      }
      style={{ width }}
    >
      <select
        value={value}
        disabled={disabled}
        onChange={handleChange}
        style={
          isGrey
            ? {
                backgroundColor: 'rgb(240, 240, 240)',
                color: '#2b2b2c',
                fontFamily: 'Helvetica Neue LT W05_55 Roman,sans-serif',
                fontSize: '16px',
                paddingLeft: '12px',
                cursor: 'pointer',
                display: 'inline-block',
                width: '100%',
                paddingRight: '50px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis',
              }
            : {
                fontSize: '14px',
                cursor: 'pointer',
                display: 'inline-block',
                width: '280px',
                paddingRight: '50px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis',
              }
        }
      >
        <option key={'empty'} value={''} disabled>
          {placeholder || ''}
        </option>

        {options?.map((o: ISelectListOption) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectList;
