import { gql } from '@apollo/client';

const SAVE_PROFILE_LANGUAGE = gql`
  mutation SaveProfileLanguage($input: SaveProfileLanguageInput!) {
    saveProfileLanguage(input: $input) {
      profile {
        language
      }
    }
  }
`;

export default SAVE_PROFILE_LANGUAGE;
