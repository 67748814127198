/* eslint-disable array-callback-return */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import plusRed from '../../../icons/plusRed.svg';
import './styles.css';
import Upgrade from './Upgrade';
import { UpgradesPropsProps } from './type';

const Upgrades: FC<UpgradesPropsProps> = ({
  upgrades,
  upgradesFromSettings,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {upgrades?.length > 0 ? (
        <div className='upgrades-container'>
          <Subheader
            isCentered
            icon={plusRed}
            text={t('commons.upgrades')}
            isBlack
          />
          {upgrades &&
            upgrades.map((upgrade, index) => {
              const upgradeDetails = upgradesFromSettings.find(
                (u) => u.id === upgrade.id
              );
              if (upgrade.totalPrice)
                return (
                  <Upgrade
                    id={upgrade.id}
                    amount={0}
                    totalPrice={upgrade.totalPrice}
                    summaryPrice={upgrade.summaryPrice}
                    key={upgrade.id + 'index' + index}
                    title={upgradeDetails?.title}
                    infoContent={upgradeDetails?.infoContent}
                  />
                );
            })}
        </div>
      ) : null}
    </>
  );
};
export default Upgrades;
