import { useContext } from 'react';
import { AppContext } from '../AppContext';

const useClearAddons = () => {
  const { chosenAddonsState } = useContext(AppContext);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [chosenAddons, setChosenAddons] = chosenAddonsState;

  const clear = () => {
    sessionStorage.setItem('chosenAddons', String([]));
    setChosenAddons([]);
  };
  return clear;
};
export default useClearAddons;
