import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import BookingCalendar from '../BookingCalendar/BookingCalendar';
import Spinner from '../../atoms/Spinner/Spinner';
import { ICalendarDayForPersonType } from '../../../interfaces/BookingRelatesInterfaces';
import { IBookingCalendarWithStatuses } from '../../../interfaces/BookingRelatesInterfaces';
import './styled.css';
import { toast } from 'react-toastify';
import useURLQuery from '../../../hooks/useURLQuery';
import useClearAddons from '../../../hooks/useClearAddons';
import useClearUpgrades from '../../../hooks/useClearUpgrades';
import rangeHaveEmptyDays from './rangeHaveEmptyDays';
import { dayIsAvailable, renderTileContent } from './functions';
import { dateFormat } from '../../../constans/formats';

const BookingCalendarWithStatuses: FC<IBookingCalendarWithStatuses> = ({
  maxConsecutiveDays,
  shopDraftResult,
  calendarData,
  loadingCalendarData,
}) => {
  const { ticketsStartDateState, ticketsEndDateState, chosenSubcategoryState } =
    useContext(AppContext);
  const { t } = useTranslation();
  const query = useURLQuery();
  const cleanAddons = useClearAddons();
  const cleanUpgrades = useClearUpgrades();
  const [chosenSubcategory] = chosenSubcategoryState;
  const [ticketsStartDate, setTicketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate, setTicketsEndDate] = ticketsEndDateState;
  const [showBookingCalendar, setShowBookingCalendar] = useState(false);

  const upSaleDates =
    shopDraftResult?.data?.shopLoadOfferDraftForPersonTypes?.upsaleDates || [];

  const firstAvailableDate = calendarData?.find(dayIsAvailable)?.date;

  const getDayFromCalendar = (date: string | null) =>
    calendarData?.find((day: ICalendarDayForPersonType) => day.date === date);

  const setStorageStartDate = (newStartDate: string) => {
    const newStartDateFixed = newStartDate
      ? moment(newStartDate).format(dateFormat)
      : '';
    sessionStorage.setItem('ticketsStartDate', String(newStartDateFixed));
    setTicketsStartDate(newStartDateFixed);
    setShowBookingCalendar(true);
  };

  const setStorageEndDate = (newEndDate: string) => {
    const newEndDateFixed = newEndDate
      ? moment(newEndDate).format(dateFormat)
      : '';
    sessionStorage.setItem('ticketsEndDate', String(newEndDateFixed));
    setTicketsEndDate(newEndDateFixed);
  };

  const setStorageDates = (startDate: string, endDate?: string) => {
    setStorageStartDate(startDate);
    setStorageEndDate(endDate || startDate);
  };

  const onChangeBookingCalendar = (date: Date): void => {
    const momentDate = moment(date);
    const dayDiffStartDate = Math.round(
      moment.duration(momentDate.diff(ticketsStartDate)).asDays()
    );
    const dayDiffEndDate = Math.round(
      moment.duration(momentDate.diff(ticketsEndDate)).asDays()
    );
    const tooManySelectedDays =
      Math.abs(dayDiffStartDate) >= maxConsecutiveDays ||
      Math.abs(dayDiffEndDate) >= maxConsecutiveDays;

    const calendarDay = getDayFromCalendar(momentDate.format(dateFormat));

    const dateIsInRange =
      momentDate.isSameOrAfter(ticketsStartDate) &&
      momentDate.isSameOrBefore(ticketsEndDate);
    const dateIsBeforeStartDate = momentDate.isBefore(ticketsStartDate);
    const newRangeHaveEmptyDays = rangeHaveEmptyDays(
      calendarData,
      dateIsBeforeStartDate ? date : ticketsStartDate,
      dateIsBeforeStartDate ? ticketsEndDate : date
    );

    if (calendarDay && dayIsAvailable(calendarDay)) {
      if (
        ticketsStartDate &&
        ticketsEndDate &&
        maxConsecutiveDays > 1 &&
        !tooManySelectedDays &&
        !newRangeHaveEmptyDays &&
        !dateIsInRange
      ) {
        dayDiffStartDate > 0
          ? setStorageEndDate(String(date))
          : setStorageStartDate(String(date));
      } else setStorageDates(String(date));

      cleanAddons();
      cleanUpgrades();
    }
  };

  //SET Start date and end date from Url query
  useEffect(() => {
    if (calendarData) {
      const startDateFromUrl = query.get('startDate');
      const endDateFromUrl = query.get('endDate');
      const endDateIsSameOrAfter =
        moment(endDateFromUrl).isSameOrAfter(startDateFromUrl);
      const dayDiff = moment
        .duration(moment(endDateFromUrl).diff(moment(startDateFromUrl)))
        .asDays();
      if (!ticketsEndDate && !ticketsStartDate) {
        if (startDateFromUrl && endDateFromUrl) {
          if (
            endDateIsSameOrAfter &&
            dayIsAvailable(getDayFromCalendar(startDateFromUrl)) &&
            dayIsAvailable(getDayFromCalendar(endDateFromUrl)) &&
            maxConsecutiveDays >= dayDiff
          )
            setStorageDates(startDateFromUrl, endDateFromUrl);
          else {
            toast(t('toasts.productNotAvailable'), { type: 'error' });
            if (firstAvailableDate) setStorageDates(firstAvailableDate);
            else setShowBookingCalendar(true);
          }
        } else if (firstAvailableDate) setStorageDates(firstAvailableDate);
        else setShowBookingCalendar(true);
      } else if (ticketsEndDate && ticketsStartDate) {
        if (rangeHaveEmptyDays(calendarData, ticketsStartDate, ticketsEndDate))
          setStorageDates(firstAvailableDate || '');
        else setShowBookingCalendar(true);
      } else setShowBookingCalendar(true);
    }
  }, [calendarData]);

  useEffect(() => {
    if (!loadingCalendarData && !calendarData) {
      setShowBookingCalendar(true);
    }
  }, [calendarData, loadingCalendarData]);
  return (
    <div
      style={{
        width: '315px',
      }}
    >
      {loadingCalendarData || !showBookingCalendar ? (
        <Spinner customDIVHeight={250} />
      ) : (
        <div className={chosenSubcategory ? 'active' : 'grayed'}>
          <BookingCalendar
            defaultActiveStartDate={
              ticketsStartDate ? new Date(ticketsStartDate) : undefined
            }
            onChange={onChangeBookingCalendar}
            value={
              ticketsStartDate
                ? ticketsEndDate && ticketsStartDate !== ticketsEndDate
                  ? [new Date(ticketsStartDate), new Date(ticketsEndDate)]
                  : new Date(ticketsStartDate)
                : undefined
            }
            renderTileContent={(props) =>
              renderTileContent({
                ...props,
                maxConsecutiveDays,
                ticketsStartDate,
                ticketsEndDate,
                upSaleDates,
                calendarForPersonTypes: calendarData,
              })
            }
          />
          <div className={'infoContainer'}>
            <div className='infoItem' style={{ background: '#d3eadd' }}>
              {t('buyTicketView.available')}
            </div>

            <div className='infoItem' style={{ background: '#fcf4db' }}>
              {t('buyTicketView.almostSoldOut')}
            </div>
            <div className='infoItem' style={{ background: '#fbdddd' }}>
              {t('buyTicketView.soldOut')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCalendarWithStatuses;
