import { ICalendarDayForPersonType } from '../../../interfaces/BookingRelatesInterfaces';
import moment from 'moment/moment';
import availabilityDateColors from '../../../constans/availabilityDateColors';

const rangeHaveEmptyDays = (
  calendarData: ICalendarDayForPersonType[],
  startDate: string | Date | undefined,
  endDate: string | Date | undefined
): boolean => {
  if (!calendarData || !startDate || !endDate) return true;
  else
    return (
      calendarData?.filter((day: ICalendarDayForPersonType) => {
        const dayInRange =
          moment(day.date).isSameOrAfter(startDate) &&
          moment(day.date).isSameOrBefore(endDate);

        return dayInRange && !availabilityDateColors.includes(day.availability);
      }).length > 0
    );
};

export default rangeHaveEmptyDays;
