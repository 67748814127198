import { PersonType } from '../interfaces/AtomsRelatedInterfaces';
import { useTranslation } from 'react-i18next';

const useTypeDescription = (type: PersonType, ageText?: string) => {
  const { t } = useTranslation();
  if (ageText) return `${ageText} ${t('buyTicketView.yearsOld')}`;
  else
    switch (type) {
      case 'adult':
        return t('buyTicketView.from18Years');
      case 'adult-v2':
        return t('buyTicketView.from18Years');
      case 'entry':
        return '';
      case 'teen':
        return `13-17 ${t('buyTicketView.yearsOld')}`;
      case 'teen-child':
        return `6-17 ${t('buyTicketView.yearsOld')}`;
      case 'child':
        return `6-12 ${t('buyTicketView.yearsOld')}`;
      case 'senior':
        return t('buyTicketView.seniorAge');
      case 'senior-v2':
        return '64 plus';
      default:
        return '';
    }
};
export default useTypeDescription;
