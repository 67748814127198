import { FC, useContext, useEffect, useState } from 'react';

import './UserPanel.css';

import UserPanelStatusBar from '../../molecules/UserPanelStatusBar/UserPanelStatusBar';
import BookedTicketsComponent from '../../atoms/BookedTickets/BookedTickets';
import YourProfile from '../../molecules/YourProfile/YourProfile';
import GroupMembers from '../../atoms/GroupMembers/GroupMembers';
import ToggleSideMenu from '../../molecules/ToggleSideMenu/ToggleSideMenu';
import { AppContext } from '../../../AppContext';
import { Order } from '../../atoms/BookedTickets/type';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER } from '../../../graphql/user/purchases';

const UserPanel: FC = () => {
  const { menuHiddenState } = useContext(AppContext);
  const [isMenuHidden, setIsMenuHidden] = menuHiddenState;
  const [orders, setOrders] = useState<Order[]>([]);

  const userPurchases = useQuery(FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const purchaseData =
      userPurchases?.data?.shopLoadPurchases.filter(
        (o: any) => o?.orderNumber
      ) ?? [];
    purchaseData?.length && setOrders(purchaseData);
  }, [userPurchases]);

  useEffect(() => {
    const opositeSessionStorageMenuState = !isMenuHidden;
    setIsMenuHidden(opositeSessionStorageMenuState);
    sessionStorage.setItem(
      'isMenuHidden',
      String(opositeSessionStorageMenuState)
    );
  }, []);

  //const [changeAvatarModal, setChangeAvatarModal] = useState<boolean>(false);
  //const avatar = useQuery(FETCH_GUEST_AVATAR)?.data ?? {
  //  guest: { profile: { avatar: null } },
  //};
  return (
    <div className='userPanelContainer'>
      <div className='userPanelLeftSection'>
        <div className='userPanelMenu'>
          <ToggleSideMenu />
        </div>
        {/* Hidden for now regarding Client's instruction
        <TileContainer>
          <img
            src={avatar.guest.profile.avatar || exampleAvatar}
            alt='avatar'
            className='avatar'
            onClick={() => setChangeAvatarModal(!changeAvatarModal)}
          />
        </TileContainer> */}
      </div>
      <div className='userPanelRightSection'>
        <div className='userPanelTopRight'>
          <UserPanelStatusBar />
        </div>
        <div className='userPanelBottomRight'>
          <div className='groupMembers userPanelBox'>
            <BookedTicketsComponent orders={orders} />
          </div>
          <div className='yourProfile userPanelBox'>
            <YourProfile />
          </div>
          <div className='groupMembers userPanelBox'>
            <GroupMembers />
          </div>
        </div>
        {/* Hidden for now regarding Client's instruction
        {changeAvatarModal && (
          <ChangeAvatarModal
            setChangeAvatarModal={setChangeAvatarModal}
            refresh={() => {}}
          />
        )} */}
      </div>
    </div>
  );
};
export default UserPanel;
