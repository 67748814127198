import { FC, useState, createContext } from 'react';
import { IAppProvider } from './interfaces/AtomsRelatedInterfaces';

declare global {
  interface Window {
    config: { newKeycardCost: number };
  }
}
// prettier-ignore
const AppContext = createContext<
  (any & { config: { newKeycardCost: number } }) | undefined
>(undefined);

const AppProvider: FC<IAppProvider> = ({ children }): JSX.Element => {
  const [availableProducts, setAvailableProducts] = useState(
    Array.from(
      sessionStorage
        ?.getItem('availableProducts')
        ?.replace('[', '')
        ?.replace(']', '')
        ?.split(',') ?? []
    )
  );
  const [lovedProducts, setLovedProducts] = useState(
    Array.from(
      sessionStorage
        ?.getItem('lovedProducts')
        ?.replace('[', '')
        ?.replace(']', '')
        ?.split(',') ?? []
    )
  );

  const [isCookiesPanelVisible, setIsCookiesPanelVisible] = useState(
    localStorage.getItem('isCookiesPanelVisible')
      ? Boolean(sessionStorage.getItem('isCookiesPanelVisible') === 'true')
      : true
  );

  const [showSubTotalCostView, setShowSubTotalCostView] = useState(
    sessionStorage.getItem('showSubTotalCostView')
      ? Boolean(sessionStorage.getItem('showSubTotalCostView') === 'true')
      : false
  );

  const [mainCategories, setMainCategories] = useState(
    Array.from(
      sessionStorage
        ?.getItem('mainCategories')
        ?.replace('[', '')
        ?.replace(']', '')
        ?.split(',') ?? []
    )
  );

  const [chosenPersonTypes, setChosenPersonTypes] = useState(
    sessionStorage?.getItem('chosenPersonTypes') === ''
      ? []
      : Array.from(
          sessionStorage
            ?.getItem('chosenPersonTypes')
            ?.replace('"[', '')
            ?.replace(']"', '')
            ?.split(',') ?? []
        )
  );
  const [chosenAddons, setChosenAddons] = useState(
    sessionStorage?.getItem('chosenAddons') === ''
      ? []
      : Array.from(
          sessionStorage
            ?.getItem('chosenAddons')
            ?.replace('"[', '')
            ?.replace(']"', '')
            ?.split(',') ?? []
        )
  );
  const [chosenUpgrades, setChosenUpgrades] = useState(
    sessionStorage?.getItem('chosenUpgrades') === ''
      ? []
      : Array.from(
          sessionStorage
            ?.getItem('chosenUpgrades')
            ?.replace('"[', '')
            ?.replace(']"', '')
            ?.split(',') ?? []
        )
  );
  const [chosenGroupCategory, setChosenGroupCategory] = useState(
    sessionStorage?.getItem('chosenGroupCategory')
  );
  const [chosenPersonFields, setChosenPersonFields] = useState(
    Array.from(
      sessionStorage
        ?.getItem('chosenPersonFields')
        ?.replace('"[', '')
        ?.replace(']"', '')
        ?.split(',') ?? []
    )
  );
  const [chosenPartyMembersOnTicket, setChosenPartyMembersOnTicket] = useState(
    sessionStorage?.getItem('chosenPartyMembersOnTicket') === ''
      ? []
      : Array.from(
          sessionStorage
            ?.getItem('chosenPartyMembersOnTicket')
            ?.replace('[', '')
            ?.replace(']', '')
            ?.split(',') ?? []
        )
  );
  const [chosenMainCategory, setChosenMainCategory] = useState(
    sessionStorage.getItem('chosenMainCategory')
  );
  const [chosenSubcategory, setChosenSubcategory] = useState(
    sessionStorage.getItem('chosenSubcategory')
  );

  const [isMenuHidden, setIsMenuHidden] = useState(false);

  const [isSuccessPageView, setIsSuccessPageView] = useState(
    Boolean(sessionStorage.getItem('isSuccessPageView') === 'true')
  );

  const [isBuyTicketView, setIsBuyTicketView] = useState(
    Boolean(sessionStorage.getItem('isBuyTicketView') === 'true')
  );

  const [ticketsEndDate, setTicketsEndDate] = useState(
    sessionStorage.getItem('ticketsEndDate') || ''
  );
  const [ticketsStartDate, setTicketsStartDate] = useState(
    sessionStorage.getItem('ticketsStartDate') || ''
  );

  const [discountTokenId, setDiscountTokenId] = useState(
    sessionStorage.getItem('discountTokenId') || ''
  );

  const [purchase, setPurchase] = useState(
    sessionStorage.getItem('purchase') || ''
  );

  return (
    <AppContext.Provider
      value={{
        availableProductsState: [availableProducts, setAvailableProducts],
        lovedProductsState: [lovedProducts, setLovedProducts],
        chosenMainCategoryState: [chosenMainCategory, setChosenMainCategory],
        chosenPartyMembersOnTicketState: [
          chosenPartyMembersOnTicket,
          setChosenPartyMembersOnTicket,
        ],
        chosenPersonTypesState: [chosenPersonTypes, setChosenPersonTypes],
        chosenPersonFieldsState: [chosenPersonFields, setChosenPersonFields],
        chosenSubcategoryState: [chosenSubcategory, setChosenSubcategory],
        chosenAddonsState: [chosenAddons, setChosenAddons],
        chosenUpgradesState: [chosenUpgrades, setChosenUpgrades],
        chosenGroupCategoryState: [chosenGroupCategory, setChosenGroupCategory],
        cookiesPanelState: [isCookiesPanelVisible, setIsCookiesPanelVisible],
        showSubTotalCostViewState: [
          showSubTotalCostView,
          setShowSubTotalCostView,
        ],
        menuHiddenState: [isMenuHidden, setIsMenuHidden],
        successPageState: [isSuccessPageView, setIsSuccessPageView],
        ticketsEndDateState: [ticketsEndDate, setTicketsEndDate],
        ticketsStartDateState: [ticketsStartDate, setTicketsStartDate],
        buyTicketViewState: [isBuyTicketView, setIsBuyTicketView],
        mainCategoriesState: [mainCategories, setMainCategories],
        discountTokenIdState: [discountTokenId, setDiscountTokenId],
        purchaseState: [purchase, setPurchase],
        config: window.config,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
