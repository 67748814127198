import { FC, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Subheader from '../../atoms/Subheader/Subheader';
import './TicketsSummary.css';
import CloseRed from '../../../icons/closeRed.svg';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import { UpgradeType } from '../../molecules/Upgrades/type';

interface UpgradesProps {
  upgrades: UpgradeType[];
  upgradesFromSettings: {
    id: string;
    title: string;
  }[];
}
const Upgrades: FC<UpgradesProps> = ({ upgrades, upgradesFromSettings }) => {
  const { chosenUpgradesState } = useContext(AppContext);
  const [chosenUpgrades, setChosenUpgrades] = chosenUpgradesState;

  const uniqueChosenUpgrades = chosenUpgrades?.filter(
    (cA: string, pos: number) => chosenUpgrades.indexOf(cA) == pos
  );

  const deleteUpgrade = (upgrade: string) => {
    const newChosenUpgrades = chosenUpgrades?.filter(
      (u: string) => u !== upgrade
    );
    setChosenUpgrades(newChosenUpgrades);
    sessionStorage.setItem('chosenUpgrades', String(newChosenUpgrades));
  };

  return (
    <div className={'customerTickets'}>
      {uniqueChosenUpgrades.map((cU: string, index: number) => (
        <div className={'itemContainer'} key={'addon' + index}>
          <div className={'itemSubHeader'}>
            <Subheader
              customPaddingBottom={0}
              customMarginBottom={1}
              isBlack
              text={
                chosenUpgrades?.filter((c: string) => c === cU).length +
                ' x ' +
                upgradesFromSettings.find((u) => u.id === cU)?.title
              }
            />
          </div>
          <div className='itemPrice'>
            CHF{' '}
            {formatPriceForDisplay(
              chosenUpgrades?.filter((c: string) => c === cU).length *
                upgrades?.filter((a: any) => a.id === cU)[0].totalPrice ?? 0
            )}
          </div>
          <img
            alt='close-btn'
            src={CloseRed}
            className={'closeButton pointer'}
            onClick={() => deleteUpgrade(cU)}
          />
        </div>
      ))}
    </div>
  );
};

export default Upgrades;
