import { useQuery } from '@apollo/client';
import { FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER } from '../../../graphql/user/purchases';
import sendAnalytics from '../../../utils/sendAnalytics';
import { Order } from '../../atoms/BookedTickets/type';
import { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import priceDivided from '../../../utils/priceDivided';
import useClearProduct from '../../../hooks/useClearProduct';

interface Props {
  setSuccessAnalyticsSend: (value: boolean) => void;
  tokenId: string | undefined;
}
const OrderCompletedAnalytics: FC<Props> = ({
  setSuccessAnalyticsSend,
  tokenId,
}) => {
  const { buyTicketViewState, successPageState, availableProductsState } =
    useContext(AppContext);
  const [availableProducts] = availableProductsState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [, setIsSuccessPageView] = successPageState;
  const userPurchases = useQuery(FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER, {
    fetchPolicy: 'network-only',
  });
  const clearProduct = useClearProduct();
  const sendAnalyticsAsync = async () => {
    const purchaseData =
      userPurchases?.data?.shopLoadPurchases.filter(
        (o: any) => o?.orderNumber
      ) ?? [];
    const lastOrder = purchaseData[0] as Order;

    if (lastOrder) {
      const tokens = lastOrder.price.tokens[0]
        ? [
            {
              item_id: tokenId,
              item_name: lastOrder.price.tokens?.[0]?.name,
              item_category: 'Token',
              price: priceDivided(lastOrder.price.discount / -1),
              quantity: 1,
              affiliation: 'tickets.laax.com',
              coupon: lastOrder.price.tokens?.[0]?.name,
            },
          ]
        : [];
      const feesArray = lastOrder.orderItems.map((o) => o.fees || 0);
      const feesQuantity = feesArray.filter((f) => f > 0).length;
      const fees =
        feesQuantity > 0
          ? [
              {
                item_id: 'keycard-fee',
                item_name: 'New Keycard Fee',
                price: priceDivided(feesArray.reduce((a, b) => a + b, 0)),
                quantity: feesQuantity,
              },
            ]
          : [];
      sendAnalytics('purchase', {
        ecommerce: {
          transaction_id: lastOrder.orderNumber,
          value: priceDivided(lastOrder.price.total),
          currency: 'CHF',
          items: [
            ...lastOrder.orderItems.map((item) => ({
              affiliation: 'tickets.laax.com',
              item_id: item.productId,
              item_name: item.productName,
              item_category:
                availableProducts.find(
                  (p: { id: string }) => p.id === item.productId
                )?.categoryId || '',
              item_variant: item?.personTypeTitle || '',
              price: priceDivided(item.price),
              quantity: item.quantity,
            })),
            ...tokens,
            ...fees,
          ],
        },
      });
    }
  };
  useEffect(() => {
    if (userPurchases.loading === false)
      sendAnalyticsAsync().then(() => {
        setIsSuccessPageView(true);
        clearProduct();
        setIsBuyTicketView(false);
        sessionStorage.setItem('isSuccessPageView', 'true');
        sessionStorage.removeItem('isBuyTicketView');
        setSuccessAnalyticsSend(true);
      });
  }, [userPurchases]);

  return null;
};
export default OrderCompletedAnalytics;
