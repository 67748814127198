export const useGetDataFromProductSettings = (productSettings: any) => {
  const maxConsecutiveDays =
    productSettings?.shopLoadKeycardTicketProduct?.availability
      ?.maxConsecutiveDays || 1;

  const upgradesFromSettings =
    productSettings?.shopLoadKeycardTicketProduct?.upgrades || [];
  const addonsFromSettings =
    productSettings?.shopLoadKeycardTicketProduct?.addons || [];
  const productTitle =
    productSettings?.shopLoadKeycardTicketProduct?.title || '';
  const productDescription =
    productSettings?.shopLoadKeycardTicketProduct?.description ?? '';

  return {
    maxConsecutiveDays,
    upgradesFromSettings,
    addonsFromSettings,
    productTitle,
    productDescription,
  };
};
