import { FC } from 'react';
import Modal from '../../../atoms/Modal';
import MainOptionButton from '../../../atoms/buttons/MainOptionButton/MainOptionButton';
import Subheader from '../../../atoms/Subheader/Subheader';
import ReactMarkdown from 'react-markdown';

interface Props {
  isOpen: boolean;
  close: () => void;
  infoContent?: string;
  title?: string;
}
const Details: FC<Props> = ({ isOpen, close, title, infoContent }) => {
  return (
    <Modal isOpen={isOpen}>
      <div className={'detailsContainer'}>
        <div className='exitButtonDetails'>
          <MainOptionButton onClick={close}>x</MainOptionButton>
        </div>
        <Subheader text={title || ''} isBlack />
        <ReactMarkdown>{infoContent || ''}</ReactMarkdown>
      </div>
    </Modal>
  );
};

export default Details;
