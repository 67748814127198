import { FC, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import './ToggleMenuOption.css';
import { IToggleMenuOption } from '../../../../interfaces/ToggleMenuRelatedInterfaces';
import arrowDown from '../../../../icons/arrow-down-black.svg';
import arrowRight from '../../../../icons/arrow-right-black.svg';
import useClearSelectedData from '../../../../hooks/useClearSelectedData';
import checkIfIsMobileView from '../../../../utils/checkIfIsMobileView';
import { CategoryIcon, Header } from './styled';
import {
  ProductGroupType,
  ShopLandingProduct,
} from '../../../../interfaces/ShopRelatedInterfaces';
import getCategoryOptions, {
  SubCategoryType,
} from '../../../../utils/getCategoryOptions';

const ToggleMenuOption: FC<IToggleMenuOption> = ({ mainCategory }) => {
  const history = useHistory();
  const langEn = Boolean(localStorage.getItem('lang') === 'en');
  const clearSelectedData = useClearSelectedData();
  const {
    buyTicketViewState,
    chosenMainCategoryState,
    chosenSubcategoryState,
    menuHiddenState,
    chosenGroupCategoryState,
    availableProductsState,
  } = useContext(AppContext);
  const [chosenMainCategory, setChosenMainCategory] = chosenMainCategoryState;
  const [chosenSubcategory, setChosenSubcategory] = chosenSubcategoryState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [, setIsMenuHidden] = menuHiddenState;
  const [chosenGroupCategory, setChosenGroupCategory] =
    chosenGroupCategoryState;
  const [availableProducts] = availableProductsState;
  const subOptionsMenu = getCategoryOptions(
    availableProducts,
    mainCategory?.id
  );

  const mainCategoryId = mainCategory.id;
  const [areOptionsVisible, setOptionsVisible] = useState(false);
  const image = mainCategory.image?.asset?._ref || '';
  const imgSrc = image
    ? `${image.split('-')?.slice(1, 3).join('-') + '.svg'}`
    : '';

  const goToBuyTicket = useCallback(() => {
    const isMobileView = checkIfIsMobileView();
    setIsBuyTicketView(true);
    sessionStorage.setItem('isBuyTicketView', 'true');
    !isMobileView ? history.push('/') : history.push('/buy-tickets');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, setIsBuyTicketView]);

  const handleOnClick = (subcategory: any) => {
    goToBuyTicket();
    setIsMenuHidden(true);
    if (subcategory && subcategory.id !== chosenSubcategory) {
      clearSelectedData();
      setChosenMainCategory(subcategory.categoryId);
      setChosenSubcategory(subcategory.id);
      setChosenGroupCategory('');
      sessionStorage.setItem(
        'chosenMainCategory',
        String(subcategory.categoryId)
      );
      sessionStorage.setItem('chosenSubcategory', String(subcategory.id));
      sessionStorage.setItem('chosenGroupCategory', '');
    }
  };

  const handleOnGroupTicketClick = (subcategoryGroup: ProductGroupType) => {
    goToBuyTicket();
    setIsMenuHidden(true);
    const subcategoryGroupId = subcategoryGroup?.['ticket-group']?.id;
    const mainCategoryGroupId = subcategoryGroup?.['navigation-group']?.id;
    const isOtherOption =
      (subcategoryGroupId && subcategoryGroupId !== chosenGroupCategory) ||
      (mainCategoryGroupId && mainCategoryGroupId !== chosenMainCategory);
    if (isOtherOption) {
      clearSelectedData();
      setChosenMainCategory(mainCategoryGroupId);
      setChosenSubcategory('');
      setChosenGroupCategory(subcategoryGroupId);
      sessionStorage.setItem('chosenSubcategory', '');
      sessionStorage.setItem('chosenGroupCategory', String(subcategoryGroupId));
      sessionStorage.setItem('chosenMainCategory', String(mainCategoryGroupId));
    }
  };
  const mainCategorySelected = chosenMainCategory === mainCategoryId;

  const handleToggleMenuOptionClick = () => {
    setOptionsVisible(!areOptionsVisible);
    if (subOptionsMenu?.length === 1) {
      setIsMenuHidden(true);
      const id = (subOptionsMenu?.[0] as ShopLandingProduct)?.id;
      const groupId = (subOptionsMenu?.[0] as ProductGroupType)?.[
        'ticket-group'
      ]?.['id'];
      setChosenMainCategory(mainCategoryId);
      sessionStorage.setItem('chosenMainCategory', String(mainCategoryId));
      setChosenSubcategory(id || '');
      sessionStorage.setItem('chosenSubcategory', String(id || ''));
      setChosenGroupCategory(groupId || '');
      sessionStorage.setItem('chosenGroupCategory', String(groupId || ''));
      clearSelectedData();
      goToBuyTicket();
    }
    if (subOptionsMenu.length === 0) {
      setIsMenuHidden(true);
    }
  };
  if (subOptionsMenu?.length > 0)
    return (
      <div className='toggleMenuOptionContainer'>
        {mainCategorySelected && (
          <div className={'toggle-menu-option-border'} />
        )}
        <div
          onClick={handleToggleMenuOptionClick}
          style={{
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              minHeight: '84px',
            }}
          >
            <Header>
              <div>
                <CategoryIcon
                  imagePath={imgSrc}
                  color={mainCategorySelected ? '#b30019' : '#b7b7b7'}
                />
              </div>
              <h3 className='font-grey'>{mainCategory.title}</h3>
            </Header>
            <span
              style={{
                opacity: '0.5',
              }}
            >
              {subOptionsMenu?.length > 1 ? (
                areOptionsVisible ? (
                  <img
                    alt='arrow-down'
                    className='toggle-menu-arrow-icon element-on-hover'
                    src={arrowDown}
                  />
                ) : (
                  <img
                    alt='arrow-right'
                    className='toggle-menu-arrow-icon element-on-hover'
                    src={arrowRight}
                  />
                )
              ) : null}
            </span>
          </div>
        </div>
        {areOptionsVisible ? (
          <div className='toggle-menu-options'>
            {subOptionsMenu?.map((o: SubCategoryType | any, index) => {
              const additionalStylesForSelected = {
                color: '#B30019',
                fontWeight: 800,
              };
              if (o?.['ticket-group']) {
                const isSelected =
                  o?.['ticket-group']?.id === chosenGroupCategory &&
                  o?.['navigation-group']?.id === chosenMainCategory;
                return (
                  <span
                    className='toggle-menu-option'
                    key={o.id + 'index' + index}
                    onClick={() => handleOnGroupTicketClick(o)}
                    style={isSelected ? additionalStylesForSelected : undefined}
                  >
                    {o?.['ticket-group']?.[langEn ? 'name-en' : 'name-de']}
                  </span>
                );
              } else {
                const isSelected =
                  o?.id === chosenSubcategory &&
                  o?.categoryId === chosenMainCategory;

                return (
                  <span
                    className='toggle-menu-option'
                    key={o.id + 'index' + index}
                    onClick={() => handleOnClick(o)}
                    style={isSelected ? additionalStylesForSelected : undefined}
                  >
                    {o.title}
                  </span>
                );
              }
            })}
          </div>
        ) : null}
      </div>
    );
  else return null;
};

export default ToggleMenuOption;
