import { useLazyQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import ErrorToast from '../../atoms/ErrorToast/ErrorToast';
import Spinner from '../../atoms/Spinner/Spinner';
import Subheader from '../../atoms/Subheader/Subheader';
import { FETCH_PRODUCT_TITLE_BY_PRODUCT_ID } from '../../../graphql/shop/shopLoadKeycardTicketProduct';
import './TicketsSummary.css';
import { ProductTitleProps } from './type';

const ProductTitle: FC<ProductTitleProps> = ({
  chosenSubcategory,
  basePricePerPersonType,
  filteredPersonTypes,
}) => {
  const [
    fetchedProductTitleByProductID,
    {
      data: shopProductTitleData,
      error: fetchErrorProductID,
      loading: isProductTitleDataLoading,
    },
  ] =
    useLazyQuery(FETCH_PRODUCT_TITLE_BY_PRODUCT_ID, {
      variables: { productId: chosenSubcategory },
    }) ?? {};

  const [productTitle, setProductTitle] = useState(
    chosenSubcategory && filteredPersonTypes
      ? shopProductTitleData?.shopLoadKeycardTicketProduct?.title ?? ''
      : ''
  );

  const statusCodeProductID =
    fetchErrorProductID?.message.indexOf('400') !== -1 ? 400 : undefined;

  useEffect(() => {
    chosenSubcategory && fetchedProductTitleByProductID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenSubcategory]);

  useEffect(() => {
    if (chosenSubcategory) {
      const newTitle =
        shopProductTitleData?.shopLoadKeycardTicketProduct?.title ?? '';
      setProductTitle(newTitle);
      sessionStorage.setItem('productTitle', newTitle);
    }
  }, [
    chosenSubcategory,
    shopProductTitleData?.shopLoadKeycardTicketProduct?.title,
  ]);

  return (
    <>
      {isProductTitleDataLoading ? (
        <Spinner />
      ) : (
        <>
          {fetchErrorProductID && (
            <ErrorToast statusCode={statusCodeProductID} />
          )}
          <Subheader
            customPaddingBottom={0}
            customMarginBottom={0}
            isBlack
            text={
              basePricePerPersonType?.length
                ? `${filteredPersonTypes?.length} x ${productTitle ?? ''}`
                : ''
            }
          />
        </>
      )}
    </>
  );
};

export default ProductTitle;
