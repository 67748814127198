import { useContext } from 'react';
import { AppContext } from '../AppContext';

const useIsAnyPersonIsChosenOnTicket = (): boolean => {
  const { chosenPersonTypesState } = useContext(AppContext);

  const [chosenPersonTypes] = chosenPersonTypesState;

  return chosenPersonTypes.length > 0 && chosenPersonTypes[0] !== '';
};
export default useIsAnyPersonIsChosenOnTicket;
