import { FC } from 'react';

import { IIcon } from '../../../interfaces/AtomsRelatedInterfaces';
import './Icon.css';

const Icon: FC<IIcon> = ({ alt, src }) => {
  return <img alt={alt} className='icon' src={src} />;
};

export default Icon;
