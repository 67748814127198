import QRCode from 'qrcode.react';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SmallText from '../SmallText/SmallText';
import './styles.css';
import ticketIconRed from '../../../icons/ticket-icon-red.svg';
import { useState } from 'react';
import MainOptionButton from '../buttons/MainOptionButton/MainOptionButton';
import { TicketMoreDetailsProps } from './types';
import Button from '../buttons/SubmitButton/SubmitButton';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
// @ts-ignore
import Pdf from 'react-to-pdf';
import FlimsLaaxLogo from '../FlimsLaaxLogo/FlimsLaaxLogo';
import flimsLaaxLogo from '../../../assets/flimsLaaxLogo.png';
import { PersonTypeTitle } from '../../../mockups/personTypeTitle';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import moment from 'moment';
import arrowLeft from '../../../icons/arrow-left-black.svg';
import arrowRight from '../../../icons/arrow-right-black.svg';
import { FaFilePdf, FaRegEnvelope } from 'react-icons/fa';

const TicketMoreDetails: FC<TicketMoreDetailsProps> = ({
  dateRange,
  ticketCode,
  closeModal,
  quantity,
  productName,
  personTypeTitle,
  mediaId,
  price,
  qrCode,
  isLastTicket,
  isFirstTicket,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const [pdfView, setPdfView] = useState(false);
  const totalWithCurrency = `CHF ${formatPriceForDisplay(price)}`;
  const quantityText = `${quantity} x ${t(PersonTypeTitle(personTypeTitle))}`;
  const ticketDate = moment(dateRange?.start).format('DD.MM.YYYY');
  const title = `${productName || t('user.yourTickets')}${
    ticketCode ? ' (' + ticketCode + ')' : null
  }`;

  // const generatePassWallet = () =>
  //   generatePass({
  //     qrCode,
  //     title: `${productName || t('user.yourTickets')}`,
  //     startDate: ticketDate,
  //     description: `${totalWithCurrency} ${quantityText}`,
  //     walletID: ticketCode || '',
  //   });

  return (
    <div
      className='ticketMoreDetailsContainer'
      style={{ height: 620, overflowY: 'auto' }}
    >
      <div style={{ padding: 20 }}>
        <div className='moreDetailsExit'>
          <MainOptionButton onClick={() => closeModal && closeModal()}>
            x
          </MainOptionButton>
        </div>
        <div
          ref={ref}
          style={{
            width: pdfView ? '816px' : '100%',
            alignItems: 'center',
            display: pdfView ? 'flex' : 'block',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img
              alt={'ticket'}
              className='subheader-icon'
              style={{ marginTop: 6 }}
              src={ticketIconRed}
            />
            <h3>{title}</h3>
          </div>
          <div>
            <SmallText text={totalWithCurrency} />
          </div>
          <SmallText isNoUppercase text={ticketDate} />
          <div>
            <SmallText isNoUppercase text={quantityText} />
            {mediaId && (
              <div>
                <SmallText
                  isNoUppercase
                  text={`${t('commons.keycard')} ${mediaId}`}
                />
              </div>
            )}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '34px 0',
            }}
          >
            {!pdfView && (
              <div style={{ width: '20px' }}>
                {!isFirstTicket && (
                  <ButtonBack className={'clean-navigation-button'}>
                    <img
                      src={arrowLeft}
                      style={{ width: '20px' }}
                      alt={'back'}
                    />
                  </ButtonBack>
                )}
              </div>
            )}
            <div
              style={{
                width: pdfView ? '100%' : 'calc(100% - 40px)',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {qrCode && (
                <QRCode
                  id={qrCode}
                  value={qrCode}
                  size={220}
                  level={'H'}
                  includeMargin={false}
                />
              )}
            </div>
            {!pdfView && (
              <div style={{ width: '20px' }}>
                {!isLastTicket && (
                  <ButtonNext className={'clean-navigation-button'}>
                    <img
                      src={arrowRight}
                      style={{ width: '20px' }}
                      alt={'next'}
                    />
                  </ButtonNext>
                )}
              </div>
            )}
          </div>
          {pdfView && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '275px',
              }}
            >
              <div
                style={{
                  width: '100px',
                }}
              >
                <FlimsLaaxLogo alt='Flims Laax Logo' src={flimsLaaxLogo} />
              </div>
            </div>
          )}
        </div>
        {qrCode && (
          <div>
            <Pdf targetRef={ref} filename='ticket.pdf'>
              {({ toPdf }: any) => (
                <Button
                  className='submit-button moreDetailsButtons'
                  onClick={async () => {
                    setPdfView(true);
                    await toPdf();
                    setPdfView(false);
                  }}
                >
                  <div className={'ticketButtonContainer'}>
                    <div style={{ padding: '0 14px' }}>
                      <FaRegEnvelope size={20} />
                    </div>
                    {t('commons.preEmail')}
                  </div>
                </Button>
              )}
            </Pdf>
            <Pdf targetRef={ref} filename='ticket.pdf'>
              {({ toPdf }: any) => (
                <Button
                  className='submit-button moreDetailsButtons'
                  onClick={async () => {
                    setPdfView(true);
                    await toPdf();
                    setPdfView(false);
                  }}
                >
                  <div className={'ticketButtonContainer'}>
                    <div style={{ padding: '0 14px' }}>
                      <FaFilePdf size={20} />
                    </div>
                    {t('commons.printPdf')}
                  </div>
                </Button>
              )}
            </Pdf>
            {/*<Button*/}
            {/*  className='submit-button moreDetailsButtons'*/}
            {/*  onClick={generatePassWallet}*/}
            {/*>*/}
            {/*  <div className={'ticketButtonContainer'}>*/}
            {/*    <div style={{ padding: '0 14px' }}>*/}
            {/*      <FaWallet size={20} />*/}
            {/*    </div>*/}
            {/*    {t('commons.addToWallet')}*/}
            {/*  </div>*/}
            {/*</Button>*/}
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketMoreDetails;
