import Modal from '../../atoms/Modal';
import React, { FC, useContext, useState } from 'react';
import BookingCalendarWithStatuses from '../../molecules/BookingCalendarWithStatuses/BookingCalendarWithStatuses';
import { AppContext } from '../../../AppContext';
import { ICalendarDayForPersonType } from '../../../interfaces/BookingRelatesInterfaces';
import { useTranslation } from 'react-i18next';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import calendarIcon from '../../../icons/calendar-date-white.svg';
import { useDatesAndDaysText } from '../../organisms/TicketsSummary/utils';

export type SelectDateModalProps = {
  calendarDates: ICalendarDayForPersonType[];
  maxConsecutiveDays: number;
  isMobile: boolean;
  offerForPersonType: any;
  disabled: boolean;
};
const SelectDateModal: FC<SelectDateModalProps> = ({
  calendarDates,
  maxConsecutiveDays,
  offerForPersonType,
  disabled,
  isMobile,
}) => {
  const { t } = useTranslation();
  const { ticketsStartDateState, ticketsEndDateState } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;
  const datesAndDaysText = useDatesAndDaysText(
    ticketsStartDate,
    ticketsEndDate
  );

  return (
    <>
      <div
        className={'input-date'}
        style={{ width: isMobile ? '49%' : '100%' }}
        onClick={() => {
          !disabled && setOpen(true);
        }}
      >
        <div style={{ paddingLeft: 10, fontSize: 14 }}>
          {ticketsStartDate && ticketsEndDate
            ? datesAndDaysText
            : t('commons.chooseDate')}
        </div>
        <div style={{ padding: '8px 10px', backgroundColor: 'black' }}>
          <img src={calendarIcon} />
        </div>
      </div>
      <Modal isOpen={open} height={'fit-content'}>
        <div className={'select-date-modal'}>
          <div className='exitButton'>
            <MainOptionButton onClick={(): void => setOpen(false)}>
              x
            </MainOptionButton>
          </div>
          <BookingCalendarWithStatuses
            maxConsecutiveDays={maxConsecutiveDays}
            shopDraftResult={offerForPersonType}
            calendarData={calendarDates}
            loadingCalendarData={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectDateModal;
