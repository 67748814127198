import { useContext } from 'react';
import { AppContext } from '../AppContext';

type IViewsToClear = ('buyView' | 'successView')[];

export const useClearRestOfMobileViews = (viewsToClear: IViewsToClear) => {
  const { buyTicketViewState, successPageState } = useContext(AppContext);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isBuyTicketView, setIsBuyTicketView] = buyTicketViewState;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isSuccessPageView, setIsSuccessPageView] = successPageState;

  const clear = () => {
    if (viewsToClear?.includes('successView')) {
      setIsSuccessPageView(false);
      sessionStorage.removeItem('isSuccessPageView');
    }
    if (viewsToClear?.includes('buyView')) {
      setIsBuyTicketView(false);
      sessionStorage.removeItem('isBuyTicketView');
    }
  };
  return clear;
};
