import { useField } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleSelectField from '../../SelectField';
import '../../../../index.css';
import './styled.css';
import SelectContainerProps from './type';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import CloseRed from '../../../../icons/closeRed.svg';
import SmallText from '../../../atoms/SmallText/SmallText';

const SelectContainer: FC<SelectContainerProps> = ({
  options = [],
  productPrice,
  onAddPersonClick,
  partyMembersTypeAdded,
  partyMembersAdded,
  setPartyMembersAdded,
  fieldName,
  personFieldDelete,
  getUserKeyCardPrice,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(fieldName);
  const { setValue }: any = helper;

  useEffect(() => {
    if (
      partyMembersAdded &&
      partyMembersTypeAdded === fieldName &&
      options.length
    ) {
      setValue(options[options.length - 1].value);
      setPartyMembersAdded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyMembersAdded]);

  const onDeleteClick = async () => {
    await personFieldDelete(fieldName, field.value);
    setValue(undefined);
  };
  const selectedUserName = options.find((o) => o.value === field.value)
    ?.label as string;
  const keyCardPrice =
    selectedUserName && getUserKeyCardPrice(selectedUserName);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingBottom: '10px',
      }}
    >
      <div className='select'>
        <button
          className='transparent-btn'
          style={{ padding: '0' }}
          type='submit'
        >
          <SimpleSelectField
            isMarkRed
            label={''}
            placeholder={' '}
            name={fieldName}
            options={options}
          />
        </button>
      </div>
      <div className={'selectRightContainer'}>
        <div
          className='addPersonText'
          onClick={() => onAddPersonClick(fieldName, field.value)}
          style={{ width: localStorage.getItem('lang') === 'de' ? 130 : 90 }}
        >
          {t('checkoutView.addPerson')}
        </div>
        <div className='checkoutTicketPrice'>
          CHF {formatPriceForDisplay(productPrice ?? 0)}
          {keyCardPrice && (
            <SmallText
              isNoUppercase
              text={'Keycard CHF ' + formatPriceForDisplay(keyCardPrice)}
            />
          )}
        </div>
        <img
          alt='close-btn'
          src={CloseRed}
          className={'closeButton pointer'}
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default SelectContainer;
