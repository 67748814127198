import React, { FC } from 'react';
import { MembersListProps } from './type';
import './styled.css';
import Member from '../Member';
import { omit } from 'ramda';
import SmallText from '../../SmallText/SmallText';
import { useTranslation } from 'react-i18next';

const MemberList: FC<MembersListProps> = ({
  members,
  onRemoveClick,
  onEditClick,
  setToggleRemoveMemberModal,
  setMemberToRemoveID,
}) => {
  const { t } = useTranslation();
  return (
    <div className={'groupMember'}>
      {members.length > 0 ? (
        members.map((pM: any) => (
          <Member
            key={pM.id}
            id={pM.id}
            name={`${pM.firstName} ${pM?.lastName || ''}`}
            dateOfBirth={pM.dateOfBirth}
            keyCard={
              pM.keycard ? `${pM.keycard.type} ${pM.keycard.displayNumber}` : ''
            }
            onRemoveClick={onRemoveClick}
            onEditClick={() => onEditClick(omit(['__typename'], pM))}
            setToggleRemoveMemberModal={setToggleRemoveMemberModal}
            setMemberToRemoveID={setMemberToRemoveID}
          />
        ))
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '100px' }}>
          <SmallText text={t('checkoutView.addPersons')} isNoUppercase />
        </div>
      )}
    </div>
  );
};

export default MemberList;
