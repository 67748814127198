import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './translations/de.json';
import en from './translations/en.json';

i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: de,
    },
    en: {
      translation: en,
    },
  },
  lng: localStorage.getItem('lang') === 'en' ? 'en' : 'de',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
