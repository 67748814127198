import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styled.css';

const AuthenticatedWrapper: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = Boolean(localStorage.getItem('token'));
  const { t } = useTranslation();

  return (
    <>
      {token ? (
        <>{children}</>
      ) : (
        <div className={'noAccessContainer'}>{t('commons.noAccessToPage')}</div>
      )}
    </>
  );
};

export default AuthenticatedWrapper;
