import { FC } from 'react';

import MainOptionButton from '../../MainOptionButton/MainOptionButton';

import { IMainOptionButton } from '../../../../../interfaces/AtomsRelatedInterfaces';

const LanguageButton: FC<IMainOptionButton> = ({
  children,
  color,
  backgroundColor,
  onClick,
}) => {
  return (
    <MainOptionButton
      backgroundColor={backgroundColor}
      className='languageButton'
      color={color}
      onClick={onClick}
    >
      {children}
    </MainOptionButton>
  );
};

export default LanguageButton;
