import { FC, useEffect } from 'react';
import './styles.css';
import { useState } from 'react';
import { Order } from '../../atoms/BookedTickets/type';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER } from '../../../graphql/user/purchases';
import TicketDetails from '../../atoms/TicketDetails';

const LastTicket = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const userPurchases = useQuery(FETCH_ALL_USER_PURCHASES_DATE_PRICE_NUMBER, {
    fetchPolicy: 'network-only',
  });
  const lastOrder = orders[0];

  useEffect(() => {
    const purchaseData =
      userPurchases?.data?.shopLoadPurchases.filter(
        (o: any) => o?.orderNumber
      ) ?? [];
    purchaseData?.length && setOrders(purchaseData);
  }, [userPurchases]);

  return (
    <>
      {lastOrder && (
        <div style={{ marginBottom: '15px', padding: 20, background: 'white' }}>
          <TicketDetails order={lastOrder} />
        </div>
      )}
    </>
  );
};

export default LastTicket;
