import { FC } from 'react';
import FieldWrapper from '../../molecules/FieldWrapper';
import Input from '../../atoms/Input';

interface InputFieldProps {
  type?: string;
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  style?: any;
}

const InputField: FC<InputFieldProps> = ({
  type = 'text',
  label,
  name,
  disabled,
  required = false,
  style,
}) => (
  <FieldWrapper<string> {...{ label, name, disabled, required }}>
    {(field: any) => (
      <>
        <Input
          {...{ type, disabled, ...field, value: field.value || undefined }}
          style={style}
        />
      </>
    )}
  </FieldWrapper>
);

export default InputField;
