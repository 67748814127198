import useURLQuery from '../../../hooks/useURLQuery';
import { useQuery } from '@apollo/client';
import { SHOP_LOAD_CALENDAR_FOR_PERSON_TYPES } from '../../../graphql/shop/shopLoadCalendarPricesAndQuotas';
import { SHOP_LOAD_PRODUCT } from '../../../graphql/product/product';
import SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES from '../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import moment from 'moment';
import { dateFormat } from '../../../constans/formats';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';

export const useGetURLQueryData = () => {
  const query = useURLQuery();
  const buttonText = query.get('buttonText') || 'Book';
  const productId = query.get('productId') || '';
  const deepLink = query.get('deepLink');
  const startPrice = query.get('startPrice');
  const lang = query.get('lang') || 'de';
  const isMobile = query.get('mobile') === 'true';
  const backgroundColor = query.get('backgroundColor') || '#B30019';

  return {
    buttonText,
    productId,
    deepLink,
    startPrice,
    lang,
    isMobile,
    backgroundColor,
  };
};

export const useCalendarQuery = (personTypes: string[]) => {
  const { chosenSubcategoryState } = useContext(AppContext);
  const [chosenSubcategory] = chosenSubcategoryState;
  const { data: calendarForPersonTypes, loading: calendarLoading } = useQuery(
    SHOP_LOAD_CALENDAR_FOR_PERSON_TYPES,
    {
      variables: {
        input: {
          productId: chosenSubcategory,
          personTypes,
        },
      },
      skip: !chosenSubcategory,
    }
  );
  const calendarDates =
    calendarForPersonTypes?.shopLoadCalendarForPersonTypes?.dates || [];

  return { calendarDates, calendarLoading };
};

export const useProductQuery = () => {
  const { chosenSubcategoryState } = useContext(AppContext);
  const [chosenSubcategory] = chosenSubcategoryState;

  const { data: product, loading: productLoading } = useQuery(
    SHOP_LOAD_PRODUCT,
    {
      fetchPolicy: 'network-only',
      variables: {
        productId: chosenSubcategory,
      },
      skip: !chosenSubcategory,
    }
  );

  const productPersonTypes =
    product?.shopLoadKeycardTicketProduct?.personTypes.map(
      (pT: any) => pT.id
    ) || [];
  const maxConsecutiveDays =
    product?.shopLoadKeycardTicketProduct?.availability?.maxConsecutiveDays ||
    1;

  const selectedPersonType = productPersonTypes.includes('adult-v2')
    ? 'adult-v2'
    : productPersonTypes.includes('entry')
    ? 'entry'
    : productPersonTypes[0];

  return { selectedPersonType, maxConsecutiveDays, productLoading };
};

export const useLoadOfferForPersonType = (personTypes: string[]) => {
  const { ticketsStartDateState, ticketsEndDateState, chosenSubcategoryState } =
    useContext(AppContext);
  const [chosenSubcategory] = chosenSubcategoryState;
  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;

  const offerForPersonType = useQuery(SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        startDate: ticketsStartDate || moment(new Date()).format(dateFormat),
        endDate: ticketsEndDate || moment(new Date()).format(dateFormat),
        productId: chosenSubcategory,
        personTypes,
      },
    },
    skip: !chosenSubcategory,
  });

  const price =
    offerForPersonType?.data?.shopLoadOfferDraftForPersonTypes?.basePricePerPersonType?.find(
      (person: any) => person?.type === personTypes[0]
    )?.price;

  return {
    price,
    offerForPersonType,
    offerForPersonTypeLoading: offerForPersonType.loading,
  };
};
