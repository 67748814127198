import { FC, useContext } from 'react';
import SelectContainer from './SelectContainer';
import Subheader from '../../atoms/Subheader/Subheader';
import ISummaryTicket from '../../../interfaces/SummaryTicketReleatedInterfaces';
import './SummaryTicket.css';
import checkIfPersonTypeMatchesAge from '../../../utils/checkIfPersonTypeMatchesAge';
import { AppContext } from '../../../AppContext';
import useSubPersonTypesAmounts from '../../../hooks/useSubPersonTypesAmounts';

const SummaryTicket: FC<ISummaryTicket> = ({
  offer,
  onAddPersonClick,
  partyMembersTypeAdded,
  partyMembersAdded,
  setPartyMembersAdded,
  optionsPartyMembers,
  productIncludeSeniorType,
  getPersonTypesTitle,
  minimumAge,
  maximumAge,
  getUserKeyCardPrice,
}) => {
  const {
    chosenPersonFieldsState,
    chosenPersonTypesState,
    chosenPartyMembersOnTicketState,
  } = useContext(AppContext);
  const [chosenPersonFields, setChosenPersonFields] = chosenPersonFieldsState;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [chosenPersonTypes, setChosenPersonTypes] = chosenPersonTypesState;
  const [chosenPartyMembersOnTicket, setChosenPartyMembersOnTicket] =
    chosenPartyMembersOnTicketState;
  const personType = offer.type;
  const subPersonTypesAmounts = useSubPersonTypesAmounts(personType);
  const amountOfPeopleInType = offer.total / offer.price;
  const fieldsNames = chosenPersonFields.filter(
    (val: string) => val.split('.')[0] === personType
  );

  const filteredOptionsPartyMembers = optionsPartyMembers.filter(
    (pM) =>
      pM?.dateOfBirth &&
      checkIfPersonTypeMatchesAge(
        personType,
        new Date(pM.dateOfBirth),
        productIncludeSeniorType,
        minimumAge,
        maximumAge
      )
  );

  const personFieldDelete = (fieldName: string, value: string) => {
    const newChosenPersonFields: string[] = chosenPersonFields.filter(
      (value: string) => value !== fieldName
    );
    const newChosenPersonTypes = newChosenPersonFields.map(
      (pF) => pF.split('.')[0]
    );
    const newChosenPartyMembersOnTicket: string[] =
      chosenPartyMembersOnTicket.filter(
        (partyMember: string) => partyMember !== value
      );
    subPersonTypesAmounts();
    setChosenPersonFields(newChosenPersonFields);
    setChosenPersonTypes(newChosenPersonTypes);
    setChosenPartyMembersOnTicket(newChosenPartyMembersOnTicket);
    sessionStorage.setItem('chosenPersonFields', String(newChosenPersonFields));
    sessionStorage.setItem('chosenPersonTypes', String(newChosenPersonTypes));
    sessionStorage.setItem(
      'chosenPartyMembersOnTicket',
      String(newChosenPartyMembersOnTicket)
    );
  };

  return (
    <>
      {Boolean(fieldsNames.length) && (
        <div
          className='summaryTicketContainer'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div className='personAndPrice'>
              <Subheader
                isBlack
                isCaptalize
                text={`${amountOfPeopleInType} x ${getPersonTypesTitle(
                  personType
                )}`}
              />
            </div>
            {fieldsNames.map((fieldName: string) => {
              return (
                <SelectContainer
                  fieldName={fieldName}
                  personFieldDelete={personFieldDelete}
                  productPrice={offer.price}
                  options={filteredOptionsPartyMembers}
                  onAddPersonClick={onAddPersonClick}
                  partyMembersTypeAdded={partyMembersTypeAdded}
                  partyMembersAdded={partyMembersAdded}
                  setPartyMembersAdded={setPartyMembersAdded}
                  key={fieldName}
                  getUserKeyCardPrice={getUserKeyCardPrice}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryTicket;
