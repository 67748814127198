import { gql } from '@apollo/client';

export const SHOP_DIRECT_SAVED_PURCHASE = gql`
  mutation SDP(
    $product: KeycardTicketPurchaseConfigInput!
    $purchase: ShopDirectPurchaseInput!
  ) {
    shopDirectPurchaseWithSavedPaymentMethod(
      productInput: $product
      purchaseInput: $purchase
    ) {
      orderNumber
    }
  }
`;
