import { FC } from 'react';
import { MemberProps } from './type';
import './styled.css';
import closeIcon from '../../../../icons/close.svg';
import editUserIcon from '../../../../icons/edit-user.svg';
import moment from 'moment';

const Member: FC<MemberProps> = ({
  id,
  name,
  dateOfBirth,
  keyCard,
  onEditClick,
  setToggleRemoveMemberModal,
  setMemberToRemoveID,
}) => {
  const handleOpenToggleRemoveModal = () => {
    setToggleRemoveMemberModal(true);
    setMemberToRemoveID(id);
  };
  const language = Boolean(localStorage.getItem('lang') === 'en') ? 'en' : 'de';
  const dateFormat = language === 'en' ? 'YYYY-MM-DD' : 'D.MM.YYYY';
  return (
    <>
      <div className='rowContainer'>
        <div className='leftSectionText'>
          <span className='dateInBookedTicket'>{name}</span>
          <span className='priceInBookedTicket'>
            {moment(dateOfBirth).format(dateFormat)}
          </span>
        </div>
        <div className='rightSectionIcon'>
          <img src={editUserIcon} alt={'icon-edit'} onClick={onEditClick} />
          <img
            src={closeIcon}
            alt={'icon-close'}
            className='closeButtonUserProfile'
            onClick={() => handleOpenToggleRemoveModal()}
          />
        </div>
      </div>
      <span className='keyCardNumber'>{keyCard}</span>
      <div className='userProfileLine'></div>
    </>
  );
};

export default Member;
