import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SmallMenuFooterSocialMedia from './SmallMenuFooterSocialMedia/SmallMenuFooterSocialMedia';
import './SmallMenuFooter.css';
import AboutModal from './AboutModal';

const SmallMenuFooter: FC = () => {
  const { t } = useTranslation();
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const en = localStorage.getItem('lang') === 'en';
  const linkGTC = en
    ? 'https://www.weissearena.com/en/privacy-policy/'
    : 'https://www.weissearena.com/datenschutz/';

  const linkContact = en
    ? 'https://inside.zendesk.com/hc/en-us'
    : 'https://inside.zendesk.com/hc/de-ch';

  return (
    <>
      <div className='smallMenuFotterContainer'>
        <div style={{ marginRight: '1em' }}>
          <SmallMenuFooterSocialMedia
            facebookLink='https://www.facebook.com/LAAX'
            instagramLink='https://www.instagram.com/laax'
            label='Laax'
            youtubeLink='https://www.youtube.com/user/LAAXvideos'
          />
        </div>
        <div>
          <SmallMenuFooterSocialMedia
            facebookLink='https://www.facebook.com/flims'
            instagramLink='https://instagram.com/flims_switzerland/'
            label='Flims'
            youtubeLink='https://www.youtube.com/user/flimsswitzerland'
          />
        </div>
      </div>
      <div style={{ display: 'flex', fontSize: '12px' }}>
        <a rel='noreferrer' target='_blank' href={linkGTC}>
          <span className='social-media-label'>
            {String(`${t('commons.gtc')}`).toUpperCase()}
            <span>&nbsp;</span>
          </span>
        </a>
        <span
          className='social-media-label'
          onClick={() => setIsOpenAbout(true)}
        >
          {String(` / ${t('commons.imprint')}`).toUpperCase()}
          <span>&nbsp;</span>
        </span>
        <a rel='noreferrer' target='_blank' href={linkContact}>
          <span className='social-media-label'>
            {String(` / ${t('commons.contact')}`).toUpperCase()}
            <span>&nbsp;</span>
          </span>
        </a>
      </div>
      <span className='version-label'>
        {'v ' + process.env.REACT_APP_VERSION}
      </span>
      <AboutModal isOpen={isOpenAbout} close={() => setIsOpenAbout(false)} />
    </>
  );
};

export default SmallMenuFooter;
