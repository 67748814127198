import { gql } from '@apollo/client';

const SAVE_PROFILE_DETAILS = gql`
  mutation SaveProfileDetails($input: SaveProfileDetailsInput!) {
    saveProfileDetails(input: $input) {
      profile {
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export default SAVE_PROFILE_DETAILS;
