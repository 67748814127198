import { useContext } from 'react';
import { AppContext } from '../AppContext';

const useSubPersonTypesAmounts = (personType: string) => {
  const { chosenPersonTypesState } = useContext(AppContext);
  const [chosenPersonTypes, setChosenPersonTypes] = chosenPersonTypesState;

  const subPersonTypesAmounts = (): void => {
    const index = chosenPersonTypes.findIndex(
      (cTP: string) => cTP == personType
    );
    if (index > -1) {
      const newArray = Array.from(chosenPersonTypes);
      newArray.splice(index, 1);
      setChosenPersonTypes(newArray);
      sessionStorage.setItem('chosenPersonTypes', String(newArray));
    }
  };
  return subPersonTypesAmounts;
};
export default useSubPersonTypesAmounts;
