import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatPriceForDisplay from '../../../../utils/formatPriceForDisplay';
import { AppContext } from '../../../../AppContext';
import Checkbox from '../../../atoms/Checkbox/Checkbox';
import PayButton from '../../../atoms/buttons/PayButton/PayButton';
import './styled.css';
import { TotalProps } from './type';
import SelectList from '../../../atoms/SelectList/SelectList';
import ActivateTokenModal from '../../../molecules/ActivateTokenModal';
import { toast } from 'react-toastify';

const Total: FC<TotalProps> = ({
  isTotalCostActive,
  handlePayment,
  totalPrice,
  setTotalPrice,
  isSubmitBtnDisabled,
  isAgreementChecked,
  setIsAgreementChecked,
  discountTokenId,
  changeDiscountTokenId,
  discountTokens,
  offerDiscountsReFetch,
  offerDiscountsLoading,
  setActivatedTokenId,
  selectedDiscountToken,
  mobileView,
  shopLoadOfferData,
  shopLoadOfferError,
  shopLoadOfferLoading,
}) => {
  const { t } = useTranslation();
  const [addTokenModal, setAddTokenModal] = useState(false);
  const [lastError, setLastError] = useState<string | undefined>(undefined);
  const {
    chosenPartyMembersOnTicketState,
    chosenPersonTypesState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [chosenSubcategory] = chosenSubcategoryState;

  const discountOptions = [
    { label: t('checkoutView.withoutToken'), value: null },
    ...(discountTokens.map((token: any) => ({
      label: token.title,
      value: token.id,
    })) ?? []),
  ];

  useEffect(() => {
    chosenSubcategory &&
      setTotalPrice(shopLoadOfferData?.shopLoadOffer?.totalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shopLoadOfferData?.shopLoadOffer?.totalPrice,
    chosenSubcategory,
    chosenPartyMembersOnTicket,
  ]);

  useEffect(() => {
    if (shopLoadOfferLoading === false) {
      if (shopLoadOfferError && lastError !== shopLoadOfferError?.message)
        toast(shopLoadOfferError?.message, { type: 'error' });
      setLastError(shopLoadOfferError?.message);
    }
  }, [shopLoadOfferError, shopLoadOfferLoading]);

  useEffect(() => {
    if (!shopLoadOfferLoading) {
      const navigationButtons = document.getElementById('buttons');
      navigationButtons && navigationButtons.scrollIntoView();
    }
  }, [shopLoadOfferLoading, mobileView]);

  return (
    <div className='checkoutTotalContainer'>
      {!shopLoadOfferLoading && (
        <>
          <h3>{t('commons.total')}</h3>

          <div className='costsContainer'>
            {selectedDiscountToken && (
              <div className='discountPrice'>
                <div className='discountCurrency'>CHF</div>
                <div className='discountLine' />
                {formatPriceForDisplay(totalPrice)}
              </div>
            )}
            {offerDiscountsLoading ||
            shopLoadOfferError ||
            !chosenPersonTypes?.length ? (
              <div className='price'>-</div>
            ) : (
              <>
                <div className='currency'>CHF</div>
                <div className='price'>
                  {totalPrice >= 0 &&
                    formatPriceForDisplay(
                      selectedDiscountToken
                        ? totalPrice - selectedDiscountToken?.discount
                        : totalPrice
                    )}
                </div>
              </>
            )}
            <span className='vat'>{t('commons.VATIncluded')}</span>
          </div>

          <div className={isTotalCostActive ? 'totalBox' : 'grayedTotalBox'}>
            <Checkbox
              defaultChecked={isAgreementChecked}
              onClick={() => setIsAgreementChecked(!isAgreementChecked)}
            />
            <SelectList
              additionalOnChange={(e: { target: HTMLSelectElement }) =>
                changeDiscountTokenId(e.target.value)
              }
              propValue={discountTokenId || undefined}
              options={discountOptions}
              placeholder={t('checkoutView.selectToken')}
              isGrey
            />
            <div className={'addToken'} onClick={() => setAddTokenModal(true)}>
              {t('checkoutView.addToken')}
            </div>
            <div className='payButtonContainer'>
              <PayButton
                className='pay-button'
                disabled={isSubmitBtnDisabled}
                onClick={handlePayment}
              >
                {t('commons.next')}
              </PayButton>
            </div>
          </div>
        </>
      )}
      <ActivateTokenModal
        isOpen={addTokenModal}
        setIsOpen={setAddTokenModal}
        setActivatedTokenId={setActivatedTokenId}
        offerDiscountsReFetch={offerDiscountsReFetch}
      />
    </div>
  );
};

export default Total;
