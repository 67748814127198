/* eslint-disable array-callback-return */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import list from '../../../icons/list-red.svg';
import './styles.css';
import { AppContext } from '../../../AppContext';
import SimpleSelect from '../../atoms/SimpleSelect';
import { ShopLandingProduct } from '../../../interfaces/ShopRelatedInterfaces';
import useClearSelectedData from '../../../hooks/useClearSelectedData';
import { useHistory } from 'react-router-dom';

const Options = () => {
  const { t } = useTranslation();
  const {
    availableProductsState,
    chosenMainCategoryState,
    chosenSubcategoryState,
    menuHiddenState,
    chosenGroupCategoryState,
  } = useContext(AppContext);
  const [isMenuHidden, setIsMenuHidden] = menuHiddenState;
  const [availableProducts] = availableProductsState;
  const [chosenMainCategory] = chosenMainCategoryState;
  const [chosenSubcategory, setChosenSubcategory] = chosenSubcategoryState;
  const clearSelectedData = useClearSelectedData();
  const history = useHistory();
  const [chosenGroupCategory] = chosenGroupCategoryState;

  const groupCategories = availableProducts?.filter(
    (product: ShopLandingProduct) => {
      return (
        chosenGroupCategory &&
        product?.productVariantGroupConfig?.find(
          (c) =>
            c['ticket-group']?.id === chosenGroupCategory &&
            c['navigation-group']?.id === chosenMainCategory
        )
      );
    }
  );

  const options = groupCategories?.map((sC: ShopLandingProduct) => ({
    value: sC?.id,
    label: sC.title,
  }));

  useEffect(() => {
    if (options.length === 1) {
      sessionStorage.setItem('chosenSubcategory', options[0].value);
      setChosenSubcategory(options[0].value);
    }
  }, [options]);

  if (options.length > 0)
    return (
      <div className='options-container'>
        <Subheader isCentered icon={list} text={t('commons.options')} isBlack />
        <SimpleSelect
          value={chosenSubcategory}
          placeholder={t('commons.selectOption')}
          options={options}
          onChange={(e) => {
            !isMenuHidden && setIsMenuHidden(true);
            clearSelectedData();
            sessionStorage.setItem('chosenSubcategory', e.target.value);
            setChosenSubcategory(e.target.value);
            history.push('/');
          }}
        />
      </div>
    );
  else return null;
};
export default Options;
