import { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import './styles.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import {
  ShopCategory,
  ShopLandingProduct,
} from '../../../../interfaces/ShopRelatedInterfaces';
import getImageLink from '../../../../utils/getImageLink';
import SmallMainViewTile from '../../../molecules/SmallMainViewTile/SmallMainViewTile';
import MostLovedProducts from '../../../molecules/MostLovedProducts';
import useClearSelectedData from '../../../../hooks/useClearSelectedData';
import { useHistory } from 'react-router-dom';

const CategoryButtons: FC = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined);
  const {
    mainCategoriesState,
    availableProductsState,
    buyTicketViewState,
    menuHiddenState,
    chosenMainCategoryState,
    chosenSubcategoryState,
  } = useContext(AppContext);
  const history = useHistory();
  const [mainCategories] = mainCategoriesState;
  const [availableProducts] = availableProductsState;
  const [, setChosenMainCategory] = chosenMainCategoryState;
  const [, setChosenSubcategory] = chosenSubcategoryState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [, setIsMenuHidden] = menuHiddenState;
  const clearSelectedData = useClearSelectedData();

  const products = selectedCategoryId
    ? availableProducts?.filter(
        (product: ShopLandingProduct) =>
          product?.categoryId === selectedCategoryId
      )
    : [];

  const handleProductClick = (product: ShopLandingProduct) => {
    setIsMenuHidden(true);
    clearSelectedData();
    setIsBuyTicketView(true);
    sessionStorage.setItem('isBuyTicketView', 'true');
    history.push('/buy-tickets');
    setChosenMainCategory(product?.categoryId);
    setChosenSubcategory(product?.id);
    sessionStorage.setItem('chosenMainCategory', product?.categoryId ?? '');
    sessionStorage.setItem('chosenSubcategory', product?.id ?? '');
  };
  return (
    <div style={{ width: '315px' }}>
      <div className={'categories-container'}>
        {mainCategories.map((category: ShopCategory, index: number) => (
          <div
            key={category.id + index}
            className={'category-container'}
            onClick={() => setSelectedCategoryId(category.id)}
            style={{
              border: `1px solid ${
                selectedCategoryId === category.id ? 'grey' : 'white'
              }`,
            }}
          >
            {category?.title}
          </div>
        ))}
      </div>
      <div className={'sectionLabel'}>
        {
          mainCategories.filter(
            (c: ShopCategory) => c.id === selectedCategoryId
          )[0]?.title
        }
      </div>
      {mainCategories.map(
        (category: ShopCategory) =>
          category.id === selectedCategoryId && (
            <CarouselProvider
              naturalSlideWidth={260}
              naturalSlideHeight={210}
              totalSlides={(products?.length * 280 - 10) / 315}
              orientation='horizontal'
              interval={1000}
              dragEnabled={true}
              visibleSlides={1}
            >
              <Slider>
                {products?.map((product: ShopLandingProduct, index: number) => (
                  <Slide
                    index={index}
                    key={product.id}
                    style={{
                      width: '270px',
                      marginLeft: index !== 0 ? '10px' : '0',
                    }}
                  >
                    <SmallMainViewTile
                      imgSrc={getImageLink(product?.image.asset._ref) || ''}
                      name={product.id}
                      title={product?.title ?? ''}
                      onClick={() => handleProductClick(product)}
                    />
                  </Slide>
                ))}
              </Slider>
            </CarouselProvider>
          )
      )}
      <MostLovedProducts mobileView />
    </div>
  );
};
export default CategoryButtons;
