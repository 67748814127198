import { FC } from 'react';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../AppContext';
import useIsAnyPersonIsChosenOnTicket from '../../../../hooks/useIsAnyPersonIsChosenOnTicket';
import { CheckoutViewWrapperProps } from './type';
import UrlReplaceStateWrapper from '../../../molecules/UrlReplaceStateWrapper';
import { useHistory } from 'react-router-dom';

const CheckoutViewWrapper: FC<CheckoutViewWrapperProps> = ({
  setIsTotalCostActive,
  ticketWithPartMembers,
  children,
}) => {
  const { chosenPartyMembersOnTicketState, chosenPersonTypesState } =
    useContext(AppContext);
  const history = useHistory();

  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const arePeopleOnTicket = useIsAnyPersonIsChosenOnTicket();
  const chosenPartyMembersOnTicketWithoutEmpty =
    chosenPartyMembersOnTicket.filter((id: string) => id !== '');

  useEffect(() => {
    setIsTotalCostActive(
      !ticketWithPartMembers ||
        Boolean(
          arePeopleOnTicket &&
            chosenPersonTypes.length ===
              chosenPartyMembersOnTicketWithoutEmpty.length
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketWithPartMembers, chosenPersonTypes, chosenPartyMembersOnTicket]);

  return (
    <UrlReplaceStateWrapper path={history.location.pathname}>
      {children}
    </UrlReplaceStateWrapper>
  );
};

export default CheckoutViewWrapper;
