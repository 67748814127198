import { gql } from '@apollo/client';

const ACTIVATE_TOKEN = gql`
  mutation ActivateToken($input: RedeemPromoCodeInput!) {
    redeemPromoCode(input: $input) {
      awardedToken {
        id
        name
      }
    }
  }
`;

export default ACTIVATE_TOKEN;
