import { FC } from 'react';

import { IDownloadApp } from '../../../interfaces/MainPageRelatedInterfaces';
import { useTranslation } from 'react-i18next';

import './DownloadApp.css';

import downloadApp from '../../../assets/downloadApp.png';

const DownloadApp: FC<IDownloadApp> = ({ src, alt }) => {
  const { t } = useTranslation();
  return (
    <div className='downloadAppContainer'>
      <h1 className='downloadAppTitle'>
        {String(`${t('startView.downloadAppTitle')}`)}
      </h1>
      <img src={downloadApp} alt={alt} className='downloadAppPhoneIMG'></img>
      <div className='downloadAppButton'>
        <a rel='noreferrer' target='_blank' href='https://laax.app.link/ '>
          {String(`${t('startView.downloadAppBtnText')}`)}
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
