import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Subheader from '../../atoms/Subheader/Subheader';
import '../AddMemberModal/AddMemberModal.css';
import userIcon from '../../../icons/user-red.svg';
import '../../atoms/TileContainer/TileContainer.css';
import '../RemoveMemberModal/RemoveMemberModal.css';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';

import { RemoveMemberModalProps } from '../../../interfaces/AddMemberReleatedInterfaces';
import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import Modal from '../../atoms/Modal';

const RemoveMemberModal: FC<RemoveMemberModalProps> = ({
  setToggleRemoveMemberModal,
  onRemoveClick,
  isOpen,
}) => {
  const { t } = useTranslation();
  const handleConfirmRemove = () => {
    onRemoveClick();
    setToggleRemoveMemberModal(false);
  };
  return (
    <Modal isOpen={isOpen}>
      <div className='removeMemberModalContainer'>
        <div className='exitButton'>
          <MainOptionButton
            onClick={(): void => setToggleRemoveMemberModal(false)}
          >
            x
          </MainOptionButton>
        </div>

        <Subheader icon={userIcon} text={t('user.removeMember')} />
        <div className='removeModalInfo'>
          <p>{t('user.removeMemberMsg')}</p>
        </div>
        <div className='removeModalButtonsContainer'>
          <Button
            className='submit-button'
            onClick={() => handleConfirmRemove()}
          >
            {t('commons.yes')}
          </Button>
          <Button
            className='submit-button'
            onClick={() => setToggleRemoveMemberModal(false)}
          >
            {t('commons.no')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveMemberModal;
