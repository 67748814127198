import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const CategoryIcon = styled.div<{ imagePath: string; color: string }>`
  width: 60px;
  height: 60px;
  background: ${({ color }) => color};
  mask: ${({ imagePath }) =>
    `url(https://cdn.sanity.io/images/prci4ijt/production/${imagePath}) no-repeat center`};
`;
