import React, { FC, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import Modal from '../../atoms/Modal';
import { useTranslation } from 'react-i18next';
import MainOptionButton from '../../atoms/buttons/MainOptionButton/MainOptionButton';
import Subheader from '../../atoms/Subheader/Subheader';
import cameraIcon from '../../../icons/camera.svg';
import './styled.css';

interface QrCodeDetectorProps {
  handleClose: () => void;
  isOpen: boolean;
  onDetect: (code: string) => void;
}

const QrCodeDetector: FC<QrCodeDetectorProps> = ({
  isOpen,
  handleClose,
  onDetect,
}) => {
  const { t } = useTranslation();
  const [facingMode, setFacingMode] = useState('user');

  const validateQrCode = (qrCode: string): string => {
    const splitQrCode = qrCode?.split('#') || [];
    return splitQrCode?.length > 0 ? splitQrCode[1] : qrCode;
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={'qrCodeDetectorContainer'}>
        <div className='exitButton'>
          <MainOptionButton onClick={handleClose}>x</MainOptionButton>
        </div>

        <Subheader icon={cameraIcon} text={t('commons.scanCode')} isBlack />
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
            onClick={() => setFacingMode('user')}
          >
            <div className={'ratioCircleQrCode'}>
              {facingMode === 'user' && <div className={'ratioDotQrCode'} />}
            </div>
            <div>{t('commons.frontCamera')}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setFacingMode('environment')}
          >
            <div className={'ratioCircleQrCode'}>
              {facingMode === 'environment' && (
                <div className={'ratioDotQrCode'} />
              )}
            </div>
            <div>{t('commons.backCamera')}</div>
          </div>
        </div>

        <QrReader
          onResult={(result) => {
            if (!!result) {
              onDetect(validateQrCode(result.getText()));
              handleClose();
            }
          }}
          constraints={{ facingMode }}
          containerStyle={{ maxHeight: '500px' }}
        />
      </div>
    </Modal>
  );
};
export default QrCodeDetector;
