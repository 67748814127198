import { FC } from 'react';

import { ITileContainer } from '../../../interfaces/AtomsRelatedInterfaces';
import './TileContainer.css';

const TileContainer: FC<ITileContainer> = ({ children }) => {
  return <div className='tileContainerWithChildren'>{children}</div>;
};

export default TileContainer;
