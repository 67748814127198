import React, { ReactElement, useEffect } from 'react';
import { useField, FieldInputProps, FieldHelperProps } from 'formik';
import './styled.css';

export type FieldType = string | string[] | number | boolean | Date;

interface FieldWrapperProps<T> {
  label?: string;
  name: string;
  children(
    field: FieldInputProps<T>,
    helpers: FieldHelperProps<T>
  ): ReactElement;
  onChange?: (value: T) => void;
  required: boolean;
}

function FieldWrapper<T extends FieldType>({
  children,
  label,
  name,
  onChange,
  required,
}: FieldWrapperProps<T>): ReactElement {
  const [field, meta, helpers] = useField<T>(name);
  const showError = meta.error && meta.touched;
  const requiredSign = required ? '*' : '';

  useEffect(() => {
    if (onChange && field.value) onChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div className={'container'}>
      {label !== undefined && (
        <label className='fieldLabel' htmlFor={label}>
          {label} <span style={{ color: '#c40404' }}>{requiredSign}</span>
        </label>
      )}
      <div className={'child'}>{children && children(field, helpers)}</div>
      {showError && <span style={{ color: '#c40404' }}>{meta.error}</span>}
    </div>
  );
}

export default FieldWrapper;
