import React, { FC, HTMLAttributes } from 'react';

import './styled.css';

interface InputProps extends HTMLAttributes<HTMLInputElement> {
  readonly type?: string;
  value?: any;
}

const Input: FC<InputProps> = ({ ...props }) => (
  <input className='input' type='input' {...props} />
);

export default Input;
