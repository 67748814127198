import React, { FC, useState } from 'react';
import closeIcon from '../../../icons/cancel-black.svg';
import './PaymentOverlay.css';

const PaymentOverlay: FC<{
  onClose: () => void;
  handleCloseWhenSuccess: () => void;
  purchase?: {
    successUrl: string;
    redirectUrl: string;
  };
}> = ({ onClose, handleCloseWhenSuccess, purchase }) => {
  const correctRedirectUrl =
    String(purchase?.redirectUrl).slice(0, 25) === 'https://www.saferpay.com/';
  const [canClose, setCanClose] = useState(true);
  const onIframeLoad = (event: any) => {
    const successUrl = purchase?.successUrl;
    let href = null;
    try {
      href = event.target.contentWindow?.location.href;
      console.log(href);
    } catch (error) {
      console.log(error);
    }

    if (successUrl && href === successUrl) {
      setCanClose(false);
      handleCloseWhenSuccess();
    }
  };

  return (
    <div className='payment-container'>
      <div className='payment-overlay' />
      {canClose && (
        <button className='payment-close' onClick={onClose}>
          <img src={closeIcon} alt='close' />
        </button>
      )}
      {Boolean(purchase) && (
        <>
          {!correctRedirectUrl ? (
            <div className='payment-iframe'>
              <div
                style={{
                  width: 'calc(100% - 100px)',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 50px',
                }}
              >
                <p>
                  Deine Zahlung war nicht erfolgreich. Überprüfe deine Angaben
                  und versuche es erneut. Purchase failed. Please check your
                  payment information and try again.
                </p>
              </div>
            </div>
          ) : (
            <iframe
              className='payment-iframe'
              id='payment-iframe-id'
              src={purchase?.redirectUrl}
              title='payment-iframe'
              onLoad={onIframeLoad}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PaymentOverlay;
