import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import ErrorToast from '../../atoms/ErrorToast/ErrorToast';
import InputField from '../../molecules/InputField';

import { IAddMemberForm } from '../../../interfaces/AddMemberReleatedInterfaces';

import { FETCH_BASIC_DATA_OF_GUEST } from '../../../graphql/user/guest';
import SAVE_PROFILE_DETAILS from '../../../graphql/user/saveProfileDetails';
import {
  sendAnalytics,
  SegmentAnalyticsTypes,
} from '../../../utils/sendAnalytics';

const UserForm: FC<IAddMemberForm> = ({ isAddPerson }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const token = Boolean(localStorage.getItem('token'));

  const {
    data,
    error,
    refetch: refetchUserDetails,
  } = useQuery(FETCH_BASIC_DATA_OF_GUEST, { skip: !token });

  const { id, firstName, lastName, phoneNumber, keycard, language, email } =
    data?.guest?.profile ?? {};
  const statusCode = error?.message.indexOf('400') !== -1 ? 400 : undefined;

  const [saveProfileDetails] = useMutation(SAVE_PROFILE_DETAILS);
  const [initialValues, setInitialValues] = useState({
    firstName: firstName ?? null,
    lastName: lastName ?? null,
    phoneNumber: phoneNumber ?? null,
    keycard: keycard ?? null,
  });
  const handleSaveClick = (values: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => {
    saveProfileDetails({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values?.lastName || null,
          phoneNumber: values?.phoneNumber || null,
        },
      },
    }).then((res) => {
      if (res?.data?.saveProfileDetails) {
        sendAnalytics(
          id,
          {
            name: values.firstName + ' ' + values.lastName,
            language,
            phoneNumber: values?.phoneNumber || null,
            email,
          },
          SegmentAnalyticsTypes.IDENTIFY
        );
        toast(t('toasts.profileDetailsUpdateSuccess'), { type: 'success' });
        refetchUserDetails();
      } else {
        toast(t('toasts.serverError'), { type: 'error' });
      }
    });
  };
  const phoneRegExp = /^[+\d]+(?:[\d-.\s()]*)$/;
  const validationSchema = (): object => {
    return object().shape(
      Object.assign({
        firstName: string().nullable(),
        lastName: string().nullable(),
        phoneNumber: string()
          .matches(phoneRegExp, 'Phone number is not valid.')
          .nullable(),
        keycard: string().nullable(),
      })
    );
  };

  useEffect(() => {
    setInitialValues({
      firstName,
      lastName,
      phoneNumber,
      keycard,
    });
  }, [firstName, lastName, phoneNumber, keycard]);

  return (
    <>
      {error && <ErrorToast statusCode={statusCode} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSaveClick}
        validationSchema={validationSchema}
      >
        <Form>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              height: '368px',
              width: '100%',
              paddingRight: '10px',
              alignContent: 'space-between',
            }}
          >
            <div style={{ width: '100%' }}>
              <InputField
                label={t('commons.name')}
                type='text'
                name='firstName'
              />
              <InputField
                label={t('commons.surname')}
                type='text'
                name='lastName'
              />
              {location.pathname === '/user-panel' && !isAddPerson ? (
                <InputField
                  label={t('commons.phone')}
                  type='text'
                  name='phoneNumber'
                />
              ) : null}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                className='submit-button buttonInYourProfile'
                type='submit'
              >
                {t('commons.save')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UserForm;
