import { useQuery } from '@apollo/client';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../AppContext';
import ApolloProviderWrapper from '../../atoms/ApolloProviderWrapper/ApolloProviderWrapper';
import BookTicketStatusBar from '../../molecules/BookTicketStatusBar/BookTicketStatusBar';
import BookTicketStatusBarMobile from '../../molecules/BookTicketStatusBar/BookTicketStatusBarMobile';
import BookingCalendarWithStatuses from '../../molecules/BookingCalendarWithStatuses/BookingCalendarWithStatuses';
import CustomerTypeTickets from '../../molecules/CustomerTypeTickets/CustomerTypeTickets';
import Spinner from '../../atoms/Spinner/Spinner';
import ToggleSideMenu from '../../molecules/ToggleSideMenu/ToggleSideMenu';
import './BuyTicketView.css';
import { FETCH_PRODUCT_SETTINGS } from '../../../graphql/shop/shopLoadKeycardTicketProduct';
import Button from '../../atoms/buttons/SubmitButton/SubmitButton';
import useIsAnyPersonIsChosenOnTicket from '../../../hooks/useIsAnyPersonIsChosenOnTicket';
import LoginButton from '../../atoms/buttons/LoginButton/LoginButton';
import Total from './Total';
import BuyTicketWrapper from './BuyTickeWrapper';
import SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES from '../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import { SHOP_LOAD_CALENDAR_FOR_PERSON_TYPES } from '../../../graphql/shop/shopLoadCalendarPricesAndQuotas';
import Addons from '../../molecules/Addons';
import TicketInformation from '../../molecules/TicketInformation';
import SignIn from './SignIn';
import { toast } from 'react-toastify';
import Upgrades from '../../molecules/Upgrades';
import { useGetDataFromProductSettings } from './hooks';
import sendAnalytics from '../../../utils/sendAnalytics';
import useGetDataFromSettings from '../../../hooks/useGetDataFromSettings';
import Options from '../../molecules/Options';
import { ShopLandingProduct } from '../../../interfaces/ShopRelatedInterfaces';
import { dateFormat } from '../../../constans/formats';

interface IBuyTicketView {
  isProductActiveMobile?: boolean;
  isDataActiveMobile?: boolean;
  isPersonActiveMobile?: boolean;
}

const BuyTicketView: FC<IBuyTicketView> = () => {
  const {
    chosenSubcategoryState,
    ticketsStartDateState,
    ticketsEndDateState,
    chosenPersonTypesState,
    showSubTotalCostViewState,
    availableProductsState,
    chosenMainCategoryState,
  } = useContext(AppContext);

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [showSubTotalCostView, setShowSubTotalCostView] =
    showSubTotalCostViewState;
  const [mobileView, setMobileView] = useState('date');
  const isProductActiveMobile = mobileView === 'product';
  const isDataActiveMobile = mobileView === 'date';
  const isPersonActiveMobile = mobileView === 'people';
  const isBuyActiveMobile = mobileView === 'buy';

  const [toggleStepsView] = useState(1);
  const [chosenSubcategory] = chosenSubcategoryState;
  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [basePrice, setBasePrice] = useState(0);
  const isChosenPerson = useIsAnyPersonIsChosenOnTicket();
  const submitButtonDisabled = !isChosenPerson;
  const [availableProducts] = availableProductsState;
  const [chosenMainCategory] = chosenMainCategoryState;
  const subCategory = availableProducts?.find(
    (product: ShopLandingProduct) => product?.categoryId === chosenMainCategory
  );
  const { data: productSettings, loading } = useQuery(FETCH_PRODUCT_SETTINGS, {
    fetchPolicy: 'network-only',
    variables: {
      productId: chosenSubcategory || subCategory?.id,
    },
    skip: !chosenSubcategory && !subCategory?.id,
  });
  const shopDraftResult = useQuery(SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        startDate: ticketsStartDate || moment(new Date()).format(dateFormat),
        endDate: ticketsEndDate || moment(new Date()).format(dateFormat),
        productId: chosenSubcategory,
        personTypes: chosenPersonTypes,
      },
    },
    skip: !chosenSubcategory || chosenPersonTypes[0] === '',
  });
  const {
    data: calendarForPersonTypes,
    loading: loadingCalendarForPersonTypes,
  } = useQuery(SHOP_LOAD_CALENDAR_FOR_PERSON_TYPES, {
    variables: {
      input: {
        productId: chosenSubcategory,
        personTypes: chosenPersonTypes || [],
      },
    },
    skip: !chosenSubcategory,
  });

  const {
    maxConsecutiveDays,
    upgradesFromSettings,
    addonsFromSettings,
    productTitle,
    productDescription,
  } = useGetDataFromProductSettings(productSettings);
  const { addons, upgrades, addonsPrice, upgradesPrice, productsAnalytics } =
    useGetDataFromSettings(
      shopDraftResult,
      addonsFromSettings,
      upgradesFromSettings
    );

  const handleGoToCosts = () => {
    if (!submitButtonDisabled) setMobileView('buy');
  };

  const nextClick = () =>
    sendAnalytics('Cart Viewed', { cart_id: '', ...productsAnalytics });

  useEffect(() => {
    if (showSubTotalCostView) {
      setShowSubTotalCostView(false);
      sessionStorage.setItem('showSubTotalCostView', String(false));
      setMobileView('buy');
    }
  }, [showSubTotalCostView]);

  useEffect(() => {
    if (!loading) {
      const navigationButtons = document.getElementById('buttons');
      navigationButtons && navigationButtons.scrollIntoView();
    }
  }, [loading, mobileView]);

  useEffect(() => {
    if (shopDraftResult.error)
      toast(shopDraftResult.error.message, { type: 'error' });
  }, [shopDraftResult.error]);

  return (
    <BuyTicketWrapper>
      <div className='buyTicketContainer'>
        <div className='buyTicketLeftSection'>
          <ToggleSideMenu />
          <div
            className={'sectionLabel'}
            style={{ width: 315, paddingTop: '5px' }}
          >
            {t('buyTicketView.information')}
          </div>
          <TicketInformation
            chosenSubcategory={chosenSubcategory}
            title={productTitle}
            description={productDescription}
          />
        </div>
        <div className='buyTicketRightSection'>
          <div className='buyTicketRightTop '>
            <BookTicketStatusBar withoutDetails={true} />
          </div>
          <div className='buyTicketRightBottom'>
            <div
              className={
                isDataActiveMobile ? 'rightBottomSection-1 active' : 'disactive'
              }
            >
              <div className={'sectionLabel'}>
                {'1. ' + t('buyTicketView.selectDate')}
              </div>
              <Options />
              {loading ? (
                <Spinner customDIVHeight='150' />
              ) : (
                <BookingCalendarWithStatuses
                  maxConsecutiveDays={maxConsecutiveDays}
                  shopDraftResult={shopDraftResult}
                  calendarData={
                    calendarForPersonTypes?.shopLoadCalendarForPersonTypes
                      ?.dates
                  }
                  loadingCalendarData={loadingCalendarForPersonTypes}
                />
              )}
            </div>
            <div
              className={
                isPersonActiveMobile
                  ? 'rightBottomSection-2 active'
                  : 'disactive'
              }
            >
              <div className={'sectionLabel'}>
                {'2. ' + t('buyTicketView.selectTickets')}
              </div>

              <div className={!ticketsStartDate ? 'grayed' : 'active'}>
                <CustomerTypeTickets
                  defaultPersonTypes={
                    productSettings?.shopLoadKeycardTicketProduct?.personTypes
                  }
                  shopDraftResult={shopDraftResult}
                  startDate={ticketsStartDate}
                  calendarData={
                    calendarForPersonTypes?.shopLoadCalendarForPersonTypes
                      ?.dates
                  }
                  loadingCalendarData={loadingCalendarForPersonTypes}
                />
              </div>
              <div className={!ticketsStartDate ? 'grayed' : 'active'}>
                {!loading && (
                  <Upgrades
                    upgrades={upgrades}
                    upgradesFromSettings={upgradesFromSettings}
                  />
                )}
              </div>
              <div className={!ticketsStartDate ? 'grayed' : 'active'}>
                {!loading && (
                  <Addons
                    addons={addons}
                    addonsFromSettings={addonsFromSettings}
                  />
                )}
              </div>
            </div>
            <div className={isBuyActiveMobile ? 'active' : 'disactive'}>
              <div className={'sectionLabel'}>
                {'3. ' + t('buyTicketView.checkOrder')}
              </div>
              <div className={!submitButtonDisabled ? 'active' : 'grayed'}>
                <ApolloProviderWrapper>
                  <Total
                    productTitle={productTitle}
                    productId={chosenSubcategory}
                    isTotalCostActive={!submitButtonDisabled}
                    basePrice={basePrice + addonsPrice + upgradesPrice}
                    setBasePrice={setBasePrice}
                    loginButtonDisabled={submitButtonDisabled}
                    endDate={ticketsEndDate}
                    startDate={ticketsStartDate}
                    addonsFromSettings={addonsFromSettings}
                    upgradesFromSettings={upgradesFromSettings}
                    nextClick={nextClick}
                  />
                </ApolloProviderWrapper>
              </div>
              {!token && <SignIn />}
            </div>
          </div>
        </div>
        <div className='hideOnWeb'>
          <div className='navigationButtonsBox' id={'buttons'}>
            {isProductActiveMobile ? (
              <Button
                className='submit-button'
                onClick={() => setMobileView('date')}
              >
                {t('commons.next')}
              </Button>
            ) : null}
            {isDataActiveMobile ? (
              <div className='nextBackButtons'>
                <Button
                  className='submit-button'
                  onClick={() => setMobileView('product')}
                >
                  {t('commons.back')}
                </Button>
                <Button
                  className='submit-button'
                  disabled={!ticketsStartDate}
                  onClick={() => setMobileView('people')}
                >
                  {t('commons.next')}
                </Button>
              </div>
            ) : null}
            {isPersonActiveMobile ? (
              <div className='nextBackButtons'>
                <Button
                  className='submit-button'
                  onClick={() => setMobileView('date')}
                >
                  {t('commons.back')}
                </Button>
                <Button
                  className='submit-button'
                  disabled={submitButtonDisabled}
                  onClick={handleGoToCosts}
                >
                  {t('commons.next')}
                </Button>
              </div>
            ) : null}

            {isBuyActiveMobile ? (
              <div className='nextBackButtons'>
                <Button
                  className='submit-button'
                  onClick={() => setMobileView('people')}
                >
                  {t('commons.back')}
                </Button>
                <div style={{ width: '124px' }}>
                  <LoginButton
                    onClickFunction={nextClick}
                    disabled={submitButtonDisabled}
                    navigationRoute={`${window.location.origin}/checkout/view-cart`}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <BookTicketStatusBarMobile
            isBuyActiveMobile={isBuyActiveMobile}
            isDataActiveMobile={isDataActiveMobile}
            isPersonActiveMobile={isPersonActiveMobile}
            toggleStepsView={toggleStepsView}
            endDate={ticketsEndDate}
            startDate={ticketsStartDate}
            setMobileView={setMobileView}
          />
        </div>
      </div>
    </BuyTicketWrapper>
  );
};

export default BuyTicketView;
