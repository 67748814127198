import { useTranslation } from 'react-i18next';

import Subheader from '../../atoms/Subheader/Subheader';
import UserForm from '../../molecules/UserForm/UserForm';

import profileIconRed from '../../../icons/profile-icon-red.svg';

import './YourProfile.css';

const YourProfile = () => {
  const { t } = useTranslation();

  return (
    <div className='yourProfileContainer'>
      <Subheader
        icon={profileIconRed}
        text={String(t('user.yourProfile')).toUpperCase()}
        isBlack
      />
      <UserForm />
    </div>
  );
};

export default YourProfile;
