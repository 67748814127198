import { FC, useRef } from 'react';
import Calendar from 'react-calendar';

import i18n from '../../../i18n';

import './BookingCalendar.css';
import '../../../index.css';

import prevArrowIcon from '../../../icons/arrow-left-red.svg';
import nextArrowIcon from '../../../icons/arrow-right-red.svg';

import {
  IBookingCalendar,
  IBookingCalendarArrowIcon,
} from '../../../interfaces/BookingRelatesInterfaces';

const BookingCalendarArrowIcon: FC<IBookingCalendarArrowIcon> = ({
  alt,
  src,
}) => {
  return <img alt={alt} className='booking-calendar-arrow-icon' src={src} />;
};

const BookingCalendar: FC<IBookingCalendar> = ({
  checkIfTileDisabled,
  onChange,
  value,
  renderTileContent,
  defaultActiveStartDate = new Date(),
}) => {
  const ref = useRef();
  return (
    <Calendar
      ref={ref}
      locale={i18n?.language || 'en'}
      minDetail='month'
      next2Label={null}
      nextLabel={
        <BookingCalendarArrowIcon alt='next-arrow-icon' src={nextArrowIcon} />
      }
      onChange={onChange}
      prevLabel={
        <BookingCalendarArrowIcon alt='prev-arrow-icon' src={prevArrowIcon} />
      }
      prev2Label={null}
      // @ts-ignore
      value={value}
      tileContent={renderTileContent}
      tileDisabled={checkIfTileDisabled}
      defaultActiveStartDate={ref.current ? undefined : defaultActiveStartDate}
    />
  );
};

export default BookingCalendar;
