import React, { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';

const UrlReplaceStateWrapper: FC<{
  children: React.ReactNode;
  path: string;
}> = ({ children, path }) => {
  const {
    chosenPersonTypesState,
    ticketsStartDateState,
    ticketsEndDateState,
    chosenSubcategoryState,
    chosenGroupCategoryState,
  } = useContext(AppContext);

  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;
  const [chosenSubcategory] = chosenSubcategoryState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [chosenGroupCategory] = chosenGroupCategoryState;

  useEffect(() => {
    const productId = chosenSubcategory ? `productId=${chosenSubcategory}` : '';
    const groupId =
      !chosenSubcategory && chosenGroupCategory
        ? `groupId=${chosenGroupCategory}`
        : '';
    const startDate = ticketsStartDate ? `&startDate=${ticketsStartDate}` : '';
    const endDate = ticketsEndDate ? `&endDate=${ticketsEndDate}` : '';
    const personTypes = chosenPersonTypes.reduce((prev: any, value: any) => {
      return {
        ...prev,
        [value]: (prev[value] || 0) + 1,
      };
    }, {});
    const types = Object.keys(personTypes)
      .map((type) => `&${type + '=' + personTypes[type]}`)
      .join('');
    window.history.replaceState(
      null,
      '',
      path + '?' + groupId + productId + startDate + endDate + types
    );
  }, [ticketsStartDate, ticketsEndDate, chosenSubcategory, chosenPersonTypes]);

  return <>{children}</>;
};

export default UrlReplaceStateWrapper;
