import '../BookTicketStatusBar/BookTicketStatusBar.css';
import './UserPanelStatusBar.css';
import FlimsLaaxLogo from '../../atoms/FlimsLaaxLogo/FlimsLaaxLogo';
import flimsLaaxLogo from '../../../assets/flimsLaaxLogo3.png';
import listRed from '../../../icons/list-red.svg';
import profileIconRed from '../../../icons/profile-icon-red.svg';
import usersRed from '../../../icons/users-red.svg';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon/Icon';

const UserPanelStatusBar = () => {
  const { t } = useTranslation();
  return (
    <div className='userProfileStatusBarContainer'>
      <div className={'userPanelTitle'}>{t('user.yourAccount')}</div>
      <div className={'userPanelInfo'}>{t('user.yourAccountInfo')}</div>
      <div className='logoBookTicketStatusBar'>
        <FlimsLaaxLogo alt='Flims Laax Logo' src={flimsLaaxLogo} />
      </div>
      {/* Hidden according to Client - WEBSHOP-15, after accept this commented code can be deleted */}

      <div className='statusBarContainer'>
        <div className='iconContainer'>
          <Icon alt='default icon' src={listRed} />
          <span className='iconText'>
            {String(t('user.bookings')).toUpperCase()}
          </span>
        </div>

        <span className='line'></span>

        <div className='iconContainer'>
          <Icon alt='default icon' src={profileIconRed} />
          <span className='iconText '>
            {String(t('user.yourProfile')).toUpperCase()}
          </span>
        </div>

        <span className='line'></span>

        <div className='iconContainer'>
          <Icon alt='default icon' src={usersRed} />
          <span className='iconText'>
            {String(t('user.groupMembers')).toUpperCase()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserPanelStatusBar;
