import { string, object } from 'yup';

const validationSchema = (): object =>
  object().shape(
    Object.assign({
      lastName: string().nullable(),
      firstName: string().required('This field is required'),
      dayBirth: string().required('This field is required'),
      monthBirth: string().required('This field is required'),
      yearBirth: string().required('This field is required'),
      keyCardType: string(),
      keyCardDisplayNumber: string().when(
        'keyCardType',
        (_type: string, schema: any) =>
          _type !== 'noKeycard'
            ? schema.required('This field is required')
            : schema
      ),
    })
  );
export default validationSchema;
