import React, { ReactElement, SelectHTMLAttributes } from 'react';
import * as R from 'ramda';
import './styled.css';

export interface Option {
  value?: string | number;
  label: string;
  hidden?: boolean;
}

export type SimpleSelectVariant = 'big' | 'small';

interface SimpleSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<Option>;
  isMarkRed?: boolean;
  value?: any;
  placeholder?: string;
  variant?: SimpleSelectVariant;
}

const SimpleSelect = ({
  options,
  isMarkRed,
  value,
  placeholder,
  variant = 'big',
  ...rest
}: SimpleSelectProps): ReactElement => (
  <div
    className={
      variant === 'small'
        ? 'select-small-div select-div-grey'
        : 'select-div select-div-grey'
    }
  >
    <select
      {...rest}
      value={R.isNil(value) ? '' : value}
      style={{
        backgroundColor: 'rgb(240, 240, 240)',
        border: isMarkRed && !value ? '2px solid #b30019' : undefined,
        color: '#2b2b2c',
        fontWeight: 'normal',
        fontSize: '15px',
        cursor: 'pointer',
        fontFamily: 'Helvetica Neue LT W05_55 Roman,sans-serif',
        textOverflow: 'ellipsis',
        paddingRight: '50px',
      }}
    >
      {placeholder && (
        <option value={''} disabled>
          {placeholder}
        </option>
      )}
      {options.map((o) => (
        <option
          key={o?.value?.toString()}
          value={o?.value}
          hidden={o?.hidden || false}
          disabled={o?.hidden || false}
        >
          {o.label}
        </option>
      ))}
    </select>
  </div>
);

export default SimpleSelect;
