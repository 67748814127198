import React, { FC } from 'react';
import ReactModal from 'react-modal';
import './styled.css';

interface ModalProps {
  isOpen: boolean;
  height?: string | number;
  children: React.ReactNode;
}
const Modal: FC<ModalProps> = ({ isOpen, height, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: {
          animation: 'show 0.2s',
          display: 'flex',
          justifyContent: 'center',
          background: 'rgba(128, 128, 128, 0.61)',
          zIndex: 4,
          overflow: 'auto',
          padding: '20px',
        },
        content: {
          position: 'static',
          padding: 0,
          height: height || 'auto',
          maxHeight: '650px',
          overflowX: 'hidden',
        },
      }}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden';
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'initial';
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
