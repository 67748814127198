import { gql } from '@apollo/client';

export const FETCH_BASIC_DATA_OF_GUEST = gql`
  query GetBasicDataOfGuest {
    guest {
      profile {
        id
        firstName
        lastName
        phoneNumber
        language
        email
      }
    }
  }
`;

export const FETCH_GUEST_AVATAR = gql`
  query GetBasicDataOfGuest {
    guest {
      profile {
        avatar
      }
    }
  }
`;

export const SAVE_GUEST_AVATAR = gql`
  mutation sA($avatar: SaveAvatarInput!) {
    saveAvatar(input: $avatar) {
      profile {
        avatar
      }
    }
  }
`;
