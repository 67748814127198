import { useQuery } from '@apollo/client';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Icon from '../../atoms/Icon/Icon';
import userBlack from '../../../icons/user-black.svg';
import userRed from '../../../icons/user-red.svg';
import calendarBlack from '../../../icons/calendar-date-black.svg';
import calendarRed from '../../../icons/calendar-date-red.svg';
import ticketIcon from '../../../icons/ticket-icon-red.svg';
import ticketIconBlack from '../../../icons/ticket-icon-grey.svg';
import circleCheckGrey from '../../../icons/check-circle-grey.svg';
import circleCheckRed from '../../../icons/check-circle-red.svg';
import './BookTicketStatusBarMobile.css';
import { GET_BASE_PRICE_FROM_SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES } from '../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import formatPriceForDisplay from '../../../utils/formatPriceForDisplay';
import useIsAnyPersonIsChosenOnTicket from '../../../hooks/useIsAnyPersonIsChosenOnTicket';
import { IBookTicketStatusBarMobile } from './type';
import circleBlack from '../../../icons/check-circle-grey.svg';
import circleRed from '../../../icons/check-circle-red.svg';
import { AddonType } from '../Addons/type';
import { UpgradeType } from '../Upgrades/type';

const BookTicketStatusBarMobile: FC<IBookTicketStatusBarMobile> = ({
  isBuyActiveMobile = false,
  isDataActiveMobile = false,
  isPersonActiveMobile = false,
  isTotalCostActiveMobile = false,
  isTicketsActive = false,
  toggleStepsView,
  canGoToTotalCost,
  startDate,
  endDate,
  setMobileView,
}) => {
  const {
    chosenPersonTypesState,
    chosenSubcategoryState,
    showSubTotalCostViewState,
    chosenAddonsState,
    chosenUpgradesState,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [chosenAddons] = chosenAddonsState;
  const [chosenUpgrades] = chosenUpgradesState;
  const [showSubTotalCostView, setShowSubTotalCostView] =
    showSubTotalCostViewState;
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [chosenSubcategory] = chosenSubcategoryState;

  const fetchedBasePrice = useQuery(
    GET_BASE_PRICE_FROM_SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          startDate,
          endDate,
          productId: chosenSubcategory,
          personTypes: chosenPersonTypes,
        },
      },
      skip:
        !startDate ||
        !endDate ||
        !chosenSubcategory ||
        chosenPersonTypes[0] === '',
    }
  );

  const basePrice =
    chosenSubcategory && chosenPersonTypes[0] !== ''
      ? fetchedBasePrice?.data?.shopLoadOfferDraftForPersonTypes?.basePrice
      : 0;
  const addons =
    fetchedBasePrice?.data?.shopLoadOfferDraftForPersonTypes?.addons;
  const upgrades =
    fetchedBasePrice?.data?.shopLoadOfferDraftForPersonTypes?.upgrades;
  const addonsPrice = chosenAddons
    .map(
      (addon: string) =>
        addons?.find((a: AddonType) => a.id === addon).totalPrice || 0
    )
    .reduce((a: number, b: number) => a + b, 0);

  const upgradesPrice = chosenUpgrades
    .map(
      (upgrade: string) =>
        upgrades?.find((u: UpgradeType) => u.id === upgrade).totalPrice || 0
    )
    .reduce((a: number, b: number) => a + b, 0);

  const isSubmitDisabled = !useIsAnyPersonIsChosenOnTicket();

  const handleGoToTotalCosts = () => {
    if (canGoToTotalCost) setMobileView('totalCost');
  };
  const handleGoToSubTotal = () => {
    if (!isSubmitDisabled) {
      setShowSubTotalCostView(true);
      sessionStorage.setItem('showSubTotalCostView', String(true));
      history.goBack();
    }
  };

  const handleGoToTickets = () => {
    setMobileView('ticket');
  };

  return (
    <div className='fixedWrapper'>
      <div className='bookTicketStatusBarMobileContainer'>
        <div className='statusBarContainerMobile'>
          {toggleStepsView === 1 ? (
            <>
              <div
                className='iconContainer'
                onClick={() => setMobileView('date')}
              >
                <Icon
                  alt='default icon'
                  src={isDataActiveMobile ? calendarRed : calendarBlack}
                />
                <span
                  className={
                    isDataActiveMobile
                      ? 'iconDescription activeRedColorText'
                      : 'iconDescription'
                  }
                >
                  {t('commons.date')}
                </span>
              </div>
              <div
                className='iconContainer'
                onClick={() => startDate && setMobileView('people')}
              >
                <Icon
                  alt='default icon'
                  src={isPersonActiveMobile ? userRed : userBlack}
                />
                <span
                  className={
                    isPersonActiveMobile
                      ? 'iconDescription activeRedColorText'
                      : 'iconDescription'
                  }
                >
                  {t('commons.people')}
                </span>
              </div>
              <div
                className='iconContainer'
                onClick={() => setMobileView('buy')}
              >
                <Icon
                  alt='default icon'
                  src={isBuyActiveMobile ? circleRed : circleBlack}
                />
                <span
                  className={
                    isBuyActiveMobile
                      ? 'iconDescription activeRedColorText'
                      : 'iconDescription'
                  }
                >
                  {t('commons.buy')}
                </span>
              </div>
              <div
                className='subtotalMobile'
                onClick={() => setMobileView('buy')}
              >
                {t('commons.subtotal')}

                <div className={'subtotalPrice'}>
                  <span style={{ fontSize: '10px', paddingRight: '4px' }}>
                    {'CHF'}
                  </span>
                  {basePrice
                    ? formatPriceForDisplay(
                        basePrice + addonsPrice + upgradesPrice
                      )
                    : '-'}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='iconContainer' onClick={handleGoToTickets}>
                <Icon
                  alt='default icon'
                  src={isTicketsActive ? ticketIcon : ticketIconBlack}
                />
                <span
                  className={
                    isTicketsActive
                      ? 'iconDescription activeRedColorText'
                      : `iconDescription`
                  }
                >
                  {t('commons.tickets')}
                </span>
              </div>
              {isTotalCostActiveMobile ? (
                <div className='iconContainer'>
                  <Icon alt='default icon' src={circleCheckRed} />
                  <span className='iconDescription activeRedColorText'>
                    {t('commons.pay')}
                  </span>
                </div>
              ) : (
                <div onClick={handleGoToTotalCosts}>
                  <div className='iconContainer'>
                    <Icon alt='default icon' src={circleCheckGrey} />
                    <span className='iconDescription'>{t('commons.pay')}</span>
                  </div>
                </div>
              )}
              <div className='subtotalMobile' onClick={handleGoToSubTotal}>
                {t('commons.subtotal')}
                <div className={'subtotalPrice'}>
                  <span style={{ fontSize: '10px', paddingRight: '4px' }}>
                    {'CHF'}
                  </span>
                  {basePrice
                    ? formatPriceForDisplay(
                        basePrice + addonsPrice + upgradesPrice
                      )
                    : '-'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookTicketStatusBarMobile;
