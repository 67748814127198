import { FC } from 'react';

import './InputWhite.css';
import { IInputWhite } from '../../../interfaces/AtomsRelatedInterfaces';
const InputWhite: FC<IInputWhite> = ({
  label,
  type,
  name,
  onChange,
  value,
}) => {
  return (
    <div className='inputContainer'>
      <label className='inputlabel' htmlFor={label}>
        {label}
      </label>
      <input
        className='inputWhite'
        type={type}
        id={label}
        name={name}
        value={value ?? ''}
        onChange={onChange}
      />
    </div>
  );
};

export default InputWhite;
