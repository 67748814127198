import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICheckboxComponent } from '../../../interfaces/AtomsRelatedInterfaces';

import './Checkbox.css';

const Checkbox: FC<ICheckboxComponent> = ({ ...rest }) => {
  const { t } = useTranslation();

  const en = localStorage.getItem('lang') === 'en';
  const link = en
    ? 'https://www.weissearena.com/en/privacy-policy/'
    : 'https://www.weissearena.com/datenschutz/';

  return (
    <form className='acceptCheckbox'>
      <label className='checkbox'>
        <input type='checkbox' {...rest} />
        {t('checkoutView.Iaccept')}{' '}
        <span className='checkboxSpan'>
          <a href={link} target='blank'>
            {t('checkoutView.GTCandBGB')}
          </a>
        </span>
      </label>
    </form>
  );
};

export default Checkbox;
