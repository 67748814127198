import TagManager from 'react-gtm-module';

export enum SegmentAnalyticsTypes {
  IDENTIFY = 'identify',
  TRACK = 'track',
}

export const sendAnalytics = (
  title: string,
  data: object,
  segmentAnalyticsType: SegmentAnalyticsTypes = SegmentAnalyticsTypes.TRACK
) => {
  //segment analytics
  segmentAnalyticsType === SegmentAnalyticsTypes.IDENTIFY
    ? window.analytics.identify(title, data)
    : window.analytics.track(title, data);
  //gtm analytics
  TagManager.dataLayer({
    dataLayerName: 'dataLayer',
    dataLayer: {
      ...data,
      gtmEventTitle: title,
    },
  });
};

export default sendAnalytics;
