import { FC } from 'react';

import { IStartDescription } from '../../../interfaces/AtomsRelatedInterfaces';
import './StartDescription.css';

const StartDescription: FC<IStartDescription> = ({ text, title, link }) => {
  return (
    <div className='descriptionContainer'>
      <h3 className='descriptionH3'>{title}</h3>
      <p>{text}</p>
      <span className='line'></span>

      <a className='laaxLink' rel='noreferrer' target='_blank' href={link}>
        laax.com
      </a>
    </div>
  );
};

export default StartDescription;
