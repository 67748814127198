import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckoutView } from '../../../interfaces/CheckoutViewRelatedInterfaces';
import moment from 'moment';
import { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import ApolloProviderWrapper from '../../atoms/ApolloProviderWrapper/ApolloProviderWrapper';
import BookTicketStatusBar from '../../molecules/BookTicketStatusBar/BookTicketStatusBar';
import BookTicketStatusBarMobile from '../../molecules/BookTicketStatusBar/BookTicketStatusBarMobile';
import SmallText from '../../atoms/SmallText/SmallText';
import TicketsSummary from '../../organisms/TicketsSummary/TicketsSummary';
import ToggleSideMenu from '../../molecules/ToggleSideMenu/ToggleSideMenu';
import '../BuyTicketView/BuyTicketView.css';
import './CheckoutView.css';
import checkIfIsMobileView from '../../../utils/checkIfIsMobileView';
import NavigationContainer from './NavigationContainer';
import CheckoutViewWrapper from './CheckoutViewWrapper';
import { useMutation, useQuery } from '@apollo/client';
import { SHOP_INITIALIZE_PURCHASE } from '../../../graphql/shop/shopInitializeWebviewPurchase';
import Total from './Total';
import {
  SHOP_LOAD_OFFER,
  SHOP_LOAD_OFFER_DISCOUNTS,
} from '../../../graphql/shop/shopLoadOffer';
import { DiscountToken } from '../../../interfaces/TotalComponentRelatedInterfaces';
import { toast } from 'react-toastify';
import { SHOP_DIRECT_SAVED_PURCHASE } from '../../../graphql/shop/shopDirectPurchaseWithSavedPaymentMethod';
import { useHistory } from 'react-router-dom';
import { FETCH_PRODUCT_TITLE_BY_PRODUCT_ID } from '../../../graphql/shop/shopLoadKeycardTicketProduct';
import SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES from '../../../graphql/shop/shopLoadOfferDraftForPersonTypes';
import useGetDataFromSettings from '../../../hooks/useGetDataFromSettings';
import sendAnalytics from '../../../utils/sendAnalytics';
import useGetPaymentMethods from '../../../hooks/useGetPaymentMethods';
import OrderCompletedAnalytics from '../../molecules/OrderCompltedAnalytics';
import priceDivided from '../../../utils/priceDivided';
import { dateFormat } from '../../../constans/formats';

const CheckoutView: FC<ICheckoutView> = () => {
  const {
    buyTicketViewState,
    chosenSubcategoryState,
    ticketsStartDateState,
    ticketsEndDateState,
    chosenPartyMembersOnTicketState,
    chosenPersonTypesState,
    discountTokenIdState,
    chosenAddonsState,
    chosenUpgradesState,
    purchaseState,
  } = useContext(AppContext);

  const { t } = useTranslation();
  const history = useHistory();
  const paymentMethods = useGetPaymentMethods();
  const [chosenPersonTypes] = chosenPersonTypesState;
  const [chosenPartyMembersOnTicket] = chosenPartyMembersOnTicketState;
  const [chosenSubcategory] = chosenSubcategoryState;
  const [, setIsBuyTicketView] = buyTicketViewState;
  const [ticketsStartDate] = ticketsStartDateState;
  const [ticketsEndDate] = ticketsEndDateState;
  const [discountTokenId, setDiscountTokenId] = discountTokenIdState;
  const [chosenAddons] = chosenAddonsState;
  const [chosenUpgrades] = chosenUpgradesState;
  const [, setPurchase] = purchaseState;
  const [canGoToTotalCost, setCanGoToTotalCost] = useState(false);
  const [activatedTokenId, setActivatedTokenId] = useState<
    string | undefined | null
  >(undefined);
  const [toggleStepsView] = useState(2);
  const [sentAnalytics, setSentAnalytics] = useState(false);
  const isMobileView = checkIfIsMobileView();
  const [shopInitializePurchase] = useMutation(SHOP_INITIALIZE_PURCHASE);
  const [shopDirectSavedPurchase] = useMutation(SHOP_DIRECT_SAVED_PURCHASE);
  const [mobileView, setMobileView] = useState('ticket');
  const isTotalCostActiveMobile = mobileView === 'totalCost';
  const isTicketsActive = mobileView === 'ticket';
  const token = Boolean(localStorage.getItem('token'));
  const [successAnalyticsSend, setSuccessAnalyticsSend] = useState(false);
  const [successSavedPurchase, setSuccessSavedPurchase] = useState(false);

  const [totalPrice, setTotalPrice] = useState(-1);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const chosenPartyMembersOnTicketWithoutEmpty =
    chosenPartyMembersOnTicket.filter((id: string) => id !== '');
  const haveAllPersonTypesAssignedMembers = Boolean(
    chosenPartyMembersOnTicketWithoutEmpty.length > 0 &&
      chosenPersonTypes.length === chosenPartyMembersOnTicketWithoutEmpty.length
  );

  const changeDiscountTokenId = (tokenId: string) => {
    const discountsToken = discountsTokens.find(
      (token: DiscountToken) => token.id === tokenId
    );
    const lastDiscountsToken = discountsTokens.find(
      (token: DiscountToken) => token.id === discountTokenId
    );
    if (lastDiscountsToken)
      sendAnalytics('Coupon Removed', {
        cart_id: '',
        coupon_id: discountTokenId,
        coupon_name: lastDiscountsToken.description,
        discount: priceDivided(lastDiscountsToken.discount),
      });

    if (discountsToken)
      sendAnalytics('Coupon Applied', {
        cart_id: '',
        coupon_id: tokenId,
        coupon_name: discountsToken.description,
        discount: priceDivided(discountsToken.discount),
      });

    setDiscountTokenId(tokenId);
    sessionStorage.setItem('discountTokenId', tokenId);
  };

  const handleShowBuyTicketView = () => {
    sessionStorage.setItem('isBuyTicketView', 'true');
    setIsBuyTicketView(true);
    history.push(`/buy-tickets${window.location.search}`);
  };

  const fetchedProductByProductID =
    useQuery(FETCH_PRODUCT_TITLE_BY_PRODUCT_ID, {
      variables: {
        productId: chosenSubcategory,
      },
      skip: !chosenSubcategory,
    }) ?? {};

  const shopDraftResult = useQuery(SHOP_LOAD_OFFER_DRAFT_FOR_PERSON_TYPES, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        startDate: ticketsStartDate || moment(new Date()).format(dateFormat),
        endDate: ticketsEndDate || moment(new Date()).format(dateFormat),
        productId: chosenSubcategory,
        personTypes: chosenPersonTypes,
      },
    },
    skip: !chosenSubcategory || chosenPersonTypes[0] === '',
  });

  const upgradesFromSettings =
    fetchedProductByProductID?.data?.shopLoadKeycardTicketProduct?.upgrades;
  const addonsFromSettings =
    fetchedProductByProductID?.data?.shopLoadKeycardTicketProduct?.addons;

  const { addons, upgrades, productsAnalytics, productsGoogleAnalytics } =
    useGetDataFromSettings(
      shopDraftResult,
      addonsFromSettings,
      upgradesFromSettings
    );
  const quantityConfig =
    fetchedProductByProductID?.data?.shopLoadKeycardTicketProduct
      ?.quantityConfig;
  const ticketWithPartMembers = quantityConfig === 'AXESS_PARTY_MEMBER';
  const {
    data: offerDiscounts,
    loading: offerDiscountsLoading,
    refetch: offerDiscountsReFetch,
  } = useQuery(SHOP_LOAD_OFFER_DISCOUNTS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        productId: chosenSubcategory,
        startDate: ticketsStartDate,
        endDate: ticketsEndDate,
        partyMemberIds: ticketWithPartMembers
          ? chosenPartyMembersOnTicket
          : undefined,
        personTypes: ticketWithPartMembers ? undefined : chosenPersonTypes,
        selectedAddonIds: chosenAddons || [],
      },
    },
    skip:
      (ticketWithPartMembers && !haveAllPersonTypesAssignedMembers) ||
      !ticketsStartDate ||
      !ticketsEndDate ||
      !chosenSubcategory ||
      fetchedProductByProductID.loading ||
      !quantityConfig,
  });
  const {
    data: shopLoadOfferData,
    error: shopLoadOfferError,
    loading: shopLoadOfferLoading,
  } = useQuery(SHOP_LOAD_OFFER, {
    variables: {
      input: {
        productId: chosenSubcategory,
        startDate: ticketsStartDate,
        endDate: ticketsEndDate,
        partyMemberIds: ticketWithPartMembers
          ? chosenPartyMembersOnTicket
          : undefined,
        personTypes: ticketWithPartMembers ? undefined : chosenPersonTypes,
        selectedAddonIds: chosenAddons || [],
        selectedUpgradeId: chosenUpgrades[0],
      },
    },
    skip:
      (ticketWithPartMembers
        ? !haveAllPersonTypesAssignedMembers
        : chosenPersonTypes.length === 0 || chosenPersonTypes.includes('')) ||
      !ticketsStartDate ||
      !ticketsEndDate ||
      !chosenSubcategory ||
      fetchedProductByProductID.loading ||
      !quantityConfig,
  });

  const discountsTokens = offerDiscounts?.shopLoadOfferDiscountTokens || [];
  const selectedDiscountToken =
    discountTokenId &&
    discountsTokens.filter((d: DiscountToken) => d.id === discountTokenId)[0];

  const isPayActive =
    chosenPersonTypes.length > 0 &&
    (!ticketWithPartMembers || haveAllPersonTypesAssignedMembers) &&
    totalPrice - (selectedDiscountToken?.discount || 0) >= 0;

  const isSubmitBtnDisabled = !isAgreementChecked || !isPayActive;

  const handlePayment = useCallback(() => {
    if (!isSubmitBtnDisabled) {
      if (totalPrice - (selectedDiscountToken?.discount || 0) > 0) {
        sendAnalytics('Checkout Step Completed', { checkout_id: '', step: 2 });
        shopInitializePurchase({
          variables: {
            product: {
              productId: chosenSubcategory,
              startDate: ticketsStartDate,
              endDate: ticketsEndDate,
              partyMemberIds: ticketWithPartMembers
                ? chosenPartyMembersOnTicket
                : undefined,
              personTypes: ticketWithPartMembers
                ? undefined
                : chosenPersonTypes,
              selectedAddonIds: chosenAddons || [],
              selectedUpgradeId: chosenUpgrades[0],
            },
            purchase: {
              discount: selectedDiscountToken?.discount || 0,
              selectedDiscountTokenId: selectedDiscountToken?.id,
              totalPrice: totalPrice,
              paymentMethods,
              storePaymentMeans: false,
              shop: 'web',
            },
          },
        })
          .then((response) => {
            if (response?.data) {
              const purchase = {
                ...response?.data.shopInitializeWebviewPurchase,
                tokenId: selectedDiscountToken?.id,
              };
              sessionStorage.setItem('purchase', String(purchase));
              setPurchase(purchase);
              history.push('/checkout/payment-info');
            }
          })
          .catch((err) => toast(err?.message, { type: 'error' }));
      } else {
        shopDirectSavedPurchase({
          variables: {
            product: {
              productId: chosenSubcategory,
              startDate: ticketsStartDate,
              endDate: ticketsEndDate,
              partyMemberIds: ticketWithPartMembers
                ? chosenPartyMembersOnTicket
                : undefined,
              personTypes: ticketWithPartMembers
                ? undefined
                : chosenPersonTypes,
              selectedAddonIds: chosenAddons || [],
              selectedUpgradeId: chosenUpgrades[0],
            },
            purchase: {
              discount: selectedDiscountToken?.discount || 0,
              selectedDiscountTokenId: selectedDiscountToken?.id,
              totalPrice: totalPrice,
              paymentToken: '',
            },
          },
        })
          .then((response) => {
            if (response?.data) setSuccessSavedPurchase(true);
          })
          .catch((err) => toast(err?.message, { type: 'error' }));
      }
    }
  }, [
    totalPrice,
    isSubmitBtnDisabled,
    shopInitializePurchase,
    chosenSubcategory,
    ticketsStartDate,
    ticketsEndDate,
    chosenPartyMembersOnTicket,
    selectedDiscountToken,
    paymentMethods,
  ]);

  useEffect(() => {
    if (offerDiscountsLoading === false) {
      if (activatedTokenId) {
        if (
          discountsTokens.find((d: DiscountToken) => d.id === activatedTokenId)
        )
          changeDiscountTokenId(activatedTokenId);
        setActivatedTokenId(undefined);
      } else {
        if (
          !discountTokenId ||
          !discountsTokens.find((d: DiscountToken) => d.id === discountTokenId)
        ) {
          const firstAutoApplyDiscount = discountsTokens.filter(
            (d: DiscountToken) => d.autoApply
          )[0];
          changeDiscountTokenId(firstAutoApplyDiscount?.id || '');
        }
      }
    }
  }, [discountsTokens, offerDiscountsLoading]);

  const getUserKeyCardPrice = (user: string) => {
    return shopLoadOfferData?.shopLoadOffer?.lineItems
      ?.find((item: any) =>
        item?.subLineItems.find(
          ({ title }: { title: string }) => title === user
        )
      )
      ?.subLineItems.find((item: any) => item.title === 'New Keycard fee')
      ?.price;
  };

  useEffect(() => {
    if (
      !sentAnalytics &&
      shopDraftResult.data &&
      fetchedProductByProductID.data &&
      totalPrice >= 0
    ) {
      sendAnalytics('Checkout Started', {
        order_id: '',
        affiliation: 'tickets.laax.com',
        value: priceDivided(totalPrice),
        currency: 'CHF',
        ...productsAnalytics,
      });
      setSentAnalytics(true);
    }
  }, [shopDraftResult, fetchedProductByProductID, sentAnalytics, totalPrice]);

  useEffect(() => {
    if (successAnalyticsSend)
      history.push(`/checkout/complete${window.location.search}`);
  }, [successAnalyticsSend]);

  return (
    <CheckoutViewWrapper
      setIsTotalCostActive={setCanGoToTotalCost}
      ticketWithPartMembers={ticketWithPartMembers}
    >
      {successSavedPurchase && (
        <OrderCompletedAnalytics
          tokenId={selectedDiscountToken?.id}
          setSuccessAnalyticsSend={setSuccessAnalyticsSend}
        />
      )}
      <div className='buyTicketContainer'>
        <div className='buyTicketLeftSection'>
          <ToggleSideMenu />
          {!isMobileView ? (
            <div className='linkToStep1' onClick={handleShowBuyTicketView}>
              <SmallText
                isNoUppercase
                text={`❮ 1. ${t('buyTicketView.configureYourTicket')}`}
              />
            </div>
          ) : null}
        </div>
        <div className='buyTicketRightSection'>
          <div className='buyTicketRightTop '>
            <BookTicketStatusBar />
          </div>
          {fetchedProductByProductID?.loading === false && (
            <div className='buyTicketRightBottom'>
              <div className={isTicketsActive ? 'active' : 'disactive'}>
                {chosenSubcategory && chosenPersonTypes ? (
                  <TicketsSummary
                    {...{
                      endDate: ticketsEndDate,
                      startDate: ticketsStartDate,
                      filteredPersonTypes: chosenPersonTypes,
                      ticketWithPartMembers,
                      chosenSubcategory,
                      addons,
                      upgrades,
                      upgradesFromSettings,
                      addonsFromSettings,
                      getUserKeyCardPrice,
                    }}
                  />
                ) : null}
              </div>
              {token && (
                <div
                  className={isTotalCostActiveMobile ? 'active' : 'disactive'}
                >
                  <ApolloProviderWrapper>
                    <Total
                      ticketWithPartMembers={ticketWithPartMembers}
                      isTotalCostActive={canGoToTotalCost}
                      discountTokens={discountsTokens}
                      {...{
                        handlePayment,
                        totalPrice,
                        setTotalPrice,
                        isSubmitBtnDisabled,
                        isAgreementChecked,
                        setIsAgreementChecked,
                        discountTokenId,
                        changeDiscountTokenId,
                        mobileView,
                        offerDiscountsReFetch,
                        offerDiscountsLoading,
                        setActivatedTokenId,
                        selectedDiscountToken,
                        shopLoadOfferData,
                        shopLoadOfferError,
                        shopLoadOfferLoading,
                      }}
                    />
                  </ApolloProviderWrapper>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='hideOnWeb'>
          <div className='navigationButtonsBox' id={'buttons'}>
            <NavigationContainer
              isTicketsActive={isTicketsActive}
              isTotalCostActiveMobile={isTotalCostActiveMobile}
              canGoToTotalCost={canGoToTotalCost}
              isSubmitBtnDisabled={isSubmitBtnDisabled}
              handlePayment={handlePayment}
              setMobileView={setMobileView}
            />
            <BookTicketStatusBarMobile
              isTotalCostActiveMobile={isTotalCostActiveMobile}
              toggleStepsView={toggleStepsView}
              isTicketsActive={isTicketsActive}
              canGoToTotalCost={canGoToTotalCost}
              startDate={ticketsStartDate}
              endDate={ticketsEndDate}
              setMobileView={setMobileView}
            />
          </div>
        </div>
      </div>
    </CheckoutViewWrapper>
  );
};

export default CheckoutView;
