import { useContext } from 'react';
import { AppContext } from '../AppContext';
import useClearUpgrades from './useClearUpgrades';
import useClearAddons from './useClearAddons';

const useClearProduct = () => {
  const {
    chosenMainCategoryState,
    chosenSubcategoryState,
    chosenGroupCategoryState,
  } = useContext(AppContext);
  const clearUpgrades = useClearUpgrades();
  const clearAddons = useClearAddons();

  const [, setChosenMainCategory] = chosenMainCategoryState;
  const [, setChosenSubcategory] = chosenSubcategoryState;
  const [, setChosenGroupCategory] = chosenGroupCategoryState;

  const clear = () => {
    clearUpgrades();
    clearAddons();
    setChosenMainCategory('');
    setChosenSubcategory('');
    setChosenGroupCategory('');
    sessionStorage.removeItem('chosenGroupCategory');
    sessionStorage.removeItem('chosenMainCategory');
    sessionStorage.removeItem('chosenSubcategory');
  };
  return clear;
};
export default useClearProduct;
