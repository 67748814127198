import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppProvider } from './AppContext';
import ApolloProviderWrapper from './components/atoms/ApolloProviderWrapper/ApolloProviderWrapper';
import AppRouter from './AppRouter';

import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import ShopEnabledWrapper from './components/templates/ShopEnabledWrapper';

const tagManagerArgs = {
  gtmId: 'GTM-M7XWMCT',
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const gtm = (w: any, d: any, s: any, l: any, i: any) => {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  };

  const setAnalytics = () => {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e: any) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key: string, e: any) {
          var t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            key +
            '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          // @ts-ignore
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = 'KMHrA3aA29oi01gccksrbFzcmevwsSpi';
        analytics.SNIPPET_VERSION = '4.15.3';
        analytics.load('KMHrA3aA29oi01gccksrbFzcmevwsSpi');
        analytics.page();
      }
  };
  useEffect(() => {
    gtm(window, document, 'script', 'dataLayer', 'GTM-NNG7BJ9');
    setAnalytics();
  }, []);

  return (
    <AppProvider>
      <ApolloProviderWrapper>
        <ShopEnabledWrapper>
          <BrowserRouter>
            <AppRouter />
            <ToastContainer />
          </BrowserRouter>
        </ShopEnabledWrapper>
      </ApolloProviderWrapper>
    </AppProvider>
  );
};

export default App;
