import React, { FC, useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { useGetURLQueryData } from './hooks';
import ProductWidgetComponent from './ProductWidgetComponent';

const ProductWidget: FC = () => {
  const { chosenSubcategoryState } = useContext(AppContext);
  const [chosenSubcategory, setChosenSubcategory] = chosenSubcategoryState;
  const { productId, lang } = useGetURLQueryData();
  const langNotChanged = lang && lang !== localStorage.getItem('lang');

  useEffect(() => {
    if (langNotChanged) {
      localStorage.setItem('lang', lang === 'en' ? 'en' : 'de');
      window.location.reload();
    }
  }, [langNotChanged]);

  useEffect(() => {
    productId && setChosenSubcategory(productId);
  }, [productId]);

  if (langNotChanged || chosenSubcategory !== productId) return null;
  else return <ProductWidgetComponent />;
};

export default ProductWidget;
