import { FC, useContext } from 'react';

import { AppContext } from '../../../AppContext';

import '../../../index.css';
import './AmountControl.css';

import { IAmountControl } from '../../../interfaces/AtomsRelatedInterfaces';
import sendAnalytics from '../../../utils/sendAnalytics';
import priceDivided from '../../../utils/priceDivided';

const AmountControl: FC<IAmountControl> = ({ personType, type, price }) => {
  const isAddType = type === 'plus';
  const {
    chosenPersonTypesState,
    availableProductsState,
    chosenSubcategoryState,
  } = useContext(AppContext);

  const [availableProducts] = availableProductsState;
  const [chosenSubcategory] = chosenSubcategoryState;
  const [chosenPersonTypes, setChosenPersonTypes] = chosenPersonTypesState;

  const product = availableProducts.find(
    (aP: any) => aP.id === chosenSubcategory
  );

  const productAnalytics = {
    currency: 'chf',
    product_id: product?.id,
    sku: product?.id + '--' + personType,
    category: product?.category,
    name: product?.title,
    variant: personType,
    price: priceDivided(price),
    quantity: 1,
  };
  const handleOnClick = (): void => {
    if (!isAddType) {
      const index = chosenPersonTypes.findIndex(
        (cTP: string) => cTP == personType
      );
      if (index > -1) {
        sendAnalytics('Product Removed', productAnalytics);
        const newArray = Array.from(chosenPersonTypes);
        newArray.splice(index, 1);
        setChosenPersonTypes(newArray);
        sessionStorage.setItem('chosenPersonTypes', String(newArray));
      }
    } else {
      sendAnalytics('Product Added', productAnalytics);
      setChosenPersonTypes([...chosenPersonTypes, personType]);
      sessionStorage.setItem(
        'chosenPersonTypes',
        String([...chosenPersonTypes, personType])
      );
    }
  };
  return (
    <span
      className='amount-control'
      style={{ paddingRight: !isAddType ? '4px' : undefined }}
      onClick={handleOnClick}
    >
      {isAddType ? '+' : '-'}
    </span>
  );
};

export default AmountControl;
