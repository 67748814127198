import { FC } from 'react';

import '../../../index.css';
import './SmallText.css';

import { ISmallText } from '../../../interfaces/AtomsRelatedInterfaces';

const SmallText: FC<ISmallText> = ({ isLink, isNoUppercase = false, text }) => {
  return (
    <span
      className={`small-text ${isLink ? 'small-text-link' : ''}`}
      style={!isNoUppercase ? { textTransform: 'uppercase' } : undefined}
    >
      {text}
    </span>
  );
};

export default SmallText;
