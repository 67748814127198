import React, { FC, useEffect } from 'react';

const Redirect404: FC = () => {
  useEffect(() => {
    window.location.replace('https://tickets.laax.com/404.html');
  }, []);
  return <></>;
};

export default Redirect404;
