import { useQuery } from '@apollo/client';
import { SHOP_PAYMENT_METHODS } from '../graphql/shop/paymentMethods';

const useGetPaymentMethods = () => {
  const shopPaymentMethod = useQuery(SHOP_PAYMENT_METHODS);

  const supportedPaymentTypes =
    shopPaymentMethod?.data?.paymentMethods?.supportedPaymentTypes || [];

  if (supportedPaymentTypes.includes('CREDIT_CARD'))
    return [
      ...supportedPaymentTypes.filter((p: string) => p !== 'CREDIT_CARD'),
      'VISA',
      'MASTERCARD',
      'DINERS',
      'MAESTRO',
    ];
  else return supportedPaymentTypes;
};
export default useGetPaymentMethods;
