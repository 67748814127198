import { FC } from 'react';

import SocialMediaIcon from '../../../atoms/SocialMediaIcon/SocialMediaIcon';

import './SmallMenuFooterSocialMedia.css';

import facebookIcon from '../../../../icons/facebook.png';
import instagramIcon from '../../../../icons/instagram.png';
import youtubeIcon from '../../../../icons/youtube.png';

import { ISmallMenuFooterSocialMedia } from '../../../../interfaces/SmallMenuFooterRelatedInterfaces';

const SmallMenuFooterSocialMedia: FC<ISmallMenuFooterSocialMedia> = ({
  facebookLink,
  instagramLink,
  label,
  youtubeLink,
}) => {
  return (
    <div className='social-media'>
      <span className='social-media-label'>{label}</span>
      <div className='social-media' style={{ paddingBottom: '0.5em' }}>
        <SocialMediaIcon
          alt='instagram-icon'
          link={instagramLink}
          src={instagramIcon}
        />
        <SocialMediaIcon
          alt='facebook-icon'
          link={facebookLink}
          src={facebookIcon}
        />
        <SocialMediaIcon
          alt='youtube-icon'
          link={youtubeLink}
          src={youtubeIcon}
        />
      </div>
    </div>
  );
};

export default SmallMenuFooterSocialMedia;
