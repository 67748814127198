import { FC } from 'react';

import '../../../../index.css';
import './MainOptionButton.css';

import { IMainOptionButton } from '../../../../interfaces/AtomsRelatedInterfaces';

const MainOptionButton: FC<IMainOptionButton> = ({
  children,
  className,
  backgroundColor = '#c40404',
  color = '#fff',
  onClick,
}) => {
  return (
    <button
      className={`main-option-button ${className}`}
      style={{ color: color, backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MainOptionButton;
