import moment from 'moment';
import { dateFormat } from '../../../../constans/formats';

type CheckDatePositionProps = {
  maxConsecutiveDays: number;
  upSaleDates: any;
  ticketsStartDate: string | undefined;
  ticketsEndDate: string | undefined;
  convertedDate: string;
};

const checkDatePosition = ({
  maxConsecutiveDays,
  upSaleDates,
  ticketsStartDate,
  ticketsEndDate,
  convertedDate,
}: CheckDatePositionProps) => {
  const dateDayAfter =
    ticketsEndDate && moment(ticketsEndDate).add(1, 'day').format(dateFormat);
  const dateDayBefore =
    ticketsStartDate &&
    moment(ticketsStartDate).subtract(1, 'day').format(dateFormat);

  const showDayAfter = Boolean(
    maxConsecutiveDays > 1 &&
      upSaleDates.find((sale: any) => sale.date === dateDayAfter)?.dayPrice
  );

  const showDayBefore = Boolean(
    maxConsecutiveDays > 1 &&
      upSaleDates.find((sale: any) => sale.date === dateDayBefore)?.dayPrice
  );
  return {
    dateIsDayAfter: showDayAfter && moment(convertedDate).isSame(dateDayAfter),
    dateIsDayBefore:
      showDayBefore && moment(convertedDate).isSame(dateDayBefore),
  };
};

export default checkDatePosition;
