import { FC } from 'react';

import { ISocialMediaIcon } from '../../../interfaces/AtomsRelatedInterfaces';

const SocialMediaIcon: FC<ISocialMediaIcon> = ({ alt, link, src }) => {
  return (
    <a className='laaxLink' rel='noreferrer' target='_blank' href={link}>
      <img alt={alt} className='social-media-icon' src={src} />
    </a>
  );
};

export default SocialMediaIcon;
